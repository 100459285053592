import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ArchiveUserdata from 'Components/ArchiveUserdata/ArchiveUserdata.async';
import ChangePassword from 'Components/ChangePassword/ChangePassword';
import Dashboard from 'Components/Dashboard/Dashboard.async';
import Login from 'Components/Login/Login.async';
import LogoutComponent from 'Components/LogoutComponent/LogoutComponent';
import NoSSR from 'Components/NoSSR';
import ResetPassword from 'Components/ResetPassword/ResetPassword';
import RegisterFormProfile from 'Components/Settings/PayWithFixly/RegisterFormProfile';
import AboutUs from 'pages/AboutUs/AboutUs.async';
import AllCategories from 'pages/AllCategories/AllCategories.async';
import CamperContest from 'pages/CamperContest/CamperContest.async';
import L2CategoryCities from 'pages/CategoryCities/L2CategoryCities.async';
import L4CategoryCities from 'pages/CategoryCities/L4CategoryCities.async';
import CompanyListing from 'pages/CompanyListing/CompanyListing.async';
import ExternalReview from 'pages/ExternalReview/ExternalReview.async';
import Homepage from 'pages/Homepage/Homepage.async';
import IkeaLanding from 'pages/IkeaLanding/IkeaLanding.async';
import L2Category from 'pages/L2Category/L2Category.async';
import L4Category from 'pages/L4Category/L4Category.async';
import L4CategoryCity from 'pages/L4Category/L4CategoryCity.async';
import PayWithFixly from 'pages/PayWithFixly/PayWithFixly.async';
import PayWithFixlyPromo from 'pages/PayWithFixlyPromo/PayWithFixlyPromo.async';
import Payments from 'pages/Payments/Payments.async';
import ProviderRegister from 'pages/ProviderRegister/ProviderRegister.async';
import ProviderRegisterCity from 'pages/ProviderRegister/ProviderRegisterCity/ProviderRegisterCity.async';
import ProviderRegisterL2 from 'pages/ProviderRegister/ProviderRegisterL2/ProviderRegisterL2.async';
import ProviderRegisterL3 from 'pages/ProviderRegister/ProviderRegisterL3/ProviderRegisterL3.async';
import ProviderRegisterL3City from 'pages/ProviderRegister/ProviderRegisterL3City/ProviderRegisterL3City.async';
import ProviderRegisterUkrainian from 'pages/ProviderRegisterUkrainian/ProviderRegister.async';
import Referral from 'pages/Referral/Referral.async';
import Reviews from 'pages/Reviews/Reviews.async';
import SPOnboarding from 'pages/SPOnboarding/SPOnboarding.async';
import SPPublicProfileRedirector from 'pages/SPPublicProfile/SPPublicProfileRedirector';
import UserReviewLinkHandler from 'pages/UserReviewLinkHandler/UserReviewLinkHandler';
import routes from 'utils/routeTranslator';

import RedirectWithStatus from './RedirectWithStatus';
import WildcardRoute from './WildcardRoute';

export const IKEA_SLUG = 'montaz-mebli-ikea';

const Routes = () => (
  <Switch>
    <RedirectWithStatus status={301} from={routes.get('front::landing.segment')} to="front::categories.type" />
    <RedirectWithStatus status={301} from={routes.get('front::categories')} to="categories_seo" exact />
    {/* L4 Category page for IKEA must not be accessible. */}
    {/* Instead redirect users to special page with IKEA wizard. */}
    <RedirectWithStatus
      status={301}
      from={routes.get('front::category', { slug: IKEA_SLUG })}
      to={routes.get('front::ikea.landing')}
    />
    <Route path={routes.get('front::index')} exact component={Homepage} />
    <Route
      path={routes.get('provider::settings.getUserdataArchive')}
      exact
      render={() => (
        <NoSSR>
          <ArchiveUserdata />
        </NoSSR>
      )}
    />
    <Route path={routes.get('front::auth.user.login')} component={Login} />
    <Route path={routes.get('front::auth.user.logout')} component={LogoutComponent} />
    <Route path={routes.get('front::password.reset')} component={ResetPassword} />
    <Route path={routes.get('front::password.change')} component={ChangePassword} />
    <Route path={routes.get('front::dashboard')} component={Dashboard} />
    <Route path={routes.get('front::user.rate.provider')} component={ExternalReview} />
    <Route path={routes.get('front::provider.profile')} component={SPPublicProfileRedirector} exact />
    <Route path={routes.get('provider::settings.index')} component={Dashboard} />
    <Route path={routes.get('provider::profile')} component={Dashboard} />
    <Route path={routes.get('categories_seo')} component={AllCategories} exact />
    <Route path={routes.get('front::categories.cities')} component={L2CategoryCities} exact />
    <Route path={routes.get('front::category.cities')} component={L4CategoryCities} exact />
    <Route path={routes.get('front::categories.type')} exact component={L2Category} />
    <Route path={routes.get('front::category_city')} component={L4CategoryCity} />
    <Route path={routes.get('front::category')} component={L4Category} />
    <Route path={routes.get('payments::any')} component={Payments} />
    <Route path={routes.get('pwf::index')} component={PayWithFixly} />
    <Route path={routes.get('front::provider.register.uk')} exact component={ProviderRegisterUkrainian} />
    <Route path={routes.get('front::provider.register')} exact component={ProviderRegister} />
    <Route path={routes.get('front::provider.register.l2.requests')} component={ProviderRegisterL2} />
    <Route path={routes.get('front::provider.register.l2')} component={ProviderRegisterL2} />
    <Route path={routes.get('front::provider.register.city')} component={ProviderRegisterCity} />
    <Route path={routes.get('front::provider.register.l3.city')} component={ProviderRegisterL3City} />
    <Route path={routes.get('front::provider.register.l3')} component={ProviderRegisterL3} exact />
    <Route path={routes.get('front::companies.city')} component={CompanyListing} exact />
    <Route path={routes.get('front::companies.l4.city')} component={CompanyListing} exact />
    <Route path={routes.get('front::companies.l4')} component={CompanyListing} exact />
    <Route path={routes.get('provider::onboarding')} component={SPOnboarding} />
    <Route path={routes.get('provider::onboarding.pwf')} component={RegisterFormProfile} exact />
    <Route path={routes.get('provider::request.quoteDone')} component={UserReviewLinkHandler} />
    <Route path={routes.get('front::referral')} component={Referral} />
    <Route path={routes.get('front::aboutus')} component={AboutUs} exact />
    <Route path={routes.get('front::contest')} component={CamperContest} exact />
    <Route path={routes.get('front::pwf.promo')} component={PayWithFixlyPromo} exact />
    <Route path={routes.get('front::ikea.landing')} component={IkeaLanding} exact />
    <Route path={routes.get('front::reviews')} component={Reviews} exact />
    <RedirectWithStatus status={301} from={routes.get('front::neighbourHelp')} to={routes.get('front::index')} exact />
    <Route component={WildcardRoute} />
  </Switch>
);

export default Routes;
