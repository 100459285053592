import React from 'react';

export const CarsmileIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    Î
    <path
      d="M21.96 65.7c-17.3 0-22.6-9.5-21.9-21.8.2-3.3.5-5.9 1.4-9.6C5.96 16.4 12.36 7 36.86 7h34.7l-4.6 15.5h-23.7c-9.8 0-14.8 4-17.4 12.9-.6 1.9-1 4.7-1.1 6.2-.3 5.7 2.6 8.6 10.4 8.6h25l-4.6 15.5h-33.6z"
      fill="currentColor"
    />
  </svg>
);

/* eslint max-len:0 */
