import loadable from '@loadable/component';
import React from 'react';

const Placeholder = ({ error }: { error: boolean }) => {
  if (error) {
    console.log(error);

    return <p>Cannot load public provider profile...</p>;
  }

  return <div />;
};

export default loadable(() => import('./SPPublicProfile'), {
  fallback: Placeholder({
    error: false,
  }),
});
