import React from 'react';

export type SnackbarMessage = string;
export type VariantType = 'default' | 'error' | 'success' | 'info';

export interface IconVariant {
  default: React.ReactNode;
  error: React.ReactNode;
  success: React.ReactNode;
  info: React.ReactNode;
}

export interface SnackbarProviderProps {
  children: React.ReactNode | React.ReactNode[];
  domRoot?: HTMLElement | undefined;
}

export interface SnackbarContainerProps {
  children: JSX.Element | JSX.Element[] | null;
  className?: string;
}

export interface SnackbarItemProps {
  message?: SnackbarMessage;
  content?: React.ReactNode;
  variant: VariantType;
  options: OptionsObject;
  onClose: () => void;
}

type CommonProps = {
  variant?: VariantType;
  options?: OptionsObject;
};

type MessageProps = {
  message: string;
};

export type CustomContentProps = {
  content: React.ReactNode;
};

export type SnackbarShowProps = (MessageProps | CustomContentProps) & CommonProps;

export interface OptionsObject {
  iconVariant?: IconVariant;
  hideIconVariant?: boolean;
  autoHideDuration?: number;
  containerClassName?: string;
}

export interface SnackbarProviderContext {
  openSnackbar: (params: SnackbarShowProps) => void;
  closeSnackbar: () => void;
}

export const DEFAULT_OPTIONS = {
  hideIconVariant: false,
  variant: 'default' as VariantType,
  autoHideDuration: 10000,
};
