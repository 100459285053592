import { t, Trans } from '@lingui/macro';
import classnames from 'classnames';
import { useFormik } from 'formik';
import React, { FC, useState } from 'react';
import * as Yup from 'yup';

import config from 'config/config';
import { Button } from 'uikit/Button';
import FormRow from 'uikit/FormRow/FormRow';
import Input from 'uikit/Input/Input';
import api from 'utils/api/index';
import routes from 'utils/routeTranslator';

import './ChangePassword.scss';

const passwordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required(t`To pole nie może być puste`)
    .min(config.PASSWORD_MIN_LENGTH, t`Hasło musi mieć co najmniej 6 znaków - dzięki temu będzie bezpieczniejsze`),
  password_confirmation: Yup.string()
    .required(t`To pole nie może być puste`)
    .oneOf([Yup.ref('password'), null], t`Wpisane hasła nie są takie same`),
});

type Props = {
  token: string;
};

const ChangePasswordForm: FC<Props> = (props) => {
  const [formSent, setFormSent] = useState<boolean>(false);
  const goBack = (): void => {
    window.location.href = routes.get('front::index');
  };

  const { values, errors, touched, isSubmitting, setSubmitting, handleSubmit, handleChange, setErrors } = useFormik({
    initialValues: {
      password: '',
      password_confirmation: '',
    },
    validationSchema: passwordValidationSchema,
    onSubmit: () => {
      api
        .sendChangePassword({
          code: props.token,
          password: values.password,
        })
        .then(() => setFormSent(true))
        .catch((error) => {
          const message =
            error.response && error.response.data
              ? error.response.data.errors[0].message
              : t`Coś poszło nie tak...Spróbuj ponownie`;
          setErrors({
            password: message,
          });
        })
        .finally(() => setSubmitting(false));
    },
  });

  const submitButtonCN = classnames('changePassword__submit', {
    changePassword__submit_loading: isSubmitting,
  });

  if (formSent) {
    return (
      <div className="changePassword__sent">
        <p className="changePassword__resetNotification">
          <Trans>Twoje ustawienia zostały zapisane.</Trans>
        </p>
        <Button onClick={goBack}>
          <Trans>Wróć</Trans>
        </Button>
      </div>
    );
  }

  return (
    <form className="changePassword__form" onSubmit={handleSubmit}>
      <FormRow>
        <Input
          id="changePassword__password"
          name="password"
          type="password"
          value={values.password}
          error={touched.password && errors.password}
          onChange={handleChange}
          placeholder={t`Nowe hasło`}
          autoComplete="new-password"
        />
      </FormRow>
      <FormRow>
        <Input
          id="changePassword__password_confirmation"
          name="password_confirmation"
          type="password"
          value={values.password_confirmation}
          error={touched.password_confirmation && errors.password_confirmation}
          onChange={handleChange}
          placeholder={t`Ponownie wpisz nowe hasło`}
          autoComplete="new-password"
        />
      </FormRow>
      <Button
        type="submit"
        kind="cta"
        width="full"
        className={submitButtonCN}
        isLoading={isSubmitting}
        disabled={isSubmitting}
      >
        <Trans>Zapisz hasło</Trans>
      </Button>
    </form>
  );
};

export default ChangePasswordForm;
