import React, { ReactHTML } from 'react';
import classnames from 'classnames';

import './Heading.scss';

export type Heading$Props = {
  children: React.ReactNode;
  className?: string;
  as?: keyof ReactHTML;
};

export function Heading1({ className, children, as }: Heading$Props) {
  const CN = classnames('heading1', className);
  const TagName = as || 'h1';

  return <TagName className={CN}>{children}</TagName>;
}

export function Heading2({ className, children, as }: Heading$Props) {
  const CN = classnames('heading2', className);
  const TagName = as || 'h2';

  return <TagName className={CN}>{children}</TagName>;
}

export function Heading3({ className, children, as }: Heading$Props) {
  const CN = classnames('heading3', className);
  const TagName = as || 'h3';

  return <TagName className={CN}>{children}</TagName>;
}

export function Heading4({ className, children, as }: Heading$Props) {
  const CN = classnames('heading4', className);
  const TagName = as || 'h4';

  return <TagName className={CN}>{children}</TagName>;
}
