import { Trans } from '@lingui/macro';
import React, { FC } from 'react';
import { Route } from 'react-router-dom';

const NotFound: FC = () => {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) {
          staticContext.statusCode = 404;
        }

        return (
          <div className="errorContainer">
            <h1>404</h1>
            <h2>
              <Trans>Podana strona nie istnieje. Chyba musimy zlecić na Fixly jej stworzenie...</Trans>
            </h2>
            <p>
              <Trans>
                Kliknij <a href="/">tutaj</a> aby powrócić do strony głównej
              </Trans>
            </p>
          </div>
        );
      }}
    />
  );
};

export default NotFound;
