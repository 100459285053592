import React, { useState, useEffect } from 'react';

const DefaultFallback = () => <span></span>;

export const NoSSR: React.FC<{ fallback?: JSX.Element }> = ({ children, fallback = <DefaultFallback /> }) => {
  const [isOnClient, setOnClient] = useState(false);
  useEffect(() => setOnClient(true), []);
  return isOnClient ? (children as React.ReactElement) : fallback;
};

export const withNoSSR =
  <P extends object>(WrappedComponent: React.ComponentType<P>) =>
  (props: P) => (
    <NoSSR>
      <WrappedComponent {...props} />
    </NoSSR>
  );

export default NoSSR;
