import loadable from '@loadable/component';

/* @ts-expect-error type mismatch */
export default loadable(() => import('./ProviderHeaderPoints'), {
  fallback: (({ error }) => {
    if (error) {
      console.error(error);
    }

    return null;
  })({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

/* eslint no-console:0 */
