import loadable from '@loadable/component';

export default loadable(() => import('./PayWithFixly'), {
  fallback: (({ error }) => {
    if (error) {
      console.error(error);
    }

    return undefined;
  })({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
