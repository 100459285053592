const FOCUS_RING_CLASS_NAME = 'focus_ring_hidden';
const SHOW_FOCUS_ON = 'keydown';
const HIDE_FOCUS_ON = 'mousedown';
const doesWindowExist: boolean = typeof window !== 'undefined';

/**
 * Removes the classname, which makes focus rings visible
 */
const hideFocusRings = (): void => {
  if (doesWindowExist === false) {
    return;
  }

  window.document.body.classList.add(FOCUS_RING_CLASS_NAME);
};

/**
 * Checking if users tries to navigate over the site using
 * the keyboard -> show focus rings
 */
const showFocusRings = (event: KeyboardEvent): void => {
  if (doesWindowExist === false || event.keyCode !== 9) {
    return;
  }

  window.document.body.classList.remove(FOCUS_RING_CLASS_NAME);

  window.addEventListener(HIDE_FOCUS_ON, hideFocusRings, { once: true });
};

/**
 * Initialize keyboard and mouse event listeners
 */
export const focusManager = (): void => {
  if (doesWindowExist === false) {
    return;
  }

  window.addEventListener(SHOW_FOCUS_ON, showFocusRings);
  window.addEventListener(HIDE_FOCUS_ON, hideFocusRings, { once: true });
  window.document.body.classList.add(FOCUS_RING_CLASS_NAME);
};

export default focusManager;
