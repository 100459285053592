import loadable from '@loadable/component';
import React from 'react';

const Placeholder = () => {
  return (
    <div className="preload spinner">
      <div className="rect1" />
      <div className="rect2" />
      <div className="rect3" />
      <div className="rect4" />
      <div className="rect5" />
    </div>
  );
};

export default loadable(() => import('./Dashboard'), {
  fallback: Placeholder(),
  ssr: false,
});
