import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { FEATURE_FLAGS_QUERY, SET_FEATURE_FLAGS } from './FeatureFlags.helpers';
import { useEffect, useState } from 'react';

export const useSetFeatureFlags = () => {
  const [setFeatureFlags, { data, loading, error }] = useMutation<{ setFeatureFlags: boolean }>(SET_FEATURE_FLAGS);

  return {
    setFeatureFlags,
    data,
    loading,
    error,
  };
};

export const useFeatureFlag = (featureFlagId: string) => {
  const [queryFeatugreFlag, { loading, error, data }] = useLazyQuery<{
    featureFlags: { enabled: boolean; name: string }[];
  }>(FEATURE_FLAGS_QUERY, { ssr: false });
  const [featureFlag, setFeatureFlag] = useState<
    | {
        enabled: boolean;
        name: string;
      }
    | undefined
  >();

  useEffect(() => {
    if (data) {
      const featureFlagData = data.featureFlags.find(({ name }) => name === featureFlagId);
      setFeatureFlag(featureFlagData);
    }
  }, [data, featureFlagId]);
  return { queryFeatugreFlag, loading, error, featureFlag };
};
