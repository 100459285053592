import { ApolloLink, fromPromise, NextLink, Operation, toPromise } from '@apollo/client';
import auth from '../../auth/auth';

const getToken = async (operation: Operation): Promise<string | undefined> => {
  if (typeof window === 'undefined') return;

  const isAnonymous = Boolean(operation.variables && operation.variables.anonymous);
  if (isAnonymous) return;

  return await auth.getAccessTokenWithRefresh();
};
export const authorizationMiddleware = new ApolloLink((operation: Operation, forward: NextLink) => {
  const promise = getToken(operation).then((token) => {
    if (!token) return toPromise(forward(operation));

    operation.setContext((context: Record<string, any>) => {
      return {
        ...context,
        headers: {
          ...(context.headers || {}),
          'Authorization': token,
        },
      };
    });

    return toPromise(forward(operation));
  });

  return fromPromise(promise);
});
