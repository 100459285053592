import { gql } from '@apollo/client';

import { client } from '../apolloConnector/apolloConnector';

// TYPES

export type UserRegistrationCheckResponse = {
  checkUserRegistered: {
    isProvider: boolean;
    isGuest: boolean;
    name: string;
  } | null;
};

type UserRegistrationCheckVars = {
  login: string;
  ignoreProviderByPhoneError?: boolean;
  includeExternal?: boolean;
};

// HELPERS

export const checkUserRegisteredQuery = gql`
  query CheckUserRegistered($login: String!, $ignoreProviderByPhoneError: Boolean = false) {
    checkUserRegistered(login: $login, ignoreProviderByPhoneError: $ignoreProviderByPhoneError) {
      isProvider
      isGuest
      name
    }
  }
`;

export const isUserRegistered = (login: string, isWizard = false) =>
  client.query<UserRegistrationCheckResponse, UserRegistrationCheckVars>({
    query: checkUserRegisteredQuery,
    variables: {
      login,
      ignoreProviderByPhoneError: isWizard,
    },
  });

export const isLoggedInQuery = gql`
  query isLoggedIn {
    isLoggedIn
  }
`;
