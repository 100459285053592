// Unicode source: https://en.wikipedia.org/wiki/Polish_alphabet#Computer_encoding
const unicodeArray = [
  '\\u0104', // Ą
  '\\u0106', // Ć
  '\\u0118', // Ę
  '\\u0141', // Ł
  '\\u0143', // Ń
  '\\u00D3', // Ó
  '\\u015A', // Ś
  '\\u0179', // Ź
  '\\u017B', // Ż
  '\\u0105', // ą
  '\\u0107', // ć
  '\\u0119', // ę
  '\\u0142', // ł
  '\\u0144', // ń
  '\\u00F3', // ó
  '\\u015B', // ś
  '\\u017A', // ź
  '\\u017C', // ż
].join('|');

const polishAlphabetRegExpString = `[(${unicodeArray})a-zA-Z]`;

export default polishAlphabetRegExpString;
