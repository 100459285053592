import React from 'react';

import { IconVariant } from '../Snackbar.helpers';
import { ReactComponent as ErrorIcon } from '../icons/error.svg';
import { ReactComponent as InfoIcon } from '../icons/info.svg';
import { ReactComponent as SuccessIcon } from '../icons/success.svg';

const iconStyles = {
  fontSize: 20,
  marginInlineEnd: 8,
};

const defaultIconVariants: IconVariant = {
  default: undefined,
  success: <SuccessIcon style={iconStyles} />,
  error: <ErrorIcon style={iconStyles} />,
  info: <InfoIcon style={iconStyles} />,
};

export default defaultIconVariants;
