import classnames from 'classnames';
import React, { FC, useEffect, useRef, useState } from 'react';

import './DropdownMenu.scss';

type Props = {
  title: string;
  theme?: 'dark';
  ariaLabel?: string;
  dataTestId?: string;
  className?: string;
};

export const DropdownMenu: FC<Props> = (props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const hostRef = useRef<HTMLDivElement>(null);
  const isDarkTheme = props.theme === 'dark';

  const handleClick = (e: Event) => {
    const target = e.target as HTMLTextAreaElement;
    if (
      target.tagName === 'A' ||
      (hostRef.current && target instanceof HTMLElement && !hostRef.current.contains(target))
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick, false);
    return () => {
      document.removeEventListener('click', handleClick, false);
    };
  }, []);

  const dropdownMenuCN = classnames('dropdownMenu', props.className, {
    dropdownMenu_dark: isDarkTheme,
  });

  const dropdownMenuButtonCN = classnames('dropdownMenu__button', {
    dropdownMenu__button_open: isDropdownOpen,
  });

  const dropdownMenuDropdownCN = classnames('dropdownMenu__dropdown', {
    dropdownMenu__dropdown_open: isDropdownOpen,
    dropdownMenu__dropdown_dark: isDarkTheme,
  });

  const dropdownWrapperCN = classnames('dropdownMenu__dropdownWrapper', {
    dropdownMenu__dropdownWrapper_open: isDropdownOpen,
  });

  return (
    <div className={dropdownMenuCN} ref={hostRef}>
      <button
        type="button"
        className={dropdownMenuButtonCN}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        aria-expanded={isDropdownOpen}
        aria-haspopup
        aria-label={props.ariaLabel && props.ariaLabel}
        data-testid={props.dataTestId}
      >
        {props.title}
      </button>

      <div className={dropdownWrapperCN}>
        <div className={dropdownMenuDropdownCN} aria-hidden={!isDropdownOpen}>
          <div className="dropdownMenu__container">{props.children}</div>
        </div>
      </div>
    </div>
  );
};
