import React from 'react';
import { Route, Redirect, RedirectProps } from 'react-router-dom';

import routes from 'utils/routeTranslator';

type RedirectWithStatus$Props = RedirectProps & {
  status: number;
  to: string;
};

export default function RedirectWithStatus({ from, to, status, exact, ...rest }: RedirectWithStatus$Props) {
  return (
    <Route
      path={from}
      exact={exact}
      render={({ staticContext, match: { params }, location }) => {
        if (staticContext) {
          staticContext.statusCode = status;
        }

        return (
          <Redirect
            to={{
              ...location,
              pathname: routes.get(to, { ...params }),
            }}
            {...rest}
          />
        );
      }}
    />
  );
}
