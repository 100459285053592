import { useEffect, useState } from 'react';

import loadDynamicScript from '../loadDynamicScript';

type Output = {
  SVC: Window['embedded_svc'];
  loading: boolean;
};

export const useEmbeddedSVC = (): Output => {
  const [loading, setLoading] = useState<boolean>(false);
  const [SVC, setSVC] = useState<Window['embedded_svc']>();

  useEffect(() => {
    const run = async () => {
      const host = window.location.host;
      const protocol = window.location.protocol;
      setLoading(true);
      await loadDynamicScript(`${protocol}//${host}/esw.min.js`, 'salesforce-esw');
      setSVC(window.embedded_svc);
      setLoading(false);
    };

    // TODO: this potentially creates lifecycle bugs
    if (window.embedded_svc) setSVC(window.embedded_svc);
    else run();
  }, []);

  return {
    SVC,
    loading,
  };
};
