import c from 'classnames';
import React, { FC } from 'react';

import { ReactComponent as AndroidIcon } from 'common/icons/android_icon_grey.svg';

import Button, { Button$Props } from '../Button';
import '../Button.scss';

const AndroidButton: FC<Button$Props> = ({ children, className, ...rest }) => {
  return (
    <Button icon={<AndroidIcon aria-hidden />} className={c('button_app', className)} {...rest}>
      {children}
    </Button>
  );
};

export default AndroidButton;
