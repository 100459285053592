import localStorage from 'utils/localStorage/localStorage';

export const getApiUrl = (key = String(localStorage.getItem('fixlyEndpointKey'))) => {
  switch (key) {
    case 'staging_olx':
      return 'https://api.fixly.stg.01.eu-west-1.eu.olx.org';
    case 'staging_new':
      return 'https://api.qa.fix.rebbix.dev';
    case 'staging_old':
      return 'https://api.fix.rebbix.dev';
    case 'localhost':
    default:
      if (typeof window === 'undefined') return String(process.env.SERVICES_INTERNAL_API_URL);
      return `${window.location.protocol}//${window.location.hostname}:8000`;
  }
};

export const getAdminApiUrl = (key = String(localStorage.getItem('fixlyEndpointKey'))) => {
  switch (key) {
    case 'staging_olx':
      return 'https://admin-api.fixly.stg.01.eu-west-1.eu.olx.org';
    case 'staging_new':
      return 'https://admin-api.qa.fix.rebbix.dev';
    case 'staging_old':
      return 'https://admin-api.fix.rebbix.dev';
    case 'localhost':
    default:
      if (typeof window === 'undefined') return String(process.env.SERVICES_ADMIN_API_PUBLIC_URL);
      return `${window.location.protocol}//${window.location.hostname}:8010`;
  }
};

export const getChatUrl = (key = String(localStorage.getItem('fixlyEndpointKey'))) => {
  switch (key) {
    case 'staging_olx':
      return 'https://chat.fixly.stg.01.eu-west-1.eu.olx.org';
    case 'staging_new':
      return 'mqtts://chat.qa.fix.rebbix.dev';
    case 'staging_old':
      return 'mqtts://chat.fix.rebbix.dev';
    case 'localhost':
    default:
      if (typeof window === 'undefined') return 'mqtt://chat:8082';
      return `${window.location.protocol}//${window.location.hostname}:8082`;
  }
};

export const getWebNotificationsUrl = (key = String(localStorage.getItem('fixlyEndpointKey'))) => {
  switch (key) {
    case 'staging_olx':
      return 'https://web-notify.fixly.stg.01.eu-west-1.eu.olx.org';
    case 'staging_new':
      return 'mqtts://web-notify.qa.fix.rebbix.dev';
    case 'staging_old':
      return 'mqtts://web-notify.fix.rebbix.dev';
    case 'localhost':
    default:
      if (typeof window === 'undefined') return 'mqtt://web-notify:8092';
      return `${window.location.protocol}//${window.location.hostname}:8092`;
  }
};

type IEnv = (key: string, def?: string) => string;

const isDev = process.env.NODE_ENV !== 'production';

const env: IEnv = (key, def = '') => {
  // Next line is needed as a workaround to switch between endpoints by EndpointSwitcher
  // when app is running on localhost in dev mode
  if (isDev) return def;
  return process.env[`REACT_APP_${key}`] || process.env[key] || def;
};

const apiUrl = env('API_URL', getApiUrl());
const adminApiUrl = env('ADMIN_API_URL', getAdminApiUrl());
const chatUrl = env('CHAT_URL', getChatUrl());
const webNotificationsUrl = env('WEB_NOTIFICATIONS_URL', getWebNotificationsUrl());

const config = {
  isDevEnv: isDev,
  API_URL: apiUrl,
  ADMIN_API_URL: adminApiUrl,
  CHAT_URL: chatUrl,
  WEB_NOTIFICATIONS_URL: webNotificationsUrl,
  APP_LOCALITY: 'pl',
  SITE_NAME: 'Fixly.pl',
  SITE_URL: env('SITE_URL', 'https://fixly.pl'),
  PUBLIC_URL: env('SERVICES_PHP_PUBLIC_URL', 'https://fixly.pl'),
  API_VERSION: '1',
  STOP_IMPERSONATE_PATH: '/impersonate/stop',

  // TODO: Store map url with key in proper way
  GOOGLE_MAP_API_URL: env(
    'GOOGLE_MAP_API_URL',
    'https://maps.googleapis.com/maps/api/js?key=AIzaSyDBSI61tFN4Moxu5E9NDvyO8wOJ2A0Glnw&libraries=places'
  ),
  GOOGLE_MAPS_API_KEY: env('GOOGLE_MAPS_API_KEY', 'AIzaSyDBSI61tFN4Moxu5E9NDvyO8wOJ2A0Glnw'),
  GOOGLE_MAP_URL: env('GOOGLE_MAP_URL', 'https://www.google.com/maps/search/?api=1&query='),
  REQUEST_PER_PAGE: 15,
  NOTIFICATIONS_PER_PAGE: 15,
  MOBILE_BREAK_POINT: 568,
  SHOW_ENDPOINT_SWITCHER: process.env.NODE_ENV === 'development',
  NOTIFICATIONS_DEBUG_LOGS_ON: false,
  MAX_REQUEST_SLOTS: 5,
  MIN_VISIBLE_DISTANCE: 20, // KM
  MIN_CITY_SEARCH_QUERY_LENGTH: 2,
  ANALYTICS_ENABLED: process.env.NODE_ENV !== 'development' && env('FEATURES_ANALYTICS_ENABLED', '1') === '1',
  ANALYTICS_CALLBACK_TIMEOUT: parseInt(env('FEATURES_ANALYTICS_CALLBACK_TIMEOUT', '2000'), 10),
  MAX_PRICE_NUMBER_LENGTH: 25,
  MAX_UPLOAD_IMAGE_SIZE: 10, // 10MB
  MIN_DISTANCE_TO_REQUEST: 20, // 20KM
  MAX_MESSAGE_SIZE: 800,
  MAX_FEEDBACK_IMAGE_COUNT: 5,
  MIN_FEEDBACK_MESSAGE_LENGTH: 10,
  MAX_FEEDBACK_MESSAGE_LENGTH: 600,
  MIN_SHORT_DESCRIPTION_LENGTH: 50,
  MAX_SHORT_DESCRIPTION_LENGTH: 200,
  MAX_COMPANY_NAME_LENGTH: 255,
  MAX_PROVIDER_SERVICES_COUNT: 3,
  USERNAME_MIN_LENGTH: 2,
  PROVIDER_NAME_MIN_LENGTH: 5,
  PROVIDER_NAME_MAX_LENGTH: 35,
  PASSWORD_MIN_LENGTH: 6,
  MIN_STARTING_YEAR: 1900,
  MINUTES_TO_SHOW_ONLINE: 3,
  TOOLTIP_NOTIFICATION_VISIBILITY_DURATION: 30000, // 30s
  LOCALE: 'pl',

  // UTILITY
  FORCE_AUTHORIZATION_HEADERS_USAGE: process.env.NODE_ENV === 'development' && apiUrl.indexOf('fixpl-local') === -1, // Use authorization tokens only when you are on dev and trying to access staging servers

  // APPS
  ANDROID_APP_URL: env(
    'ANDROID_APP_URL',
    'https://play.google.com/store/apps/details?id=com.fixly.android.provider&referrer=channel-feature-campaign'
  ),
  ANDROID_APP_URL_CUSTOMER: env(
    'ANDROID_CUSTOMER_APP_URL',
    'https://play.google.com/store/apps/details?id=com.fixly.android.user&referrer=channel-feature-campaign'
  ),
  IOS_APP_URL: env('IOS_APP_URL', 'https://apps.apple.com/pl/app/id1293721839'),
  IOS_APP_URL_CUSTOMER: env('IOS_CUSTOMER_APP_URL', 'https://apps.apple.com/pl/app/id1508502089'),
  IOS_APP_ID_CUSTOMER: env('IOS_APP_ID_CUSTOMER', '1508502089'),

  HELPDESK_COOKIE_URL: env('HELPDESK_COOKIE_URL', 'https://pomoc.fixly.pl/hc/pl/articles/360000013859'),
  HELPDESK_TOS_URL: env('HELPDESK_TOS_URL', 'https://pomoc.fixly.pl/hc/pl/articles/360000013459'),
  HELPDESK_PRIVACY_URL: env('HELPDESK_PRIVACY_URL', 'https://pomoc.fixly.pl/hc/pl/articles/360000012620'),
  HELPDESK_PRIVACY_MEDIA_URL: env(
    'HELPDESK_PRIVACY_MEDIA_URL',
    'https://pomoc.fixly.pl/hc/pl/articles/360006995320-Polityka-przetwarzania-danych-w-sektorze-B2B-i-w-kontaktach-z-mediami)'
  ),
  HELPDESK_GDPR_URL: env('HELPDESK_GDPR_URL', 'https://pomoc.fixly.pl/hc/pl/categories/360000004080'),
  HELPDESK_CONTACT_URL: env('HELPDESK_CONTACT_URL', 'https://pomoc.fixly.pl/hc/pl/requests/new?ticket_form_id=192585'),
  HELPDESK_POINTS_URL: env('HELPDESK_POINTS_URL', 'https://pomoc.fixly.pl/hc/pl/articles/360009535479'),
  HELPDESK_NO_FREE_SLOTS_URL: env('HELPDESK_NO_FREE_SLOTS_URL', 'https://pomoc.fixly.pl/hc/pl/articles/360004808140'),
  HELPDESK_PUBLIC_PROFILE_URL: env(
    'HELPDESK_PUBLIC_PROFILE_URL',
    'https://pomoc.fixly.pl/hc/pl/categories/4405047836434-Twoja-strona-i-Twoja-strona-Premium'
  ),

  // PAYMENTS
  PAYU_ENDPOINT: env('PAYU_ENDPOINT', 'https://secure.snd.payu.com'),
  PAYU_VAS_MERCHANT_ID: env('REACT_APP_PAYU_VAS_POS_ID', '354316'),
  PAYU_POINTS_MERCHANT_ID: env('REACT_APP_PAYU_POINTS_POS_ID', '396606'),
  PAYU_PWF_MERCHANT_ID: env('REACT_APP_PAYU_PWF_POS_ID', '407262'),

  PAYU_TOS_URL: env(
    'PAYU_TOS_URL',
    'http://static.payu.com/sites/terms/files/payu_terms_of_service_single_transaction_pl_pl.pdf'
  ),

  FIX_FACEBOOK_URL: env('FACEBOOK_URL', 'https://www.facebook.com/fixlypl/'),
  FIX_YOUTUBE_URL: env('YOUTUBE_URL', 'https://www.youtube.com/channel/UCQDvMXhFoiMc3qK0mo5NPJQ'),
  FIX_BLOG_URL: env(
    'BLOG_URL',
    'https://fixly.pl/blog/?utm_source=own&utm_medium=website_footer&utm_campaign=Fixly_blog'
  ),
  FIX_SP_BLOG_URL: env('SP_BLOG_URL', 'https://fixly.pl/niezbednik/'),
  FIX_OLX_URL: env('OLX_URL', 'https://www.olx.pl/?utm_source=footer-fixly&utm_medium=footer&utm_campaign=Fixly'),
  FIX_OTODOM_URL: env(
    'OTODOM_URL',
    'https://www.otodom.pl/?utm_source=footer-fixly&utm_medium=footer&utm_campaign=Fixly'
  ),
  FIX_OTOMOTO_URL: env(
    'OTOMOTO_URL',
    'https://www.otomoto.pl/?utm_source=footer-fixly&utm_medium=footer&utm_campaign=Fixly'
  ),
  FIX_CARSMILE_URL: env('CARSMILE_URL', 'https://carsmile.pl'),
  FIX_HELPDESK_URL: env('HELPDESK_URL', 'https://pomoc.fixly.pl/hc/pl'),
  FIX_HELPDESK_COOKIE_URL: env('HELPDESK_COOKIE_URL', 'https://pomoc.fixly.pl/hc/pl/articles/360000013859'),
  FIX_HELPDESK_TOS_URL: env('HELPDESK_TOS_URL', 'https://pomoc.fixly.pl/hc/pl/articles/360000013459'),
  FIX_HELPDESK_PRIVACY_URL: env('HELPDESK_PRIVACY_URL', 'https://pomoc.fixly.pl/hc/pl/articles/360000012620'),
  FIX_HELPDESK_CONTACT_URL: env('HELPDESK_CONTACT_URL', 'https://pomoc.fixly.pl/hc/pl/requests/new'),
  FIX_HELPDESK_PAY_WITH_FIXLY_URL: env(
    'FIX_HELPDESK_PAY_WITH_FIXLY_URL',
    'https://pomoc.fixly.pl/hc/pl/categories/4405047862418-P%C5%82atno%C5%9Bci-Fixly'
  ),
  FIX_HELPDESK_FAQ_FOR_CUSTOMERS_URL: env(
    'FIX_HELPDESK_FAQ_FOR_CUSTOMERS_URL',
    'https://pomoc.fixly.pl/hc/pl/sections/4410823768850-FAQ-dla-Zleceniodawcy-'
  ),
  FIX_HELPDESK_INSURANCE_EXPLANATION_FOR_CUSTOMERS_URL: env(
    'FIX_HELPDESK_INSURANCE_EXPLANATION_FOR_CUSTOMERS_URL',
    'https://pomoc.fixly.pl/fixlyhelp/s/article/Wykonawca-jest-ubezpieczony-co-to-oznacza'
  ),
  FIX_IKEA_PRINCIPLES_PDF_URL: env(
    'FIX_IKEA_PRINCIPLES_PDF_URL',
    'https://cdn.fixly.prd.01.eu-west-1.eu.olx.org/static_pages/ikea/principles-of-cooperation.pdf'
  ),

  OLX_FRICTION_FLOW_ENABLED: env('OLX_FRICTION_FLOW_ENABLED', '0') === '1',
  OLX_AUTH_DOMAIN: env('OLX_AUTH_DOMAIN', 'pl.staging.login.olx.com'),
  OLX_AUTH_DOMAIN_FALLBACK: env('OLX_AUTH_DOMAIN_FALLBACK', 'login.pl.stg.eu.olx.org'),
  OLX_AUTH_CLIENT_ID: env('OLX_AUTH_CLIENT_ID', '42u56vn3jn49fu1qms9o09s67e'),
  OLX_AUTH_CALLBACK_URL: env('OLX_AUTH_CALLBACK_URL', 'https://fixly.stg.01.eu-west-1.eu.olx.org/olx/auth-callback'),
  OLX_AUTH_TOKEN_ENDPOINT: env(
    'OLX_AUTH_TOKEN_ENDPOINT',
    'https://api.fixly.stg.01.eu-west-1.eu.olx.org/olx-auth/oauth2/token'
  ),
  OLX_AUTH_ID_TOKEN_ISSUER: env(
    'OLX_AUTH_ID_TOKEN_ISSUER',
    'https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_VPglCdty5'
  ),

  /**
   * Salesforce's configuration is from the ticket https://naspersclassifieds.atlassian.net/browse/GAT-55
   */
  SALESFORCE_BASE_CORE_URL: env('SALESFORCE_BASE_CORE_URL', 'https://bolt--uat.sandbox.my.salesforce.com'),
  SALESFORCE_COMMUNITY_ENDPOINT_URL: env(
    'SALESFORCE_COMMUNITY_ENDPOINT_URL',
    'https://bolt--uat.sandbox.my.salesforce-sites.com/chatSetup'
  ),
  SALESFORCE_ORG_ID: env('SALESFORCE_ORG_ID', '00D8E0000009cTM'),
  SALESFORCE_BASE_LIVE_AGENT_CONTENT_URL: env(
    'SALESFORCE_BASE_LIVE_AGENT_CONTENT_URL',
    'https://c.la1-c1cs-lo3.salesforceliveagent.com/content'
  ),
  SALESFORCE_BUTTON_ID: env('SALESFORCE_BUTTON_ID', '5738E0000008Oqz'),
  SALESFORCE_BASE_LIVE_AGENT_URL: env(
    'SALESFORCE_BASE_LIVE_AGENT_URL',
    'https://d.la1-c1cs-lo3.salesforceliveagent.com/chat'
  ),
  SALESFORCE_ESW_LIVE_AGENT_DEV_NAME: env(
    'SALESFORCE_ESW_LIVE_AGENT_DEV_NAME',
    'EmbeddedServiceLiveAgent_Parent04I8E0000004CRgUAM_185a31bad49'
  ),
};

export default config;

/* eslint max-len:0 */
