import React, { ReactNode } from 'react';
import classnames from 'classnames';

import './FormRow.scss';

type FormRow$Props = {
  children: ReactNode;
  className?: string;
};

export default function FormRow({ children, className }: FormRow$Props) {
  return <div className={classnames('formRow', className)}>{children}</div>;
}
