import { Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FC } from 'react';

type LoginLinkStepProps = {
  login: string;
  loading?: boolean;
  disabled?: boolean;
  challengeElement: JSX.Element | null;
  handleResend: () => void;
};

const LoginLinkStep: FC<LoginLinkStepProps> = (props) => {
  const resendButtonCN = classnames('login__resendButton', {
    'login__resendButton_loading': props.loading,
  });

  return (
    <div className="login__resetLink">
      <p className="login__loginLinkSent">
        <Trans>
          Wysłaliśmy tymczasowe dane do logowania na adres <b>{props.login}</b>. Sprawdź swoją skrzynkę mailową
        </Trans>
      </p>
      {props.challengeElement}
      <button type="button" onClick={props.handleResend} className={resendButtonCN} disabled={props.disabled}>
        <Trans>Wyślij ponownie</Trans>
      </button>
    </div>
  );
};

export default LoginLinkStep;
