import { pathToRegexp } from 'path-to-regexp';
import { ReactNode } from 'react';
import nl2ReactBR from 'react-nl2br';

import { TPremium$UserVas } from 'Components/Settings/Premium/Premium.helpers';
import { VasTypeEnum } from 'Components/WalletModalNew/WalletModal.helpers';
import { compose } from 'common/helpers/compose';
import routes from 'utils/routeTranslator';

import parseQueryString from './parseQueryString';
import platformDetector from './platformDetector';

const NL2BR_REGEXP = /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g;

export const nl2br = (text?: string): string => (text ? text.replace(NL2BR_REGEXP, '$1<br/>$2') : '');

export const nl2brArray = (content: ReactNode): ReactNode => {
  if (!content) return '';

  if (Array.isArray(content)) {
    return content.map(nl2brArray);
  }

  if (typeof content === 'string') {
    return nl2ReactBR(content);
  }

  return content;
};
export const truncate = (str: string, length: number) => str.slice(0, length);
export const truncateWithEllipsis = (str: string, length: number, truncateWithChar = '...'): string => {
  if (str.trim().length <= length) {
    return str;
  }

  return `${truncate(str, length).trim()}${truncateWithChar}`;
};
export const isScrollAtBottom = (element: HTMLElement, difference = 0): boolean => {
  if (!element) {
    return false;
  }

  const { scrollTop, scrollHeight, offsetHeight } = element;
  return scrollTop + 10 >= scrollHeight - offsetHeight - difference;
};
// noinspection JSUnusedGlobalSymbols
export const isScrollAtTop = (element: HTMLElement): boolean => {
  if (!element) {
    return false;
  }

  const { scrollTop } = element;
  return scrollTop === 0;
};
// noinspection JSUnusedGlobalSymbols
export const listenToScrollAtBottom = (element: HTMLElement, callback: () => unknown | null | undefined) => {
  const checkScrollPosition = () => {
    if (!isScrollAtBottom(element)) {
      return;
    }

    element.removeEventListener('scroll', checkScrollPosition);
    callback();
  };

  element.addEventListener('scroll', checkScrollPosition);
};
export const isWindow: boolean = typeof window !== 'undefined';
export const isProduction: boolean = process.env.NODE_ENV === 'production';
export const isDocument: boolean = typeof document !== 'undefined';
export const isBrowser: boolean = isWindow && isDocument;

/**
 * Makes from 987654321 -> 987 654 321
 */
export const prettifyNumber = (num = 0): string => {
  let result = '';
  const stringRepresentation = String(num);

  if (stringRepresentation.length <= 3) {
    return stringRepresentation;
  }

  for (let i = stringRepresentation.length; i > 0; i -= 3) {
    const chunk = stringRepresentation.slice(i - 3, i);

    if (chunk) {
      result = `${chunk} ${result}`;
    }

    if (i < 3) {
      return `${stringRepresentation.slice(0, i)} ${result}`.trim();
    }
  }

  return result.trim();
};
export const stagingUrls = [
  'https://qa.fix.rebbix.dev',
  'https://fix.rebbix.dev',
  'http://fixpl-local.dev.rebbix.com:3000',
];
// noinspection JSUnusedGlobalSymbols
export const isStagingEnv = (): boolean => isWindow && stagingUrls.indexOf(window.location.origin) !== -1;

export function isGraphqlError(e: Error): boolean {
  return 'graphQLErrors' in e;
}

// eslint-disable-next-line no-use-before-define
export const stripNetworkPrefix = (message: string) => stringSubtract(message, 'Network error: ');
// eslint-disable-next-line no-use-before-define
export const stripGraphqlPrefix = (message: string) => stringSubtract(message, 'GraphQL error: ');
export const stripErrorPrefixes = compose(stripGraphqlPrefix, stripNetworkPrefix);

/**
 * Converts `49` to `49,00 {currency}`
 * note the `comma` sign instead of `point`
 */
export const formatPrice = (price: number): string => `${price.toFixed(2).replace('.', ',')} zł`;
export const isRoute = (pathname: string, routeKey: string) => pathToRegexp(routes.get(routeKey)).test(pathname);
export const getUserVasByType = (vases: TPremium$UserVas[], vasType: VasTypeEnum): TPremium$UserVas | undefined =>
  vases.find((userVas) => userVas.vas.type === vasType);
export const normalizePathname = (path: string): string =>
  path[path.length - 1] === '/' ? path.slice(0, path.length - 1) : path;

/**
 * (`something else`, `thing`) => `some else`;
 */
export const stringSubtract = (originalString: string, subtrahend: string): string => {
  const subtractIndex = originalString ? originalString.indexOf(subtrahend) : -1;
  return subtractIndex !== -1
    ? originalString.slice(0, subtractIndex) + originalString.slice(subtractIndex + subtrahend.length)
    : originalString.replace(/^.*\/\/[^\/]+/, '');
};
export const composeSMSHref = (phone: string, content: string): string => {
  const encodedContent = encodeURIComponent(content);
  return platformDetector.Android() ? `sms://${phone}/?body=${encodedContent}` : `sms:${phone}&body=${encodedContent}`;
};
export const getRandomInt = (end: number, start = 0): number => Math.round(Math.random() * (end - start)) + start;
export const getFullName = (user: { firstName: string; lastName: string }): string =>
  `${user.firstName} ${user.lastName}`;

/**
 * 'Warszawa, mazowieckie' -> 'Warszawa'
 */
export function cityNameShortener(cityName: string): string | undefined {
  return cityName.split(',').shift();
}

type CityItem = {
  slug: string;
  name: string;
};

export const largestCities: CityItem[] = [
  {
    slug: 'warszawa',
    name: 'Warszawa',
  },
  {
    slug: 'krakow',
    name: 'Kraków',
  },
  {
    slug: 'lodz',
    name: 'Łódź',
  },
  {
    slug: 'wroclaw',
    name: 'Wrocław',
  },
  {
    slug: 'poznan',
    name: 'Poznań',
  },
  {
    slug: 'gdansk',
    name: 'Gdańsk',
  },
];

// TODO: fix the problem GS-9213
// eslint-disable-next-line ssr-friendly/no-dom-globals-in-module-scope
export const isProd: boolean = typeof window !== 'undefined' && /fixly\.pl/.test(window.origin);
export const isMobileWebView = () =>
  Boolean(typeof window !== 'undefined' && parseQueryString(window.location.search).webview);
export const escapeHtml = (string: string) => {
  const entityMap: Record<string, string> = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;',
    '/': '&#x2F;',
    '`': '&#x60;',
    '=': '&#x3D;',
  };
  return string ? String(string).replace(/[&<>"'`=/]/g, (s) => entityMap[s]) : '';
};

export const removeExtraLineBreaks = (text: string): string => {
  return text && text.replace(/[\r\n]{2,}/g, '\n\n');
};
