import { Trans } from '@lingui/macro';
import React, { FC } from 'react';

import analytics from 'utils/analytics/analytics';
import routes from 'utils/routeTranslator';

import AllServicesNav from './AllServicesNav/AllServicesNav';
import BestRequestsNav from './BestRequestsNav';
import './Navigation.scss';

interface Props {
  providerView?: boolean;
  onLoginOpen?: () => void;
  onComplexLoginOpen?: () => void;
}

const GuestNavigation: FC<Props> = ({ providerView, onLoginOpen, onComplexLoginOpen }) => {
  const onProviderRegisterClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    analytics.global.registerProviderClick(() => {
      window.location.href = e.currentTarget.href;
    }, 'header');
  };
  return (
    <>
      {providerView ? (
        <ul className="navigation__list">
          <li className="navigation__item">
            <AllServicesNav mode="dark" routeL2 />
          </li>
          <li className="navigation__item">
            <BestRequestsNav mode="dark" />
          </li>
          <li className="navigation__item">
            <button type="button" className="navigation__link navigation__link_provider" onClick={onLoginOpen}>
              <Trans>Zaloguj się</Trans>
            </button>
          </li>
          <li className="navigation__item">
            <a href={routes.get('front::index')} className="navigation__link navigation__link_provider">
              <Trans>Fixly dla klientów</Trans>
            </a>
          </li>
        </ul>
      ) : (
        <ul className="navigation__list">
          <li className="navigation__item">
            <AllServicesNav />
          </li>
          <li className="navigation__item navigation__item_complexLogin">
            <button
              type="button"
              className="navigation__link"
              onClick={onComplexLoginOpen}
              data-testid="myRequestsButton"
            >
              <Trans>Moje zapytania</Trans>
            </button>
          </li>
          <li className="navigation__item">
            <button type="button" className="navigation__link" onClick={onLoginOpen} data-testid="loginButton">
              <Trans>Zaloguj się</Trans>
            </button>
          </li>
          <li className="navigation__item">
            <a
              href={routes.get('front::provider.register', {})}
              className="navigation__link navigation__link_sp"
              onClick={onProviderRegisterClick}
              data-testid="spRegisterButton"
            >
              <Trans>Fixly dla wykonawców</Trans>
            </a>
          </li>
        </ul>
      )}
    </>
  );
};

export default GuestNavigation;
