import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { ReactComponent as GlobIcon } from 'common/icons/web.svg';
import routes from 'utils/routeTranslator';

import './LangSwitcher.scss';

const LangSwitcher: FC = () => {
  const location = useLocation();
  const plRegisterRoute = routes.get('front::provider.register');
  const ukRegisterRoute = routes.get('front::provider.register.uk');
  const isPLActive = location.pathname === plRegisterRoute;
  const isUKActive = location.pathname === ukRegisterRoute;

  if (!Boolean(isPLActive || isUKActive)) return null;

  return (
    <div className="langSwitcher">
      <GlobIcon className="langSwitcher__icon" aria-hidden />
      <a href={isPLActive ? undefined : plRegisterRoute} className="langSwitcher__locale">
        Polski
      </a>{' '}
      |{' '}
      <a href={isUKActive ? undefined : ukRegisterRoute} className="langSwitcher__locale">
        Українська
      </a>
    </div>
  );
};

export default LangSwitcher;
