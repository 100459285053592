import { Trans } from '@lingui/macro';
import React, { FC } from 'react';

import { CarsmileIcon } from 'common/icons/CarsmileIcon';
import { OlxIcon } from 'common/icons/OlxIcon';
import config from 'config/config';

type Props = {
  isProvider?: boolean;
};

const FooterSocials: FC<Props> = ({ isProvider }) => (
  <div className="footer__row footer__row_socials">
    <div className="footer__socials">
      <a
        href={config.FIX_FACEBOOK_URL}
        className="footer__social footer__social_facebook"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        <span className="visually-hidden">Facebook</span>
      </a>
      <a
        href={config.FIX_YOUTUBE_URL}
        className="footer__social footer__social_youtube"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        <span className="visually-hidden">Youtube</span>
      </a>
      <a href={isProvider ? config.FIX_SP_BLOG_URL : config.FIX_BLOG_URL} className="footer__blog">
        <Trans>Blog</Trans>
      </a>
      <span className="footer__copyright">
        <Trans>Copyright &copy; * 2016 - {new Date().getFullYear()} Fixly</Trans>
      </span>
    </div>
    <div className="footer__partners">
      <a
        href={config.FIX_OLX_URL}
        className="footer__olx footer__olx_olx events-tap_link_olx"
        target="_blank"
        rel="noopener noreferrer"
      >
        <OlxIcon />
        olx.pl
      </a>
      <a
        href={config.FIX_OTODOM_URL}
        className="footer__olx footer__olx_otodom events-tap_link_otodom"
        target="_blank"
        rel="noopener noreferrer"
      >
        otodom.pl
      </a>
      <a
        href={config.FIX_OTOMOTO_URL}
        className="footer__olx footer__olx_otomoto events-tap_link_otomoto"
        target="_blank"
        rel="noopener noreferrer"
      >
        otomoto.pl
      </a>
      <a
        href={config.FIX_CARSMILE_URL}
        className="footer__olx footer__olx_carsmile events-tap_link_carsmile"
        target="_blank"
        rel="noopener noreferrer"
      >
        <CarsmileIcon /> carsmile.pl
      </a>
    </div>
  </div>
);

export default FooterSocials;
