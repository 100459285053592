import React from 'react';
import classnames from 'classnames';
import config from '../../config/config';
import routes from '../../utils/routeTranslator';
import logo from 'common/icons/fixly_logo.svg';
import './Header.scss';
import OlxBanner from './OlxBanner';

const EmptyHeader: React.FC<{ isProviderLayout: boolean }> = ({ isProviderLayout }) => {
  const wrapperCN = classnames({
    'header__wrapper': true,
    'header__wrapper_provider': isProviderLayout,
  });

  const logoCN = classnames({
    'header__logo': true,
    'header__logo_provider': isProviderLayout,
  });

  return (
    <header className="header" id="siteHeader">
      <div className={wrapperCN}>
        <div className="header__leftSide">
          <a href={routes.get('front::index')} className={logoCN}>
            <img src={logo} alt={`${config.SITE_NAME} logo`} className="header__logoImg" width="53" height="24" />

            {isProviderLayout && <span className="header__logoProLabel">Pro</span>}
          </a>
          <OlxBanner />
        </div>
      </div>
    </header>
  );
};
export default EmptyHeader;
