import { MutationHookOptions, useMutation, gql } from '@apollo/client';
import { t } from '@lingui/macro';
import * as yup from 'yup';

import { isProduction } from 'common/helpers/helpers';

import { client } from '../apolloConnector/apolloConnector';

type TPhoneData = {
  phone: string;
};

const updatePhoneMutation = gql`
  mutation UpdatePhone($phone: String) {
    updatePhone(phone: $phone)
  }
`;

const PHONE_RE = /(\+)?(48)?\d{9}/;
const PHONE_DEV_RE = /(\+)?(48|380)?\d{9}/;

export const phoneFieldValidationSchema = yup
  .string()
  .required(t`Uzupełnij numer telefonu`)
  .test({
    test: (value = '') => {
      const regex = isProduction ? PHONE_RE : PHONE_DEV_RE;
      const pureValue = value.replace(/\s/g, '');

      return regex.test(pureValue);
    },
    message: t`Nieprawidłowy numer telefonu`,
  });

export const phoneValidationSchema = yup.object().shape({
  phone: phoneFieldValidationSchema,
});

export const useUpdatePhoneMutaion = (options?: MutationHookOptions<boolean, TPhoneData>) =>
  useMutation<boolean, TPhoneData>(updatePhoneMutation, options);

type VerifyPhoneNumberResponse = {
  verifyPhoneNumber: boolean;
};

type VerifyPhoneNumberVars = {
  code: string;
};

const verifyPhoneNumberMutation = gql`
  mutation VerifyPhoneNumber($code: String!) {
    verifyPhoneNumber(verificationCode: $code)
  }
`;

export const useVerifyPhoneNumber = () =>
  useMutation<VerifyPhoneNumberResponse, VerifyPhoneNumberVars>(verifyPhoneNumberMutation);

// This hook alternative is made for chat
// where components are not refactored to FC
export const verifyPhoneNumberFn = (code: string) =>
  client.mutate<VerifyPhoneNumberResponse, VerifyPhoneNumberVars>({
    mutation: verifyPhoneNumberMutation,
    variables: {
      code,
    },
    refetchQueries: ['ProviderConversationMessages'],
  });
