import { FC, memo, useEffect } from 'react';

import analytics from 'utils/analytics/analytics';

type Props = {
  pageLabel: string;
  [key: string]: string | number | any[];
};

const PageView: FC<Props> = (props) => {
  useEffect(() => {
    const { pageLabel, ...params } = props;
    analytics.page.view(pageLabel, params);
  }, [props]);

  return null;
};

export default memo(PageView);
