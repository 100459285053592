import { isRoute } from 'common/helpers/helpers';

const routeToLabelMap = [
  { route: 'front::dashboard', label: 'mojefixly' },
  { route: 'provider::dashboard.sentRequests', label: 'quoted_requests' },
  { route: 'provider::job', label: 'request_overview' },
  { route: 'provider::settings.index', label: 'settings' },
  { route: 'provider::settings.getPublicProfile', label: 'settings_profile' },
  { route: 'provider::settings.getServices', label: 'settings_services' },
  {
    route: 'provider::settings.getContactInfo',
    label: 'settings_notifications',
  },
  { route: 'provider::settings.getReferral', label: 'settings_reviews' },
  { route: 'provider::settings.getOrders', label: 'settings_transactions' },
  { route: 'provider::settings.getPremium', label: 'settings_paid_options' },
  { route: 'provider::settings.getPassword', label: 'settings_security' },
];

export const getPageLabelByUrl = (url: string): string => {
  const matchedRoute = routeToLabelMap.find((i) => isRoute(url, i.route));
  return matchedRoute ? matchedRoute.label : '';
};
