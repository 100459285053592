import React, { FC, ReactEventHandler, useState } from 'react';

import { withNoSSR } from 'Components/NoSSR';
import { getApiUrl } from 'config/config';
import auth from 'utils/auth/auth';
import localStorage from 'utils/localStorage/localStorage';

import './EndpointSwitcher.scss';

type EndpointOption = {
  label: string;
  value: string;
  color: string;
};

const endpoints: EndpointOption[] = [
  { label: 'OLX staging', value: 'staging_olx', color: '#23E5DB' },
  { label: 'Stable staging', value: 'staging_new', color: '#6bbf6b' },
  { label: 'Dev staging', value: 'staging_old', color: '#fbdb5b' },
  { label: 'Localhost', value: 'localhost', color: '#f37373' },
];

const EndpointSwitcher: FC = () => {
  const key = localStorage.getItem('fixlyEndpointKey') || 'staging_olx';
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const getCurrentEndpoint = (): EndpointOption | undefined => {
    return endpoints.find((e) => e.value === key);
  };

  const endpointUrlsChanged: ReactEventHandler<HTMLButtonElement> = (e) => {
    const { value } = e.currentTarget.dataset;
    if (value === key || !value) {
      setIsOpen(false);
      return;
    }

    auth.clearCredentials();
    window.localStorage.setItem('fixlyEndpointKey', value);
    window.location.href = '/';
  };

  const currentEndpoint = getCurrentEndpoint();

  if (!currentEndpoint) {
    console.error(`Cannot find endpoint for the key "${key}"`);
    return null;
  }

  return (
    <div className="endpointSwitcher">
      <div className="endpointSwitcher__relativeWrapper">
        {isOpen &&
          endpoints.map((endpoint) => (
            <button
              key={endpoint.value}
              data-value={endpoint.value}
              style={{ backgroundColor: endpoint.color }}
              onClick={endpointUrlsChanged}
              className="endpointSwitcher__switch"
            >
              {endpoint.label}
              <span className="endpointSwitcher__floatingLabel">{getApiUrl(endpoint.value)}</span>
            </button>
          ))}

        {!isOpen && (
          <button
            type="button"
            onClick={() => setIsOpen(true)}
            style={{ backgroundColor: currentEndpoint.color }}
            className="endpointSwitcher__icon"
          >
            <span className="endpointSwitcher__floatingLabel">{getApiUrl(currentEndpoint.value)}</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default withNoSSR(EndpointSwitcher);
