import { compile } from 'path-to-regexp';

import config from '../../config/config';

type RouteParams = {
  [param: string]: any;
};

type RoutesSource = {
  [key: string]: string;
};

type Locality = Record<string, string>;

const localities: Record<string, Locality> = {
  // add more localities in future
  pl: require('./localities/routes.pl.json'),
};

export class Translator {
  routes: RoutesSource;

  constructor(locality: string = config.LOCALE) {
    this.routes = localities[locality];
  }

  get(key: string, params?: RouteParams): string {
    if (!key) {
      return '';
    }

    const route = this.routes[key];

    if (Boolean(params) && route !== undefined) {
      return compile(route)(params);
    }

    return route || key;
  }
}

// NOTE: remove on production
if (process.env.NODE_ENV === 'development' && typeof window !== 'undefined') {
  // @ts-ignore
  window.routes = new Translator();
}

const routes = new Translator();
export default routes;
