import { gql } from '@apollo/client';

export const couponsQuery = gql`
  query providerCoupons {
    providerCoupons {
      ... on CodeCoupon {
        id
        title
        description
        logoUrl
        coverUrl
        buttonHint
        buttonLabel
        code
      }
      ... on LinkCoupon {
        id
        title
        description
        logoUrl
        coverUrl
        buttonHint
        buttonLabel
        linkUrl
      }
      type: __typename
    }
  }
`;

export const activateCouponMutation = gql`
  mutation ActivateCouponMutation($couponId: ID!) {
    activateCoupon(couponId: $couponId) {
      ... on CodeCoupon {
        id
        title
        description
        logoUrl
        coverUrl
        buttonHint
        buttonLabel
        code
      }
      ... on LinkCoupon {
        id
        title
        description
        logoUrl
        coverUrl
        buttonHint
        buttonLabel
        linkUrl
      }
      type: __typename
    }
  }
`;

interface Coupon {
  buttonHint?: string;
  buttonLabel?: string;
  coverUrl?: string;
  description: string; // it can contain html code
  id: string;
  logoUrl?: string;
  title: string;
}

interface CodeCoupon extends Coupon {
  code?: string;
}

interface LinkCoupon extends Coupon {
  linkUrl: string;
}

export type ProviderCoupon = CodeCoupon | LinkCoupon;
