import { cookieStorage } from './cookieStorage';

/**
 * Sets a cookie.
 * @param name The name of the cookie.
 * @param value The value of the cookie.
 * @param days Number of days until the cookie expires.
 */
function setCookie(name: string, value: string, days?: number): void {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = date.toUTCString();
  }
  cookieStorage.set(name, value, { expires });
}

/**
 * Gets a cookie by name.
 * @param name The name of the cookie to get.
 * @returns The cookie value or null if not found.
 */
function getCookie(name: string): string | null {
  const cookies = cookieStorage.get(name);
  return cookies ? cookies : null;
}

/**
 * Removes a cookie by name.
 * @param name The name of the cookie to remove.
 */
function removeCookie(name: string): void {
  cookieStorage.remove(name);
}

export { setCookie, getCookie, removeCookie };
