import config from 'config/config';

import auth from '../auth/auth';
import routes from '../routeTranslator';
import { getFetchHeaders } from './headers';

export const sendResetPassMail = (email: string) => {
  const url = `${config.API_URL}${routes.get('api::reset.password')}`;

  return fetch(url, {
    method: 'POST',
    body: JSON.stringify({ email: email }),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((response) => {
    if (!response.ok && response.status >= 400) {
      return Promise.reject(response);
    } else {
      return response.text();
    }
  });
};

export const logout = async (): Promise<void> => {
  await fetch(`${config.API_URL}${routes.get('api::logout')}`, {
    credentials: 'include',
    headers: await getFetchHeaders(),
  }).catch((err) => {
    console.log(err);
  });

  auth.clearCredentials();
};
