import classnames from 'classnames';
import React, { FC, ReactNode } from 'react';
import ReactModal from 'react-modal';

import isMobile from 'common/helpers/platformDetector';
import { CloseButton } from 'uikit/Button';
import { TextDisplay } from 'uikit/Text';

import './Modal.scss';

type Props = {
  isOpen: boolean;

  className?: string;
  id?: string;
  modalClassModifier?: string;
  overlayClassName?: string;
  bodyClassName?: string;
  titleClassName?: string;
  title?: ReactNode;
  closeEvent?: string;
  testId?: string;
  isCloseButton?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  handleClose?: (closer?: string) => void;
  handleMobileClose?: (closer?: string) => void;
  disableBodyScroll?: boolean;
};

ReactModal.setAppElement('body');

const Modal: FC<Props> = ({
  title,
  isCloseButton = true,
  isOpen,
  className = '',
  modalClassModifier = '',
  overlayClassName,
  bodyClassName,
  titleClassName,
  children,
  closeEvent,
  testId,
  id,
  handleClose,
  handleMobileClose,
  shouldCloseOnOverlayClick,
  disableBodyScroll,
}) => {
  const handleCloseRequest = (closer = ''): void => {
    if (isMobile.isAnyMobile() && typeof handleMobileClose === 'function') {
      handleMobileClose(closer);
    } else if (handleClose) {
      handleClose(closer);
    }
  };

  const canBeClosed = Boolean(handleClose);

  const handleModalClose = canBeClosed
    ? (e: React.MouseEvent) => {
        e.stopPropagation();
        handleCloseRequest(closeEvent || 'background');
      }
    : undefined;

  const modalClassName = classnames('Nmodal', modalClassModifier, className);
  const overlayClassNames = classnames('Nmodal__overlay', overlayClassName);
  const bodyClassNames = classnames('Nmodal__body', bodyClassName);
  const titleClassNames = classnames('Nmodal__title', titleClassName);

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleModalClose}
      role="dialog"
      ariaHideApp={isOpen}
      closeTimeoutMS={350}
      className={modalClassName}
      overlayClassName={overlayClassNames}
      bodyOpenClassName={disableBodyScroll ? 'noscroll' : undefined}
      testId={testId}
      id={id}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      {isCloseButton && canBeClosed && (
        <CloseButton className="Nmodal__close" onClick={handleModalClose} tabIndex={-1} />
      )}
      <div className={bodyClassNames}>
        {title && (
          <TextDisplay bold className={titleClassNames}>
            {title}
          </TextDisplay>
        )}
        {children}
      </div>
    </ReactModal>
  );
};

export default Modal;
