import React from 'react';

export const OlxIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="13" fill="none" viewBox="0 0 23 13" {...props}>
    <path
      fill="currentColor"
      d="M14.911 13h-3.084V-.001h3.084v13zm-4.197-6.5A5.357 5.357 0 110 6.5a5.357 5.357 0 0110.714 0zm-3.734 0a1.624 1.624 0 10-3.247 0 1.624 1.624 0 003.247 0zm15.733-3.256h-2.05L19.46 4.445 18.26 3.244h-2.051v2.083l1.185 1.185-1.185 1.186v2.055h2.077l1.174-1.174 1.175 1.174h2.08V7.7l-1.186-1.186 1.185-1.187V3.244z"
    />
  </svg>
);

/* eslint max-len:0 */
