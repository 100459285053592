import { gql } from '@apollo/client';

export enum ExperimentVariantEnum {
  A = 'A',
  B = 'B',
  C = 'C',
}

export type Experiment = {
  id: string;
  variant: ExperimentVariantEnum;
};

export type ExperimentsResponse = {
  experiments: Experiment[];
};

export const experimentsQuery = gql`
  query Experiments {
    experiments {
      id
      variant
    }
  }
`;
