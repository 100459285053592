import React from 'react';

import { useLocalSettings } from 'Components/LocalSettingsProvider/LocalSettingsProvider';
import { SoundOnIcon } from 'common/icons/SoundOnIcon';
import { SoundOffIcon } from 'common/icons/SoundOffIcon';
import analytics from 'utils/analytics/analytics';

import './HeaderSoundTrigger.scss';

const HeaderSoundTrigger = () => {
  const [settings, setSettings] = useLocalSettings();
  const { disableSounds } = settings;
  const switchSounds = () => {
    const newValue = !disableSounds;
    analytics.trackEvent(newValue ? 'beep_sound_enabled' : 'beep_sound_disabled');
    setSettings({ disableSounds: newValue });
  };
  return (
    <div className="headerSoundTrigger">
      <button onClick={switchSounds}> {disableSounds ? <SoundOffIcon /> : <SoundOnIcon />} </button>
    </div>
  );
};

export default HeaderSoundTrigger;
