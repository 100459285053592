import { Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FC } from 'react';
import { useHistory } from 'react-router';

import { useCurrentUser } from 'Components/CurrentUser/CurrentUser';
import LangSwitcher from 'Components/LangSwitcher/LangSwitcher';
import ServiceSearchHeader from 'Components/ServiceSearch/ServiceSearchHeader.async';
import logo from 'common/icons/fixly_logo.svg';
import config from 'config/config';
import routes from 'utils/routeTranslator';

import Navigation from '../Navigation/Navigation';
import { isRoute } from '../helpers/helpers';
import EmptyHeader from './EmptyHeader';
import { Header$Props, isDesktopFixedRoute, isProviderRegisterRoute } from './Header.helpers';
import './Header.scss';
import OlxBanner from './OlxBanner';

const Header: FC<Header$Props> = (props) => {
  const history = useHistory();
  const currentUrl = history.location.pathname;
  const isSearchHidden = isRoute(currentUrl, 'categories_seo') || isRoute(currentUrl, 'front::index');

  const showEmptyHeader = isRoute(currentUrl, 'front::auth.user.login');

  const context = useCurrentUser();

  const isProviderHeaderRoute = isProviderRegisterRoute(currentUrl);

  if (showEmptyHeader) {
    return <EmptyHeader isProviderLayout={Boolean(props.isProviderLayout) || isProviderHeaderRoute} />;
  }

  const isProvider = context.currentUser?.isProvider;
  const isImpersonating = context.currentUser?.isImpersonating;

  const isProviderLayout: boolean = isProvider || isProviderHeaderRoute;

  const wrapperCN = classnames({
    'header__wrapper': true,
    'header__wrapper_provider': isProviderLayout,
    'header__wrapper_desktopFixed': isDesktopFixedRoute(currentUrl),
    'header__wrapper_mobileNotFixed': isRoute(currentUrl, 'front::category'),
  });

  const logoCN = classnames({
    'header__logo': true,
    'header__logo_provider': isProviderLayout,
  });

  return (
    <header className="header" id="siteHeader">
      <div className={wrapperCN}>
        <div className="header__leftSide">
          <a href={isProvider ? routes.get('front::dashboard') : routes.get('front::index')} className={logoCN}>
            <img src={logo} alt={`${config.SITE_NAME} logo`} className="header__logoImg" width="53" height="24" />

            {isProviderLayout && <span className="header__logoProLabel">Pro</span>}
          </a>
          <LangSwitcher />
          <OlxBanner />
        </div>

        {!isProviderLayout && (
          <div className="header__search">
            <ServiceSearchHeader key={`header-search-${currentUrl}`} isHidden={isSearchHidden} />
          </div>
        )}

        <div className="header__rightSide">
          <Navigation isProvider={isProviderLayout} isLoggedIn={!!context.currentUser?.userId} />
        </div>
      </div>

      {isImpersonating && (
        <a href={`${config.ADMIN_API_URL}/impersonate/stop`} className="header__impersonate">
          <span className="header__impersonateText">
            <Trans>Powrót</Trans>
          </span>
        </a>
      )}
    </header>
  );
};

export default Header;
