import axios, { AxiosPromise, AxiosRequestConfig, AxiosResponse, Method } from 'axios';

import config from 'config/config';

import auth from '../auth/auth';
import routes from '../routeTranslator';
import { logout } from './authentication';
import {
  placeOrder as pwfPlaceOrder,
  checkOrderStatus as pwfCheckOrderStatus,
  paymentMethods as pwfPaymentMethods,
  cardTokens as pwfCardTokens,
  deleteCardToken as pwfDeleteCardToken,
} from './customer-payments';
import { apiHeaders, getFetchHeaders } from './headers';
import { getPBLOptions, checkOrderStatus, getCardTokens, deleteCardToken } from './provider-payments';

export type AuthResult = {
  userId: string;
  refreshToken: string;
  accessToken: string;
  isProvider: boolean;
  isImpersonating?: boolean;
  expiresAt?: number;
  emailVerified?: boolean;
  phoneVerified?: boolean;
};

export type LoginInput = { email?: string; phone?: string; password?: string; code?: string };

export { getFetchHeaders };

const sendRequest = async <T>(
  url: string,
  method: Method,
  data?: any,
  anonymous = false,
  requestConfig?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => {
  const { headers: configHeaders, ...config } = requestConfig || {};
  return axios({
    url,
    method,
    data,
    withCredentials: true,
    headers: { ...(await getFetchHeaders()), ...configHeaders },
    ...config,
  });
};

const call = async <T>(
  url: string,
  method: Method,
  data?: any,
  anonymous = false,
  requestConfig?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => {
  let response: AxiosResponse<T> = await sendRequest(url, method, data, anonymous, requestConfig);

  // retry after token refresh
  if (!anonymous && response.status === 401) {
    await auth.refresh();
    response = await sendRequest(url, method, data, anonymous);
  }

  return response;
};

const post = <T>(route: string, data: any, anonymous = false, requestConfig?: AxiosRequestConfig) => {
  return call<T>(`${config.API_URL}${route}`, 'POST', data, anonymous, requestConfig);
};

const api = {
  sendChangePassword(data: { code: string; password: string }) {
    return post(routes.get('api::change.password'), data);
  },

  async sendLoginCode(data: { phone?: string; email?: string; forgotPassword?: boolean }, headers: any = {}) {
    return post<boolean>(routes.get('api::login.code'), data, false, headers);
  },

  async sendLoginLink(data: { email: string }, headers: any = {}) {
    return post<boolean>(routes.get('api::login.link'), data, false, { headers });
  },

  getJobDoneData(
    hash: string
  ): AxiosPromise<{ notification: { channel: 'sms' | 'email'; value: string } } | { redirectUrl: string }> {
    return call(`${config.API_URL}/quote-state/job-done/${hash}`, 'GET');
  },

  async login(data: LoginInput, headers: any = {}, storeCredentials = false) {
    const res = await post<AuthResult>(routes.get('api::login'), data, true, { headers });

    if (storeCredentials) auth.storeCredentials(res.data);

    return res;
  },

  logout,

  uploadImage<T>(data: any, requestConfig?: AxiosRequestConfig) {
    return post<T>(routes.get('api::uploadImage'), data, false, requestConfig);
  },

  getActivityFeed: async (fromDate: string, limit = 20, initial = false, l2Slug?: string) => {
    const path = routes.get('api::activityFeed', {
      fromDate,
      limit,
      initial: String(initial),
      l2Slug,
    });

    return fetch(`${config.API_URL}${path}`, {
      headers: await getFetchHeaders(),
    }).catch((error) => console.log(error));
  },

  getTosBadgeStatus() {
    return fetch(`${config.API_URL}${routes.get('api::tosBadgeStatus')}`, {
      headers: {
        ...apiHeaders,
      },
    }).catch((error) => console.log(error));
  },

  async loginWithLoginToken(loginToken: string, storeCredentials = false) {
    const res = await call<AuthResult>(
      `${config.API_URL}${routes.get('api::login.token', { loginToken })}`,
      'GET',
      undefined,
      true
    );
    if (storeCredentials) auth.storeCredentials(res.data);

    return res;
  },

  payments: {
    getPBLOptions,
    checkOrderStatus,
    getCardTokens,
    deleteCardToken,
  },
  pwf: {
    placeOrder: pwfPlaceOrder,
    checkOrderStatus: pwfCheckOrderStatus,
    paymentMethods: pwfPaymentMethods,
    cardTokens: pwfCardTokens,
    deleteCardToken: pwfDeleteCardToken,
  },
};

export { providerPlaceOrder, getPBLOptions, getCardTokens, checkOrderStatus } from './provider-payments';

export { sendResetPassMail } from './authentication';

export { getResponseError } from './utils';

export * from './types';

export default api;
