import { gql } from '@apollo/client';
import cloneDeep from 'lodash.clonedeep';

import { SPOnboardingSteps } from 'pages/SPOnboarding/SPOnboarding.helpers';
import { client } from 'utils/apolloConnector/apolloConnector';

export type ProviderPromoPackage = {
  promoPackageRewards: {
    unit: 'points' | 'quotes';
    value: number;
  };
};

export const PROMO_PACKAGE_REWARDS = gql`
  query PromoPackageRewads {
    promoPackageRewards {
      unit
      value
    }
  }
`;

export const providerCounterQuery = gql`
  query DashboardCounterQuery {
    providerDashboardCount {
      inbox
      totalInbox
      sent
      totalSent
      totalArchive
    }
  }
`;

export const currentUserDataQuery = gql`
  query CurrentUserData {
    me {
      id
      slug
      firstName
      lastName
      avatarUrl
      provider {
        id
        onboardingStep
        pwfVerification {
          status
        }
      }
    }
  }
`;

type NotificationsNamesType = {
  [key: string]: string;
};

export type UserData = {
  me: {
    id: string;
    slug: string;
    firstName: string;
    lastName: string;
    avatarUrl: string;
    provider: {
      id: string;
      onboardingStep: SPOnboardingSteps;
      pwfVerification: {
        status: string;
      };
    };
  };
};

export type ProviderDashboardCount = {
  providerDashboardCount: {
    inbox: number;
    totalInbox: number;
    sent: number;
    totalSent: number;
    totalArchive: number;
  };
};

export const handledNotificationsTypes: NotificationsNamesType = {
  providerReviewRequest: 'provider-review-request',
  newMessage: 'service_request_conversation-new_message',
  newRequest: 'service_request-new',
  proGotReview: 'service_request-finished',
  providersSeen: 'providers-seen',
};

export enum HandledNotification {
  providerReviewRequest = 'provider-review-request',
  newMessage = 'service_request_conversation-new_message',
  newRequest = 'service_request-new',
  proGotReview = 'service_request-finished',
  providersSeen = 'providers-seen',
}

export const updateNotificationsCounter = (tab: string, count: 1 | -1) => {
  const query = providerCounterQuery;
  let data = {};

  try {
    /* @ts-expect-error type mismatch */
    data = cloneDeep(client.readQuery({ query }));
    /* @ts-expect-error type mismatch */
    data.providerDashboardCount = {
      /* @ts-expect-error type mismatch */
      ...data.providerDashboardCount,
      /* @ts-expect-error type mismatch */
      [tab]: data.providerDashboardCount[tab] + count,
    };
  } catch (e) {
    // TODO: Handle this error somehow
    return;
  }

  client.writeQuery({
    query,
    data,
  });
};

export const changeFooterVisibility = (hasToBeVisible: boolean) => {
  if (typeof window === 'undefined') {
    return;
  }
  /* @ts-expect-error type mismatch */
  const domCollection: HTMLElement[] = [].map.call(document.getElementsByClassName('footer'), (e) => e);
  const footerElement = domCollection[0];
  if (!footerElement) {
    return;
  }

  if (hasToBeVisible) {
    footerElement.classList.remove('footer_hidden');
  } else {
    footerElement.classList.add('footer_hidden');
  }
};

export const scrollToBottom = () => {
  if (typeof window === 'undefined' || !document.body) {
    return;
  }
  window.scrollTo(0, document.body.clientHeight);
};

export const changeDashboardNavigationVisibility = (hasToBeVisible = false, hidingClassName?: string): void => {
  if (typeof window === 'undefined') {
    return;
  }
  const dashboardNavigationNodeList = document.getElementsByClassName('dashboardNavigation__container');
  if (!dashboardNavigationNodeList || !dashboardNavigationNodeList[0]) {
    return;
  }

  const dashboardNavigation = dashboardNavigationNodeList[0];
  const classNameToUse =
    typeof hidingClassName === 'string' ? hidingClassName : 'dashboardNavigation__container_hidden';

  if (hasToBeVisible) {
    dashboardNavigation.classList.remove(classNameToUse);
  } else {
    dashboardNavigation.classList.add(classNameToUse);
  }
};
