import { gql } from '@apollo/client';
import { t } from '@lingui/macro';
import * as Yup from 'yup';

import polishAlphabet from 'common/helpers/polishAlphabetUnicode';
import config from 'config/config';
import { phoneFieldValidationSchema } from 'utils/graphql/spSettings';

export type ComplexLoginFormValues = {
  email?: string;
  phone?: string;
  name?: string;
};

export type UserCredentials = {
  subscribeToMarketing?: boolean;
  email?: string;
  phone?: string;
  password?: string;
  code?: string;
  cityId?: string;
};

export enum LoginType {
  SMS = 'type_sms',
  Email = 'type_email',
}

export type RegisterCustomerVars = {
  input: {
    cityId?: string;
    email?: string;
    isExternal?: boolean;
    name: string;
    phone?: string;
    registrationSource?: string;
    subscribeToMarketing: boolean;
    viaWizard?: boolean;
  };
};

export type RegisterCustomerResponse = {
  registerClient: {
    userId: string;
    refreshToken: string;
    accessToken: string;
    expiresAt?: number;
  };
};

export const registerCustomerMutation = gql`
  mutation RegisterCustomer($input: RegisterClientInput!) {
    registerClient(input: $input) {
      userId
      refreshToken
      accessToken
      expiresAt
    }
  }
`;

const EMAIL_REGEXP = new RegExp('^(?!.*/).*$'); // Doesn't allow forward slash https://goo.gl/sz3Rpy

const NAME_REGEXP = new RegExp(`^\\s*?(?:${polishAlphabet}+\\s*)+\\s*$`);

export const getUserInfoValidationSchema = (loginType?: 'email' | 'phone', withName?: boolean) => {
  return Yup.object().shape({
    email:
      loginType === 'email'
        ? Yup.string()
            .required(t`Podaj adres e-mail`)
            .matches(EMAIL_REGEXP, t`Nieprawidłowy adres e-mail`)
            .email(t`Nieprawidłowy adres e-mail`)
        : Yup.string(),
    phone: loginType === 'phone' ? phoneFieldValidationSchema : Yup.string(),
    name: withName
      ? Yup.string()
          .required(t`Podaj swoje imię`)
          .min(
            config.USERNAME_MIN_LENGTH,
            t`Pole “imię i nazwisko” musi zawierać 2 słowa (min. ${config.USERNAME_MIN_LENGTH} znaków)`
          )
          .matches(NAME_REGEXP, t`Podaj swoje imię`)
      : Yup.string(),
  });
};
