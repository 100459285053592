import { MutationHookOptions, useMutation, gql } from '@apollo/client';

const notifyAskForReviewMutation = gql`
  mutation notifyAskForReviewMutation($serviceRequestId: ID, $hash: String) {
    notifyAskForReviewMutation(serviceRequestId: $serviceRequestId, hash: $hash)
  }
`;

export const useNotifyAskForReviewMutation = (
  options?: MutationHookOptions<boolean, { serviceRequestId?: string; hash?: string }>
) => useMutation(notifyAskForReviewMutation, options);
