import { t, Trans } from '@lingui/macro';
import classnames from 'classnames';
import { useFormik } from 'formik';
import React, { FC, KeyboardEventHandler, useEffect } from 'react';
import * as Yup from 'yup';

import FormRow from 'uikit/FormRow/FormRow';
import Input from 'uikit/Input/Input';
import analytics from 'utils/analytics/analytics';

type CodeStepFormProps = {
  mod?: string;
  loading?: boolean;
  isResending?: boolean;
  resendDisabled?: boolean;
  error?: string;
  onSubmit: (value: string) => void;
  onResend: () => void;
};

const CodeStepForm: FC<CodeStepFormProps> = (props) => {
  const preventSpaces: KeyboardEventHandler<HTMLInputElement> = (event): void => {
    if ([' ', 'Spacebar'].includes(event.key)) event.preventDefault();
  };

  const submitButtonCN = classnames('login__submitCode', {
    'login__submitCode_loading': props.loading,
  });

  const resendButtonCN = classnames('login__resendButton', {
    'login__resendButton_loading': props.isResending,
  });

  const { values, errors, touched, setFieldError, handleChange, handleSubmit } = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema: Yup.object().shape({
      code: Yup.string().required(t`Uzupełnij pole "hasło"`),
    }),
    onSubmit: () => {
      props.onSubmit(values.code);
    },
  });

  useEffect(() => {
    setFieldError('code', props.error);
  }, [props.error, setFieldError]);

  const id = `login__code_${props.mod || ''}`;

  return (
    <form onSubmit={handleSubmit} className="login__form login__form_code">
      <div className="login__codeInputWrap">
        <FormRow>
          <Input
            id={id}
            name="code"
            value={values.code}
            error={touched.code && errors.code}
            onChange={handleChange}
            onFocus={() => analytics.trackEvent('code_login_popup_click')}
            onInput={() => analytics.trackEvent('code_login_popup_type')}
            inputMode="numeric"
            className="login__codeInput"
            placeholder={t`Wpisz kod z SMSa`}
            onKeyDown={preventSpaces}
          />
        </FormRow>
        <button id={`submitLogin${props.mod || ''}`} type="submit" name="submitLogin" className={submitButtonCN}>
          <Trans>Zaloguj się</Trans>
        </button>
      </div>
      <button type="button" onClick={props.onResend} className={resendButtonCN} disabled={props.resendDisabled}>
        <Trans>Wyślij ponownie</Trans>
      </button>
    </form>
  );
};
export default CodeStepForm;
