import localStorage from '../../utils/localStorage/localStorage';
import { isWindow, stripErrorPrefixes } from '../../common/helpers/helpers';

export type FlashMessage$Payload = {
  message: string;
  status: 'success' | 'error' | 'info';
};

export type FlashMessage$State = FlashMessage$Payload & {
  isShown: boolean;
};

export const FLASH_MESSAGE_KEY = 'fixly_flash_message';

export const FLASH_MESSAGE_TRIGGER = 'fixly_flash_message_trigger';

export const verifyPayload = (payload: any): FlashMessage$Payload => ({
  message: String(payload ? stripErrorPrefixes(payload.message) : ''),
  status: payload && payload.status ? payload.status : 'error',
});

export const getMessageToShow = () => {
  const message = localStorage.getItem(FLASH_MESSAGE_KEY);

  if (message) {
    return JSON.parse(message);
  }

  return null;
};

export const clearMessage = () => {
  localStorage.removeItem(FLASH_MESSAGE_KEY);
};

export const showFlashMessageAfterReload = (message: string, status = 'success') => {
  const jsonMessage = JSON.stringify({ message, status });
  localStorage.setItem(FLASH_MESSAGE_KEY, jsonMessage);
};

export const showFlashMessage = (payload: FlashMessage$Payload) => {
  if (!isWindow) {
    return;
  }

  window.postMessage(
    {
      type: FLASH_MESSAGE_TRIGGER,
      payload,
    },
    window.location.origin
  );
};
