import React from 'react';
import classnames from 'classnames';

import './IconSelect.scss';

type IconSelect$Props = {
  value: string;
  label: string;
  isActive: boolean;
  subLabel?: string;
  isBig?: boolean;
  onChange: (e: React.SyntheticEvent<HTMLInputElement>) => void;
};

const IconSelect: React.FC<IconSelect$Props> = ({ isActive, value, onChange, children, label, isBig, subLabel }) => {
  const selectCN = classnames({
    'iconSelect': true,
    'iconSelect_big': isBig,
  });

  const selectLabelCN = classnames({
    'iconSelect__label': true,
    'iconSelect__label_active': isActive,
  });

  const selectId = `iconSelect__${value}`;

  return (
    <div className={selectCN}>
      <label htmlFor={selectId} className={selectLabelCN}>
        <figure className="iconSelect__icon">{children}</figure>
        <span className="iconSelect__title">{label}</span>
        {subLabel && <span className="iconSelect__subLabel">{subLabel}</span>}
        <input type="radio" name="invoiceDataType" id={selectId} value={value} checked={isActive} onChange={onChange} />
      </label>
    </div>
  );
};
export default IconSelect;
