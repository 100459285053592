import { Trans } from '@lingui/macro';
import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { withNoSSR } from 'Components/NoSSR';
import { useCurrentUserQuery } from 'utils/graphql/auth';

import './ChangePassword.scss';
import ChangePasswordForm from './ChangePasswordForm';

const ChangePassword: FC = () => {
  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const token = params.get('token');

  useCurrentUserQuery();

  if (!token) return null;

  return (
    <div className="changePassword__container">
      <div className="changePassword">
        <h1 className="changePassword__title">
          <Trans>Utwórz nowe hasło</Trans>
        </h1>
        <ChangePasswordForm token={token} />
      </div>
    </div>
  );
};

export default withNoSSR(ChangePassword);
