import { t } from '@lingui/macro';
import React, { FC } from 'react';

import Modal from 'common/Modal/Modal';

import './ResetPassword.scss';
import ResetPasswordForm from './ResetPasswordForm';

type Props = {
  isOpen: boolean;
  email: string;
  onClose: () => void;
};

const ResetPasswordModal: FC<Props> = (props) => {
  const { email, isOpen, onClose } = props;

  return (
    <Modal isOpen={isOpen} handleClose={onClose} title={t`Nie pamiętasz hasła?`} className="resetPasswordModal">
      <div className="resetPassword">
        <ResetPasswordForm email={email} />
      </div>
    </Modal>
  );
};

export default ResetPasswordModal;
