import { Trans } from '@lingui/macro';
import c from 'classnames';
import reactHTMLParser from 'html-react-parser';
import React, { FC, useEffect, useState } from 'react';

import { Button } from 'uikit/Button';
import { TextP1, TextP3 } from 'uikit/Text';
import analytics from 'utils/analytics/analytics';

import { ProviderCoupon } from '../Coupons.helpers';
import './CouponsItem.scss';

type Props = {
  couponData: ProviderCoupon;
  className?: string;
  onActivate: (id: string, link?: string) => void;
};

const CouponsItem: FC<Props> = ({ couponData, className, onActivate }) => {
  const { id, title, description, buttonHint, buttonLabel, logoUrl, coverUrl } = couponData;

  useEffect(() => {
    analytics.trackEvent('sp_coupon_view', { coupon_id: id });
  }, [id]);

  const linkUrl = 'linkUrl' in couponData && couponData.linkUrl;
  const code = 'code' in couponData && couponData.code;

  const [isCopiedShow, setIsCopiedShow] = useState(false);

  const inputClickHandler = (e: React.MouseEvent<HTMLInputElement>) => {
    e.preventDefault();

    try {
      window.navigator.clipboard.writeText(e.currentTarget.value).then(() => {
        setIsCopiedShow(true);
        analytics.trackEvent('sp_coupon_code_copy', { coupon_id: id });
        setTimeout(() => setIsCopiedShow(false), 2000);
      });
    } catch (error) {
      console.warn('Copying is not supported');
    }
  };

  return (
    <div
      className={c(
        'CouponsItem',
        {
          'CouponsItem_linkType': Boolean(linkUrl),
        },
        className
      )}
    >
      <div className="CouponsItem__info">
        {Boolean(coverUrl) && <img src={coverUrl} className="CouponsItem__cover" alt="" />}
        <div className={c('CouponsItem__titleRow', { 'CouponsItem__titleRow_cover': Boolean(coverUrl) })}>
          {Boolean(logoUrl && !coverUrl) && <img src={logoUrl} className="CouponsItem__logo" alt="" />}
          <TextP1 bold className="CouponsItem__title">
            {title}
          </TextP1>
        </div>
        <div className="CouponsItem__descriptionRow">
          <TextP3 as="div">{reactHTMLParser(description)}</TextP3>
        </div>
      </div>
      <div className="CouponsItem__actions">
        {Boolean(buttonHint) && (
          <TextP3 bold className="CouponsItem__buttonHint">
            {' '}
            {buttonHint}{' '}
          </TextP3>
        )}
        {linkUrl && (
          <Button onClick={() => onActivate(id, linkUrl)} width="full" kind="primary">
            {buttonLabel || <Trans>Skorzystaj z promocji</Trans>}
          </Button>
        )}
        {!linkUrl && !code && (
          <Button onClick={() => onActivate(id)} width="full">
            {buttonLabel || <Trans>Pokaż kupon</Trans>}
          </Button>
        )}
        {code && (
          <div className="CouponsItem__codeContainerWrapper">
            <input
              onClick={inputClickHandler}
              readOnly
              type="text"
              className="CouponsItem__codeContainer"
              value={code}
            />
            <div
              className={c('CouponsItem__codeContainerCopyHint', {
                'CouponsItem__codeContainerCopyHint_active': isCopiedShow,
              })}
            >
              <Trans>Skopiowane</Trans>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CouponsItem;
