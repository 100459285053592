import { Trans } from '@lingui/macro';
import React, { FC } from 'react';

import './ResetPassword.scss';
import ResetPasswordForm from './ResetPasswordForm';

const ResetPassword: FC = () => (
  <div className="resetPassword__container">
    <div className="resetPassword">
      <h1 className="resetPassword__title">
        <Trans>Nie pamiętasz hasła?</Trans>
      </h1>
      <ResetPasswordForm />
    </div>
  </div>
);

export default ResetPassword;
