import { Trans } from '@lingui/macro';
import React, { FC } from 'react';

import iconLogo from 'common/icons/footer_application.png';
import config from 'config/config';
import { AndroidButton, IosButton } from 'uikit/Button';
import analytics from 'utils/analytics/analytics';
import routes from 'utils/routeTranslator';

import FooterSocials from './FooterSocials';

type Props = {
  isNewTosBadgeVisible: boolean;
};

const ProviderFooter: FC<Props> = ({ isNewTosBadgeVisible }) => (
  <div className="footer footer_provider">
    <div className="container">
      <div className="footer__desktop">
        <div className="footer__row footer__row_proLinks">
          <div className="footer__column footer__column_apps">
            <img src={iconLogo} className="footer__logo" alt="" width="60" height="60" />
            <div>
              <h3 className="footer__title">
                <Trans>Aplikacja mobilna Fixly</Trans>
              </h3>
              <AndroidButton
                as="a"
                className="footer__appButton"
                href="https://sp-app.fixly.pl/android-prom?touch_point_page=footer"
                target="_blank"
                rel="noopener noreferrer nofollow"
                onClick={() => analytics.trackEvent('download_android_button_click', { touch_point_page: 'footer' })}
              >
                <Trans>Pobierz z Google Play</Trans>
              </AndroidButton>
              <IosButton
                as="a"
                className="footer__appButton"
                href="https://sp-app.fixly.pl/ios-prom?touch_point_page=footer"
                target="_blank"
                rel="noopener noreferrer nofollow"
                onClick={() => analytics.trackEvent('download_ios_button_click', { touch_point_page: 'footer' })}
              >
                <Trans>Pobierz z App Store</Trans>
              </IosButton>
            </div>
          </div>

          <div className="footer__column footer__column_links">
            <h3 className="footer__title">
              <Trans>Przydatne linki</Trans>
            </h3>
            <a
              className="footer__item footer__item_link"
              href={routes.get('front::aboutus')}
              rel="noopener noreferrer nofollow"
            >
              <Trans>O nas</Trans>
            </a>
            {isNewTosBadgeVisible ? (
              <a
                className="footer__item footer__item_link footer__new-tos"
                href={config.FIX_HELPDESK_TOS_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Trans>Regulamin</Trans>
                <sup className="footer__new-tos-badge">
                  <Trans>zmiany</Trans>
                </sup>
              </a>
            ) : (
              <a
                className="footer__item footer__item_link"
                href={config.FIX_HELPDESK_TOS_URL}
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                <Trans>Regulamin</Trans>
              </a>
            )}
            <a
              className="footer__item footer__item_link"
              href={config.FIX_HELPDESK_PRIVACY_URL}
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <Trans>Polityka prywatności</Trans>
            </a>
            <a
              className="footer__item footer__item_link"
              href={routes.get('front::reviews')}
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <Trans>Opinie</Trans>
            </a>
          </div>
          <div className="footer__column footer__column_support">
            <h3 className="footer__title">
              <Trans>Pomoc</Trans>
            </h3>
            <a
              className="footer__item footer__item_link event-contact-footer"
              href={config.FIX_HELPDESK_CONTACT_URL}
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <Trans>Kontakt</Trans>
            </a>
            <a
              className="footer__item footer__item_link event-footer_faq"
              href={config.FIX_HELPDESK_URL}
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <Trans>Centrum pomocy</Trans>
            </a>
            <a
              className="footer__item footer__item_link"
              href={routes.get('front::referral')}
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <Trans>Współpraca</Trans>
            </a>
          </div>
        </div>
      </div>
      <FooterSocials isProvider />
    </div>
  </div>
);

export default ProviderFooter;
