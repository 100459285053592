import { QueryHookOptions, useQuery, gql } from '@apollo/client';

type TLoginTokenResponse = {
  loginToken: string;
};

const loginTokenQuery = gql`
  query LoginToken {
    loginToken
  }
`;

type TCurrentUserResponse = {
  me: {
    id: string;
    slug: string;
  };
};

const currentUserQuery = gql`
  query currentUser {
    me {
      id
      slug
    }
  }
`;

export const useLoginTokenQuery = (options?: QueryHookOptions<TLoginTokenResponse>) => {
  return useQuery(loginTokenQuery, options);
};

export const useCurrentUserQuery = (options?: QueryHookOptions<TCurrentUserResponse>) => {
  return useQuery(currentUserQuery, options);
};
