import { t } from '@lingui/macro';
import React, { FC, useEffect, useState } from 'react';

import ResetPasswordModal from 'Components/ResetPassword/ResetPasswordModal';
import Modal from 'common/Modal/Modal';
import analytics from 'utils/analytics/analytics';
import routes from 'utils/routeTranslator';

import { LoginStepEnum, REQUEST_PASSWORD_HASH } from './Login.helpers';
import './Login.scss';
import LoginForm from './LoginForm';

type Props = {
  isOpen: boolean;
  isPasswordStep: boolean;
  onClose: () => void;
};

const LoginModal: FC<Props> = (props) => {
  const [isLoginOpen, setIsLoginOpen] = useState<boolean>(props.isOpen);
  const [isResetPasswordOpen, setIsResetPasswordOpen] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [loginStep, setLoginStep] = useState<LoginStepEnum>(LoginStepEnum.login_step);

  const openSetPasswordStep = (): void => {
    setIsLoginOpen(true);
    setLoginStep(LoginStepEnum.set_password_step);
    analytics.trackEvent('create_password_popup_show');
  };

  const closeInternalLoginModal = (): void => {
    onLoginStepChange(LoginStepEnum.login_step);
    setIsLoginOpen(false);
  };

  const closeLoginModal = (): void => {
    closeInternalLoginModal();
    props.onClose();
  };

  const trackUserCloseEvent = (closer: string | undefined): void => {
    if (closer === 'success') {
      return;
    }
    if (loginStep === LoginStepEnum.code_step) {
      analytics.trackEvent('code_login_popup_close', { touch_point_button: closer });
    }
    if (loginStep === LoginStepEnum.set_password_step) {
      analytics.trackEvent('create_password_popup_close', { touch_point_button: closer });
    }
  };

  const handleMobileClose = (): void => {
    if (loginStep === LoginStepEnum.login_step) {
      closeLoginModal();
    } else {
      onLoginStepChange(LoginStepEnum.login_step);
    }
  };

  const openResetPasswordModal = (): void => {
    setIsResetPasswordOpen(true);
  };

  const closeResetPasswordModal = (): void => {
    setIsResetPasswordOpen(false);
    props.onClose();
  };

  const onResetPasswordOpen = (email?: string): void => {
    closeInternalLoginModal();
    openResetPasswordModal();
    if (email) {
      setEmail(email);
    }
  };

  const onLoginSuccess = (): void => {
    window.location.href = `${routes.get('front::dashboard')}#${REQUEST_PASSWORD_HASH}`;
  };

  const onLoginStepChange = (loginStep: LoginStepEnum) => {
    if (loginStep === LoginStepEnum.code_step) analytics.trackEvent('code_login_popup_show');
    setLoginStep(loginStep);
  };

  useEffect(() => {
    if (props.isPasswordStep) openSetPasswordStep();
  }, [props.isPasswordStep]);

  const isSetPasswordStep = loginStep === LoginStepEnum.set_password_step;

  return (
    <>
      <Modal
        isOpen={isLoginOpen && props.isOpen}
        handleClose={(closer) => {
          trackUserCloseEvent(closer);
          closeLoginModal();
        }}
        handleMobileClose={handleMobileClose}
        title={isSetPasswordStep ? t`Czy chcesz utworzyć hasło do swojego konta?` : t`Zaloguj się na Fixly`}
        className="loginModal"
        id={`loginModal_${isSetPasswordStep ? 'setPassword' : 'login'}`}
      >
        <div className="login">
          <LoginForm
            onSuccess={onLoginSuccess}
            handleClose={(closer: string) => {
              trackUserCloseEvent(closer);
              closeLoginModal();
            }}
            onResetPasswordOpen={onResetPasswordOpen}
            onStepChange={onLoginStepChange}
            step={loginStep}
          />
        </div>
      </Modal>
      <ResetPasswordModal isOpen={isResetPasswordOpen} email={email || ''} onClose={closeResetPasswordModal} />
    </>
  );
};

export default LoginModal;
