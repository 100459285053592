import { Trans, t } from '@lingui/macro';
import classnames from 'classnames';
import React, { Component } from 'react';

import { TextP3 } from 'uikit/Text';

import {
  FlashMessage$State,
  FlashMessage$Payload,
  getMessageToShow,
  clearMessage,
  FLASH_MESSAGE_TRIGGER,
  verifyPayload,
} from './FlashMessage.helpers';
import './FlashMessage.scss';

class FlashMessage extends Component<any, FlashMessage$State> {
  closeTimeout: any;

  state: FlashMessage$State = {
    message: '',
    status: 'success',
    isShown: false,
  };

  componentDidMount() {
    const messageToShow = getMessageToShow();

    if (messageToShow) {
      this.showFlashMessage(messageToShow);
      clearMessage();
    }

    this.attachListener();
  }

  componentWillUnmount() {
    this.detachListeners();
  }

  attachListener = () => window.addEventListener('message', this.handleFlashTriggers);

  detachListeners = () => window.removeEventListener('message', this.handleFlashTriggers);

  handleFlashTriggers = ({ data }: MessageEvent): void => {
    if (data && data.type === FLASH_MESSAGE_TRIGGER && data.payload) {
      this.showFlashMessage(verifyPayload(data.payload));
    }
  };

  showFlashMessage = (message: FlashMessage$Payload) => {
    this.setState(() => ({
      ...message,
      isShown: true,
    }));
    this.resetTimeout();
    this.closeTimeout = setTimeout(this.hideFlashMessage, 10000);
  };

  resetTimeout = () => {
    clearTimeout(this.closeTimeout);
  };

  hideFlashMessage = () => this.setState(() => ({ isShown: false }), this.resetTimeout);

  render() {
    const { message, status, isShown } = this.state;

    const messageCN = classnames({
      'flashMessage': true,
      [`flashMessage_${status}`]: status,
      'flashMessage_show': isShown,
    });

    const wrapCN = classnames({
      'flashMessage__wrap': true,
      'flashMessage__wrap_show': isShown,
    });

    return (
      <div className={wrapCN}>
        <div className="flashMessage__container">
          {message && (
            <div className={messageCN}>
              <button
                type="button"
                className="flashMessage__close"
                onClick={this.hideFlashMessage}
                aria-label={t`Zamknij`}
              />
              <TextP3 bold className="flashMessage__text">
                {status === 'error' && (
                  <strong>
                    <Trans>Coś poszło nie tak…</Trans>{' '}
                  </strong>
                )}
                {message}
              </TextP3>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default FlashMessage;
