import { t } from '@lingui/macro';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import DropdownMenu from 'common/DropdownMenu';
import routes from 'utils/routeTranslator';

import { links } from '../Navigation.helpers';
import './BestRequestsNav.scss';

type Props = {
  mode?: 'dark';
};

export const BestRequestsNav: FC<Props> = (props) => {
  return (
    <DropdownMenu title={t`Najlepsze zlecenia`} theme={props.mode} dataTestId="dropdownMenuRequestsButton">
      <ul className="bestRequestsNav">
        {links.map(({ slug, title }) => (
          <li key={slug}>
            <Link className="bestRequestsNav__link" to={routes.get('front::provider.register.l3', { slug })}>
              {title}
            </Link>
          </li>
        ))}
      </ul>
    </DropdownMenu>
  );
};
