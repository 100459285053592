import { gql } from '@apollo/client';

export type FeatureFlag = {
  name: string;
  value: boolean;
};

export const SET_FEATURE_FLAGS = gql`
  mutation setFeatureFlags($featureFlags: [FeatureFlagInput!]!) {
    setFeatureFlags(featureFlags: $featureFlags)
  }
`;

export const FEATURE_FLAGS_QUERY = gql`
  query featureFlags {
    featureFlags {
      name
      enabled
    }
  }
`;
