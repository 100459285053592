export const getNewRelic = () => {
  if (typeof window === 'undefined') {
    return;
  }

  return window.newrelic;
};

export const createPageAction = (actionName: string, params = {}) => {
  const newRelic = getNewRelic();

  newRelic?.addPageAction(actionName, {
    ...params,
    pageUrl: window?.location.href,
  });
};

export const noticeError = (error: Error, customAttrs = {}) => {
  const newRelic = getNewRelic();

  newRelic?.noticeError(error, {
    ...customAttrs,
    pageUrl: window?.location.href,
  });
};
