import { useContext } from 'react';
import SnackbarContext from './SnackbarContext';
import { SnackbarShowProps } from './Snackbar.helpers';

const useSnackbar = () => {
  const { openSnackbar, closeSnackbar } = useContext(SnackbarContext);

  function showSnackbar(props: SnackbarShowProps) {
    if ('message' in props) {
      openSnackbar({
        message: props.message,
        variant: props.variant,
        options: props.options,
      });
    }

    if ('content' in props) {
      openSnackbar({
        content: props.content,
        variant: props.variant,
        options: props.options,
      });
    }
  }

  return { showSnackbar, closeSnackbar };
};

export default useSnackbar;
