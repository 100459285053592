import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { isRoute } from 'common/helpers/helpers';

const exceptions = ['front::user.request'];

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    const isException = Boolean(exceptions.filter((exception) => isRoute(pathname, exception)).length);
    if (!isException) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
}
