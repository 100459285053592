import { Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FC, ReactNode, HTMLProps } from 'react';

import { ArrowDownIcon } from 'common/icons/ArrowDownIcon';

import './ExpandButton.scss';

type Props = {
  className?: string;
  children?: ReactNode;
  expanded: boolean;
} & HTMLProps<HTMLButtonElement>;

const ExpandButton: FC<Props> = (props) => {
  const { className, expanded, children, ...rest } = props;

  const buttonCN = classnames(
    'expandButton',
    {
      'expandButton_expanded': expanded,
    },
    className
  );

  const defaultLabel = expanded ? <Trans>Pokaż mniej</Trans> : <Trans>Pokaż więcej</Trans>;

  return (
    // @ts-expect-error type mismatch
    <button type="button" className={buttonCN} {...rest}>
      <span className="expandButton__text">{children || defaultLabel}</span>
      <span className="expandButton__icon">
        <ArrowDownIcon />
      </span>
    </button>
  );
};

export default ExpandButton;
