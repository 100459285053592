import c from 'classnames';
import React, { ReactNode, HTMLProps, ReactHTML } from 'react';

import './Button.scss';

export type Button$Props = {
  kind?: 'cta' | 'primary' | 'secondary' | 'danger';
  children: ReactNode;
  className?: string;
  isLoading?: boolean;
  width?: 'full' | 'wide';
  icon?: ReactNode;
  as?: keyof ReactHTML;
  type?: string;
} & HTMLProps<HTMLButtonElement | HTMLAnchorElement>;

export default function Button(props: Button$Props) {
  const { kind = 'cta', children, className, isLoading, width, icon, as = 'button', type, ...rest } = props;

  const buttonCN = c(
    {
      'button': true,
      'button_cta': kind === 'cta',
      'button_primary': kind === 'primary',
      'button_secondary': kind === 'secondary',
      'button_danger': kind === 'danger',
      'button_icon': Boolean(icon),
      'button_loading': isLoading,
      'button_fullWidth': width === 'full',
      'button_wide': width === 'wide',
    },
    className
  );

  const TagName = as;
  const typeAttr = as === 'a' ? undefined : type || 'button';

  return (
    // @ts-expect-error type mismatch
    <TagName type={typeAttr} className={buttonCN} {...rest}>
      {icon && <span className="button__icon">{icon}</span>}
      {children}
    </TagName>
  );
}
