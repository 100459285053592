/* eslint-disable max-len */
import React from 'react';
import routes from 'utils/routeTranslator';
import config from 'config/config';
import favicon144 from 'common/icons/favicon/favicon-144.png';

const OrganizatinSchema = () => (
  <script
    type="application/ld+json"
    dangerouslySetInnerHTML={{
      __html: `{
        "@context": "http://schema.org",
        "@type": "Organization",
        "name": "Fixly",
        "alternateName": ["flixly","fixli","flixy","flixli","fiksly","fizly","flxly","filxly","fixlu","flyxly","fixtly","fuxly","fixily","dixly","fixky","fixlee","vixly","fixdy","flixi","fixfy","fixle","fixny"],
        "url": "${config.SITE_URL}${routes.get('front::index')}",
        "logo": "${favicon144}",
        "sameAs": [
          "https://www.facebook.com/fixlypl/",
          "https://www.youtube.com/channel/UCQDvMXhFoiMc3qK0mo5NPJQ",
          "https://www.instagram.com/fixly.pl/",
          "https://www.linkedin.com/company/olx-group",
          "https://twitter.com/pl_fixly",
          "https://pl.pinterest.com/fixlypl/",
          "https://pl.trustpilot.com/review/fixly.pl",
          "https://play.google.com/store/apps/details?id=com.fixly.android.provider&referrer=channel-feature-campaign",
          "https://apps.apple.com/pl/app/fixly-dla-wykonawc%C3%B3w/id1293721839",
          "https://pl.wikipedia.org/wiki/OLX"
        ],
        "memberOf":["https://www.olx.pl/"]
      }`,
    }}
  />
);

export default OrganizatinSchema;
