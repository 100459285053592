import { PromoPackageState } from 'Components/Entry/Entry';
import React, { createContext, useContext } from 'react';

export type ExperimentContextType = {
  'GS_9697': {
    promoPackageState: { status: 'hidden' | 'visible'; type: null | 'points' | 'quotes'; value: number };
    setPromoPackageState: React.Dispatch<React.SetStateAction<PromoPackageState>>;
  };
} | null;

const ExperimentContext = createContext<ExperimentContextType>(null);

export const ExperimentContextProvider = ({
  children,
  value,
}: {
  children: React.ReactNode;
  value: ExperimentContextType;
}) => {
  return <ExperimentContext.Provider value={value}>{children}</ExperimentContext.Provider>;
};

export const useExperimentContext = () => {
  const context = useContext(ExperimentContext);

  if (context === undefined) {
    throw new Error('useExperimentContext must be used within a ExperimentContextProvider');
  }

  return context;
};
