import React, { ReactNode, HTMLProps } from 'react';
import classnames from 'classnames';

import './Error.scss';

type Error$Props = HTMLProps<HTMLSpanElement> & {
  children: ReactNode;
  className?: string;
};

export default function Error(props: Error$Props) {
  const { children, className, ...rest } = props;

  const errorCN = classnames('error', className);

  return (
    <span className={errorCN} {...rest}>
      {children}
    </span>
  );
}
