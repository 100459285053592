import { createPageAction, noticeError } from 'utils/errorLogger/newRelic';
import { getHostname } from 'utils/location/hostname';
import { isStaging } from 'utils/location/isStaging';
import { getDomain } from 'utils/location/domain';

export enum ENV {
  STG = 'STG',
  PRD = 'PRD',
}
type CookieAttributes = import('@olxeu-eprivacy-storage/react').CookieAttributes;
type CookieStorage = import('@olxeu-eprivacy-storage/react').CookieStorage;

enum CategoryCodes {
  C0001 = 'C0001',
  C0002 = 'C0002',
  C0003 = 'C0003',
  C0004 = 'C0004',
}
interface CookiesCategorisation {
  [k: string]: CategoryCodes[];
}
enum CookieScope {
  OLX = 'OLX',
  RE = 'RE',
  Motors = 'Motors',
  Fixly = 'Fixly',
}

let STORAGE_INITIALIZED = false;

const logAndThrow = (errorMsg: string, customAttrs = {}) => {
  const error = new Error(errorMsg);

  noticeError(error, customAttrs);
  throw error;
};

function triggerNotInitializedError(cookieName: string, method: string): undefined {
  logAndThrow(
    // eslint-disable-next-line max-len
    'You are trying to access cookie storage before initialisation. initializeCookieStorage method has to be called prior to any cookie storage access.',
    { cookieName, method }
  );
  return undefined;
}

/**@internal */
export function handleExpectedErrors(error: any) {
  const skipUnwantedError = error.name === 'ForbiddenAccessError';
  if (skipUnwantedError) return;

  createPageAction(error.name, {
    source: 'eprivacy-react-sdk',
    capability: 'cookies_compliance',
    ...error,
  });
}

export interface FixlyCookieStorage extends CookieStorage {
  /**
   * (Browser only) Returns cookie value or the default if the key was not found
   *
   * @param key Cookie name (key)
   * @param defaultValue Default value in case the cookie is not present or
   * the user did not give their consent to store the cookie
   * @returns Cookie value
   */
  get: (key: string, defaultValue?: string) => ReturnType<CookieStorage['get']>;

  /**
   * (Browser only) sets cookie in users browser
   *
   * @param key string
   * @param value string
   * @param extraParams {}
   * @param setDomain boolean
   * @returns undefined
   */
  set: <Value extends { toString(): string }>(
    key: string,
    value: Value,
    extraParams?: CookieAttributes,
    setDomain?: boolean
  ) => ReturnType<CookieStorage['set']>;

  /**
   * (Browser only) Removes cookie from user's browser
   *
   * @param key Cookie name (key)
   * @param params Cookie attributes with which the cookie was previously set
   * @param wasSetDomain If an explicit domain has been specified when setting the cookie
   */
  remove: (key: string, params?: CookieAttributes, wasSetDomain?: boolean) => ReturnType<CookieStorage['remove']>;
  isInitialized: boolean;
}

/**
 * Cookie storage interface. initializeCookieStorage has to be called first for this interface to be functional,
 * otherwise it will throw an error when used.
 */

export const cookieStorage: FixlyCookieStorage = {
  get: (key) => triggerNotInitializedError(key, 'get'),
  set: (key) => triggerNotInitializedError(key, 'set'),
  remove: (key) => triggerNotInitializedError(key, 'remove'),
  isInitialized: STORAGE_INITIALIZED,
};

/*
  This function may throw an error in case of Network I/O failure.
*/
async function fetchCookieCategorisationData(env: string, retryCount = 0): Promise<CookiesCategorisation> {
  const getReservoirLink = await import('@olxeu-eprivacy-storage/react').then((module) => module.getReservoirLink);
  const url = getReservoirLink(CookieScope.Fixly, env as ENV);
  const response = await fetch(url);

  if (response.ok) {
    const data = await response.json();
    return data as unknown as CookiesCategorisation;
  }

  if (retryCount < 2) {
    return fetchCookieCategorisationData(env, retryCount + 1);
  }

  const responseText = await response.text();

  return logAndThrow(
    `Failed to fetch cookies categorisation data with response: (${response.status}) ${responseText}`,
    {
      url: response.url,
      capability: 'cookies_compliance',
    }
  );
}

/**
 * Initialize the cookie storage.
 * it will initialize the ePrivacy storage but with
 *
 * This function should be called immediately after your application starts and before any access
 * to cookie storage happens.
 *
 * If the cookie categorisation data won't be fetched (Network I/O failure), the function will throw an error.
 *
 * @param env
 * @param debug
 */

export async function initializeCookieStorage(env: string, debug = false) {
  if (!STORAGE_INITIALIZED) {
    const data = await fetchCookieCategorisationData(env);
    const createCookieStorage = await import('@olxeu-eprivacy-storage/react').then(
      (module) => module.createCookieStorage
    );
    const { get, set, remove } = createCookieStorage(true, data, debug, handleExpectedErrors);

    cookieStorage['get'] = createGet(get);
    cookieStorage['set'] = createSet(set);
    cookieStorage['remove'] = createRemove(remove);

    STORAGE_INITIALIZED = true;
  }
}

const listOfDomainsRegex = [
  /^https:\/\/fixly\.stg\.01\.eu-west-1\.eu\.olx\.org\/$/, // staging
  /^http:\/\/fixpl-local\.dev\.rebbix\.com:3000/, // local
];

const needsCookieDomainRewrite = (): boolean => {
  const listOfDomains = listOfDomainsRegex.some((regex) => regex.test(getHostname()));
  console.log('listOfDomains = ', listOfDomains);
  return listOfDomains;
};

const createGet =
  <GetFn extends CookieStorage['get']>(getFn: GetFn) =>
  (key: string, defaultValue?: string) => {
    const value = getFn(key);
    return value === undefined ? defaultValue : value;
  };

const createSet =
  <SetFn extends CookieStorage['set']>(setFn: SetFn) =>
  <Value extends { toString(): string }>(key: string, value: Value, extraParams = {}, setDomain = true) => {
    let params: { expires: number; domain?: string } = {
      expires: 999,
      ...extraParams,
    };
    if (setDomain) {
      if (!isStaging()) {
        params = { domain: getDomain(), ...params };
      } else if (isStaging() && needsCookieDomainRewrite()) {
        console.log(params);
        params = { domain: getDomain(false), ...params };
      }
    }

    return setFn(key, value.toString(), params);
  };

const createRemove =
  <RemoveFn extends CookieStorage['remove']>(removeFn: RemoveFn) =>
  (key: string, params = {}, wasSetDomain = true) => {
    if (wasSetDomain) {
      if (!isStaging()) {
        params = { domain: getDomain(), ...params };
      } else if (isStaging() && needsCookieDomainRewrite()) {
        params = { domain: getDomain(false), ...params };
      }
    }

    return removeFn(key, params);
  };
