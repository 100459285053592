import React, { FC, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { LANDING_APP_BANNER_KEY } from 'Components/LandingAppBanner/LandingAppBanner';
import { stripErrorPrefixes } from 'common/helpers/helpers';
import config from 'config/config';
import analytics from 'utils/analytics/analytics';
import { FrictionHeaders, getFrictionChallengeHeaders } from 'utils/api/headers';
import api, { AuthResult, LoginInput } from 'utils/api/index';
import { isUserRegistered } from 'utils/auth/auth.helpers';
import { getFrictionInit, JSXElementFromHTMLElement } from 'utils/captcha/useFrictionFlow';
import localStorage from 'utils/localStorage/localStorage';
import { LoginStepEnum, updateUserSettings } from './Login.helpers';
import CodeStepForm from './steps/CodeStepForm';
import LoginLinkStep from './steps/LoginLinkForm';
import LoginStepForm from './steps/LoginForm';
import PasswordStepForm from './steps/PasswordForm';
import './Login.scss';
import SetPasswordStepForm from './steps/SetPasswordForm';

type Props = {
  step: LoginStepEnum;
  onStepChange: (step: LoginStepEnum) => void;
  onResetPasswordOpen: (email?: string) => void;
  handleClose?: (closer: string) => void;
  onSuccess: (data?: AuthResult) => void;
  mod?: string;
};

const LoginForm: FC<Props> = (props) => {
  const [login, setLogin] = useState('');
  const [formLoading, setFormLoading] = useState(false);
  const [isCheckAccountLoading, setIsCheckAccountLoading] = useState(false);
  const [isResendLoading, setIsResendLoading] = useState(false);
  const [isResendSent, setIsResendSent] = useState(false);
  const [challengeElement, setChallengeElement] = useState<JSX.Element | null>(null);
  const [formErrors, setFormErrors] = useState<Record<string, any>>({});

  const isLoginWithEmail = (login: string): boolean => login.includes('@');

  const startLoginFlow = async (username: string): Promise<FrictionHeaders | void> => {
    if (!config.OLX_FRICTION_FLOW_ENABLED) return Promise.resolve();

    const challengeState = await getFrictionInit('login', username, (element) => {
      setChallengeElement(element ? <JSXElementFromHTMLElement htmlElement={element} /> : null);
    });
    return getFrictionChallengeHeaders(challengeState);
  };

  const processPasswordLessUser = async (login: string) => {
    const { onStepChange } = props;

    if (isLoginWithEmail(login)) {
      await sendLoginLink(login);
      onStepChange(LoginStepEnum.login_link_step);
    } else {
      await sendLoginCode(login);
      onStepChange(LoginStepEnum.code_step);
    }
    setIsCheckAccountLoading(false);
  };

  const openResetPassword = () => {
    if (isLoginWithEmail(login)) {
      props.onResetPasswordOpen(login);
    } else {
      sendLoginCode(login, true)
        .then(() => {
          props.onStepChange(LoginStepEnum.code_step);
        })
        .finally(() => setIsCheckAccountLoading(false));
    }
  };

  const sendLoginCode = async (phone: string, reset?: boolean) => {
    const headers = await startLoginFlow(phone);
    return api.sendLoginCode({ phone, forgotPassword: reset }, headers);
  };

  const resendLoginLink = (): void => {
    sendLoginLink(login).then(() => setIsResendSent(true));
  };

  const sendLoginLink = async (email: string) => {
    const headers = await startLoginFlow(email);
    return api.sendLoginLink({ email }, headers);
  };

  const resendLoginCode = () => {
    setIsResendLoading(true);
    analytics.trackEvent('code_login_popup_send_again');

    sendLoginCode(login, true)
      .then(() => setIsResendSent(true))
      .finally(() => setIsResendLoading(false));
  };

  const submitLoginStep = (login: string) => {
    const loginTrimmed = login.trim();

    if (isCheckAccountLoading) return;

    setLogin(loginTrimmed);
    setIsCheckAccountLoading(true);
    setFormErrors({});
    isUserRegistered(loginTrimmed)
      .then((response) => {
        const { checkUserRegistered } = response.data;
        if (!checkUserRegistered) {
          setFormErrors({ login: t`Niestety nie mamy Cię jeszcze w naszej bazie` });
        } else if (checkUserRegistered.isGuest) {
          return processPasswordLessUser(login);
        } else {
          props.onStepChange(LoginStepEnum.password_step);
        }
      })
      .catch((err) => {
        setFormErrors({ login: stripErrorPrefixes(err.message) });
      })
      .finally(() => setIsCheckAccountLoading(false));
  };

  const submitLogin = (values: { password?: string; code?: string }) => {
    const credentials: LoginInput = {};

    if (formLoading) return;

    analytics.global.loginSubmit();

    if (isLoginWithEmail(login)) {
      credentials.email = login;
    } else {
      credentials.phone = login;
    }

    if (values.password) {
      credentials.password = values.password;
    }

    if (values.code) {
      credentials.code = values.code.trim();
      analytics.trackEvent('code_login_popup_confirm');
    }

    setFormLoading(true);
    setFormErrors({});
    startLoginFlow(login)
      .then((headers) => api.login(credentials, headers, true))
      .then((res) => {
        localStorage.setItem(LANDING_APP_BANNER_KEY, 1);
        onLoginSuccess(res.data);
      })
      .catch((error) => {
        setFormErrors({
          password:
            error.response && error.response.data
              ? error.response.data.errors[0].message
              : t`Coś poszło nie tak...Spróbuj ponownie`,
          code: t`Nieprawidłowy kod`,
        });
        onLoginError();
      });
  };

  const setNewPassword = (values: { new_password?: string }) => {
    if (!values.new_password) return;
    analytics.trackEvent('create_password_popup_confirm');
    setFormLoading(true);
    setFormErrors({});
    updateUserSettings(values.new_password)
      .then(() => props.handleClose?.('success'))
      .catch((error) => {
        setFormErrors({
          new_password:
            error.response && error.response.data
              ? error.response.data.errors[0].message
              : t`Coś poszło nie tak...Spróbuj ponownie`,
        });
        onLoginError();
      });
  };

  const onLoginSuccess = (data: AuthResult): void => {
    const userId = data.userId;

    analytics.global.loginSuccess(userId, false, () => {
      props.onSuccess(data);
    });
  };

  const onLoginError = (): void => {
    analytics.global.loginError(false);
    setFormLoading(false);
  };

  const { step, mod } = props;

  if (step === LoginStepEnum.login_link_step) {
    return (
      <LoginLinkStep
        login={login}
        challengeElement={challengeElement}
        handleResend={resendLoginLink}
        loading={isResendLoading}
        disabled={isResendSent}
      />
    );
  }

  if (step === LoginStepEnum.password_step) {
    return (
      <PasswordStepForm
        login={login}
        loading={formLoading}
        mod={mod}
        error={formErrors?.password}
        onSubmit={(password) => submitLogin({ password })}
        onForgotPassword={openResetPassword}
        challengeElement={challengeElement}
      />
    );
  }

  if (step === LoginStepEnum.code_step) {
    return (
      <>
        <p className="login__loginCodeSent">
          <Trans>
            Wysłaliśmy SMSa z kodem aktywacyjnym na numer <b>{login}</b>.
          </Trans>
        </p>
        <CodeStepForm
          mod={mod}
          loading={formLoading}
          isResending={isResendLoading}
          resendDisabled={isResendSent}
          onSubmit={(code) => submitLogin({ code })}
          onResend={resendLoginCode}
          error={formErrors.code}
        />
      </>
    );
  }

  if (step === LoginStepEnum.set_password_step) {
    return (
      <SetPasswordStepForm
        mod={props.mod}
        loading={formLoading}
        error={formErrors?.new_password}
        onSubmit={(new_password) => setNewPassword({ new_password })}
        onDismiss={() => props.handleClose?.('no_thanks')}
      />
    );
  }

  return (
    <LoginStepForm
      error={formErrors?.login}
      challengeElement={challengeElement}
      mod={mod}
      loading={isCheckAccountLoading}
      onSubmit={(login) => submitLoginStep(login)}
    />
  );
};

export default LoginForm;
