import React, { useState } from 'react';
import { createPortal } from 'react-dom';

import {
  SnackbarProviderProps,
  SnackbarShowProps,
  SnackbarMessage,
  VariantType,
  OptionsObject,
  DEFAULT_OPTIONS,
} from './Snackbar.helpers';
import SnackbarContainer from './SnackbarContainer';
import SnackbarContext from './SnackbarContext';
import SnackbarItem from './SnackbarItem';

const SnackbarProvider = ({ children, domRoot }: SnackbarProviderProps) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<SnackbarMessage>('');
  const [content, setContent] = useState<React.ReactNode>();
  const [variant, setVariant] = useState<VariantType>(DEFAULT_OPTIONS.variant);
  const [options, setOptions] = useState<OptionsObject>(DEFAULT_OPTIONS);

  const openSnackbar = (props: SnackbarShowProps) => {
    setContent(null);
    setMessage('');
    setVariant(props.variant || DEFAULT_OPTIONS.variant);
    setOptions({ ...DEFAULT_OPTIONS, ...props.options });
    setOpen(true);

    if ('message' in props) {
      setMessage(props.message);
    }
    if ('content' in props) {
      setContent(props.content);
    }
  };

  const closeSnackbar = () => {
    setMessage('');
    setContent(null);
    setOptions(DEFAULT_OPTIONS);
    setOpen(false);
  };

  const snackbar = () => {
    return (
      <SnackbarContainer>
        {open ? (
          <SnackbarItem
            message={message}
            content={content}
            variant={variant}
            options={options}
            onClose={closeSnackbar}
          />
        ) : null}
      </SnackbarContainer>
    );
  };

  return (
    <SnackbarContext.Provider
      value={{
        openSnackbar,
        closeSnackbar,
      }}
    >
      {children}
      {domRoot ? createPortal(snackbar(), domRoot) : snackbar()}
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;
