import { t, Trans } from '@lingui/macro';
import { useFormik } from 'formik';
import React, { FC, useState } from 'react';
import * as Yup from 'yup';

import { Button, LinkButton } from 'uikit/Button';
import FormRow from 'uikit/FormRow/FormRow';
import InputNext from 'uikit/Input/Input';
import { sendResetPassMail } from 'utils/api/index';

import './ResetPassword.scss';

type Props = {
  email?: string;
  onSubmit?: () => void;
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required(t`Nieprawidłowy adres e-mail`)
    .email(t`Nieprawidłowy adres e-mail`),
});

const ResetPasswordForm: FC<Props> = (props) => {
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [resetPasswordSent, setResetPasswordSent] = useState<boolean>(false);
  const [resendPasswordSent, setResendPasswordSent] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');

  const resendResetPassword = (): void => {
    submitResetPassword({ email: email }).then(() => {
      setResendPasswordSent(true);
    });
  };

  const { values, errors, touched, isSubmitting, handleChange, handleSubmit, setFieldError } = useFormik({
    initialValues: {
      email: props.email,
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      if (!values.email) return;
      submitResetPassword({ email: values.email });
    },
  });

  const submitResetPassword = (values: { email: string }): Promise<void> => {
    if (formLoading) {
      return Promise.resolve();
    }

    setFormLoading(true);
    setEmail(values.email);

    return sendResetPassMail(values.email)
      .then(() => {
        setResetPasswordSent(true);
        if (props.onSubmit) setTimeout(props.onSubmit, 5000);
      })
      .catch((error) => {
        const errorMessage =
          error.response && error.response.data
            ? error.response.data.errors[0].message
            : t`Coś poszło nie tak...Spróbuj ponownie`;
        setFieldError('email', errorMessage);
      })
      .finally(() => setFormLoading(false));
  };

  return resetPasswordSent ? (
    <div className="resetPassword__sent">
      <p className="resetPassword__resetNotification">
        <Trans>
          Wysłaliśmy e-mail z linkiem resetującym hasło na adres <b>{email || props.email || ''}</b>
        </Trans>
      </p>
      <LinkButton type="button" name="resendResetPassword" onClick={resendResetPassword} disabled={resendPasswordSent}>
        <Trans>Wyślij ponownie</Trans>
      </LinkButton>
    </div>
  ) : (
    <form className="resetPassword__form" onSubmit={handleSubmit}>
      <FormRow>
        <InputNext
          id="resetPassword__email"
          name="email"
          type="email"
          placeholder={t`Adres e-mail`}
          value={values.email}
          error={touched.email && errors.email}
          onChange={handleChange}
        />
      </FormRow>
      <Button type="submit" kind="cta" width="full" disabled={isSubmitting}>
        <Trans>Zresetuj hasło</Trans>
      </Button>
    </form>
  );
};

export default ResetPasswordForm;
