import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMatchMedia } from '../hooks/useMatchMedia';

const RESTRICTED_URL = 'wiadomosci';
/**
 * The query is set according to project breakpoints
 * @see src/_mixin.scss
 */
const MOBILE_VIEWPORT_QUERY = '(max-width: 568px)';

/**
 * React hook which is responsible for chat visibility logic.
 * Is based on two conditions router path and viewport size.
 */
export const useChatVisibility = () => {
  const [isVisible, setVisible] = useState(false);
  const { pathname } = useLocation();
  const isMobile = useMatchMedia(MOBILE_VIEWPORT_QUERY);

  // handle route and viewport changes
  useEffect(() => {
    if (pathname.includes(RESTRICTED_URL) || isMobile) {
      setVisible(false);
    } else {
      setVisible(true);
    }
  }, [setVisible, pathname, isMobile]);

  return isVisible;
};
