let cachedHash = '';

export const getFingerPrintHash = (): Promise<string> => {
  if (cachedHash) return Promise.resolve(cachedHash);

  const lazyImport = import('@fingerprintjs/fingerprintjs');

  return lazyImport
    .then((lib) => lib.load())
    .then((agent) => agent.get())
    .then((res) => {
      cachedHash = res.visitorId;
      return res.visitorId;
    });
};
