const loadDynamicScript = (src: string, id: string): Promise<HTMLScriptElement> =>
  new Promise((resolve, reject) => {
    if (typeof window === 'undefined') reject(new Error('Client side only!'));

    const existingScript: HTMLElement | HTMLScriptElement | null = document.getElementById(id);

    if (existingScript && existingScript.nodeName !== 'SCRIPT') {
      return reject(new Error('WTF!'));
    }

    if (!existingScript) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = src;
      script.id = id;
      document.head.appendChild(script);

      script.addEventListener('load', () => resolve(script), false);
      script.onerror = (error) => reject(error);
    } else {
      resolve(existingScript as HTMLScriptElement);
    }
  });

export default loadDynamicScript;
