import { useMutation } from '@apollo/client';
import { t, Trans } from '@lingui/macro';
import classnames from 'classnames';
import { useFormik } from 'formik';
import { GraphQLError } from 'graphql';
import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';

import { LANDING_APP_BANNER_KEY } from 'Components/LandingAppBanner/LandingAppBanner';
import { stripGraphqlPrefix } from 'common/helpers/helpers';
import config from 'config/config';
import { Button, LinkButton } from 'uikit/Button';
import FormRow from 'uikit/FormRow/FormRow';
import Input from 'uikit/Input/Input';
import { TextDisplay } from 'uikit/Text';
import analytics from 'utils/analytics/analytics';
import api, { getResponseError } from 'utils/api';
import { FrictionHeaders, getFrictionChallengeHeaders } from 'utils/api/headers';
import auth from 'utils/auth/auth';
import { isUserRegistered } from 'utils/auth/auth.helpers';
import { getFrictionInit, JSXElementFromHTMLElement } from 'utils/captcha/useFrictionFlow';
import localStorage from 'utils/localStorage/localStorage';

import {
  UserCredentials,
  ComplexLoginFormValues,
  registerCustomerMutation,
  RegisterCustomerVars,
  LoginType,
  RegisterCustomerResponse,
} from './ComplexLogin.helpers';
import './ComplexLogin.scss';
import ComplexLoginButtons from './ComplexLoginButtons';
import ComplexLoginForm from './ComplexLoginForm';
import './ComplexLoginForm.scss';

type SignInFormProps = {
  showPhoneMessage?: boolean;
  isWizard?: boolean;
};

const SignInFormStep: FC<SignInFormProps> = (props) => {
  return (
    <div className="complexLogin">
      <div className="complexLogin__header">
        <TextDisplay bold>
          {props.isWizard ? (
            <Trans>Jak mamy poinformować Cię o wiadomościach od sprawdzonych wykonawców?</Trans>
          ) : (
            <Trans>Jak informowaliśmy Cię o wiadomościach od wykonawców?</Trans>
          )}
        </TextDisplay>
        {props.showPhoneMessage && (
          <p className="complexLogin__description">
            <Trans>Twój numer telefonu podamy max. 6 zainteresowanym wykonawcom</Trans>
          </p>
        )}
      </div>

      {props.children}
    </div>
  );
};

type PasswordLoginFormProps = {
  name: string;
  isWizard?: boolean;
  loading?: boolean;
  isResetPasswordSent?: boolean;
  isResetPasswordSending?: boolean;
  onSubmit?: (password: string) => void;
  onChange?: () => void;
  onResetClick?: () => void;
  challengeElement?: ReactNode;
  actions?: ReactNode;
  error?: string | null;
};

const PasswordLoginForm: FC<PasswordLoginFormProps> = (props) => {
  const { isWizard, loading, name, onChange } = props;

  const { values, errors, touched, handleSubmit, handleChange, setFieldError } = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required(t`Uzupełnij pole "hasło"`),
    }),
    onSubmit: () => {
      if (props.onSubmit) props.onSubmit(values.password);
    },
  });

  useEffect(() => {
    if (props.error) {
      setFieldError('password', props.error);
    }
  }, [props.error, setFieldError]);

  useEffect(() => {
    if (values.password && onChange) {
      onChange();
    }
  }, [onChange, values.password]);

  return (
    <div className="complexLogin">
      <div className="complexLogin__header">
        <TextDisplay bold>
          {isWizard ? (
            <Trans>Cześć {name}! Jeśli chcesz wysłać zapytanie - zaloguj się</Trans>
          ) : (
            <Trans>Zaloguj się, by zobaczyć swoje zapytania</Trans>
          )}
        </TextDisplay>
      </div>

      <form className="complexLoginForm__form" onSubmit={handleSubmit}>
        <div className="complexLoginForm__formContent">
          <FormRow>
            <Input
              id="password"
              name="password"
              type="password"
              placeholder={t`Hasło`}
              value={values.password}
              error={touched.password && errors.password}
              onChange={handleChange}
            />
          </FormRow>

          {props.challengeElement}

          <Button
            className="complexLoginForm__submit complexLoginForm__submit_wizard"
            type="submit"
            width="full"
            isLoading={loading}
          >
            <Trans>Wyślij</Trans>
          </Button>

          <LinkButton
            onClick={props.onResetClick}
            className="complexLoginForm__resetPassword"
            loading={props.isResetPasswordSending}
            disabled={props.isResetPasswordSent}
            type="button"
          >
            <Trans>Nie pamiętasz hasła?</Trans>
          </LinkButton>
        </div>
        {props.actions}
      </form>
    </div>
  );
};

type CodeLoginFormProps = {
  name: string;
  phone?: string;
  email?: string;
  isWizard?: boolean;
  loading?: boolean;
  isResetPasswordSending?: boolean;
  isResetPasswordSent?: boolean;
  error?: string | null;
  challengeElement?: ReactNode;
  actions?: ReactNode;
  onSubmit?: (code: string) => void;
  onResetClick?: () => void;
};

const CodeLoginForm: FC<CodeLoginFormProps> = (props) => {
  const { isWizard, phone, email, name, loading } = props;

  const { values, errors, touched, handleChange, handleSubmit, setFieldError } = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema: Yup.object().shape({
      code: Yup.string().required(t`Uzupełnij pole "kod"`),
    }),
    onSubmit: () => {
      if (props.onSubmit) props.onSubmit(values.code);
    },
  });

  useEffect(() => {
    if (props.error) {
      setFieldError('code', props.error);
    }
  }, [props.error, setFieldError]);

  useEffect(() => {
    if (values.code) {
      analytics.trackEvent('code_login_popup_type');
    }
  }, [values.code]);

  const codePlaceholder = phone ? t`Wpisz kod z SMSa` : t`Wpisz kod z e-maila`;

  const smsSentText = phone ? (
    <Trans>
      Wysłaliśmy SMSa z kodem na numer <b>{phone}</b>.
    </Trans>
  ) : (
    <Trans>
      Wysłaliśmy tymczasowe dane do logowania na adres <b>{email}</b>. Sprawdź swoją skrzynkę mailową
    </Trans>
  );

  const smsSentTextLogin = phone ? (
    <Trans>
      Wysłaliśmy Ci SMSa z kodem do logowania na numer <b>{phone}</b>
    </Trans>
  ) : (
    <Trans>
      Wysłaliśmy Ci kod do logowania na adres <b>{email}</b>
    </Trans>
  );

  const smsSentCN = classnames('complexLoginForm__smsSent', {
    'complexLoginForm__smsSent_login': !isWizard,
  });

  return (
    <div className="signinStep">
      <div className="complexLogin__header">
        <TextDisplay bold>
          {isWizard ? (
            <Trans>Cześć {name}! Jeśli chcesz wysłać zapytanie - zaloguj się</Trans>
          ) : (
            <Trans>Zaloguj się, by zobaczyć swoje zapytania</Trans>
          )}
        </TextDisplay>
      </div>

      <form className="complexLoginForm__form" onSubmit={handleSubmit}>
        <div className="complexLoginForm__formContent">
          <p className={smsSentCN}>{isWizard ? smsSentText : smsSentTextLogin}</p>

          <FormRow>
            <Input
              id="code"
              name="code"
              value={values.code}
              error={touched.code && errors.code}
              onChange={handleChange}
              onFocus={() => analytics.trackEvent('code_login_popup_click')}
              inputMode="decimal"
              autoCorrect="off"
              autoCapitalize="off"
              placeholder={codePlaceholder}
            />
          </FormRow>

          {props.challengeElement}

          <Button
            className="complexLoginForm__submit complexLoginForm__submit_wizard"
            type="submit"
            width="full"
            isLoading={loading}
          >
            <Trans>Wyślij</Trans>
          </Button>

          <LinkButton
            type="button"
            onClick={props.onResetClick}
            className="complexLoginForm__resetPassword"
            loading={props.isResetPasswordSending}
            disabled={props.isResetPasswordSent}
          >
            <Trans>Wyślij ponownie</Trans>
          </LinkButton>
        </div>
        {props.actions}
      </form>
    </div>
  );
};

type Props = {
  values?: ComplexLoginFormValues;
  isWizard?: boolean;
  cityId?: string;
  onSubmit: (userId: string, guestUser?: boolean, isUserVerified?: boolean, registerType?: string) => void;
  onBack?: () => void;
  onEmailRegistered?: () => void;
  onFormChange?: (values: ComplexLoginFormValues) => void;
  onGetUser?: (name: string) => void;
  onCodeSent?: () => void;
};

const ComplexLogin: FC<Props> = (props) => {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [inputErrors, setInputErrors] = useState<Record<'email' | 'phone', string | null>>({
    email: null,
    phone: null,
  });
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [codeError, setCodeError] = useState<string | null>(null);
  const [isCheckingEmailLoading, setCheckingEmailLoading] = useState<boolean>(false);
  const [isSigningLoading, setSigningLoading] = useState<boolean>(false);
  const [isAccountRegistered, setAccountRegistered] = useState<boolean>(false);
  const [isResetPasswordSent, setResetPasswordSent] = useState<boolean>(false);
  const [isResetPasswordSending, setResetPasswordSending] = useState<boolean>(false);
  const [hasPassword, setHasPassword] = useState<boolean>(false);

  const [formStep, setFormStep] = useState<string | null>(null);
  const [challengeElement, setChallengeElement] = useState<JSX.Element | null>(null);

  const [registerCustomer] = useMutation<RegisterCustomerResponse, RegisterCustomerVars>(registerCustomerMutation, {
    onError: (error) => {
      analytics.user.registrationError({ is_professional: false });
      const errorMessage = (error.graphQLErrors && error.graphQLErrors[0].message) || getResponseError(error);
      setInputErrors({
        email: isEmailStep() ? errorMessage : null,
        phone: isPhoneStep() ? errorMessage : null,
      });
    },
  });

  const onBack = (): void => {
    setAccountRegistered(false);
    setResetPasswordSent(false);
  };

  const isEmailStep = (): boolean => formStep === LoginType.Email;
  const isPhoneStep = (): boolean => formStep === LoginType.SMS;
  const getCredentials = (): UserCredentials => {
    const credentials: UserCredentials = {};

    if (phone) {
      credentials.phone = phone;
    }
    if (email) {
      credentials.email = email;
    }
    if (password) {
      credentials.password = password;
    }
    if (code) {
      credentials.code = code;
    }

    return credentials;
  };

  const checkAccountUsage = (values: ComplexLoginFormValues): void => {
    const { onEmailRegistered, isWizard, onGetUser, onCodeSent } = props;

    if (isCheckingEmailLoading) return;

    setEmail(values.email || '');
    setPhone(values.phone || '');
    if (values.name) setName(values.name);
    setCheckingEmailLoading(true);

    isUserRegistered(values.phone || values.email || '', isWizard)
      .then((response) => {
        const { checkUserRegistered } = response.data;

        if (!checkUserRegistered) {
          if (isWizard) {
            signUpUser({
              ...values,
              subscribeToMarketing: true,
              cityId: props.cityId,
            })
              .catch((error) => {
                if (error.graphQLErrors) {
                  // checking for server side email validation error
                  const emailError = error.graphQLErrors.find((item: GraphQLError) => {
                    return item.name === 'ValidationEmailIsNotValid';
                  });
                  setInputErrors({
                    email: emailError ? emailError.message : null,
                    phone: null,
                  });
                }
              })
              .finally(() => setCheckingEmailLoading(false));
          } else {
            const userNotFoundMessage = t`Niestety nie mamy Cię jeszcze w naszej bazie`;
            setInputErrors({
              email: isEmailStep() ? userNotFoundMessage : null,
              phone: isPhoneStep() ? userNotFoundMessage : null,
            });
            setCheckingEmailLoading(false);
          }
        } else if (checkUserRegistered.isProvider && isWizard) {
          // eslint-disable-next-line max-len
          const emailTakenBySP = t`Na ten adres e-mail zostało już założone konto wykonawcy. Jeśli chcesz dodać zapytanie, użyj innego adresu e-mail.`;
          // eslint-disable-next-line max-len
          const phoneTakenBySP = t`Na ten numer telefonu zostało już założone konto wykonawcy. Jeśli chcesz dodać zapytanie, użyj innego numeru`;

          setInputErrors({
            email: isEmailStep() ? emailTakenBySP : null,
            phone: isPhoneStep() ? phoneTakenBySP : null,
          });
          setCheckingEmailLoading(false);
        } else if (checkUserRegistered.isGuest) {
          sendLoginCode()
            .then(() => {
              analytics.trackEvent('code_login_popup_show');
              if (checkUserRegistered && onCodeSent) {
                onCodeSent();
              }
              setName(checkUserRegistered.name);
              setHasPassword(false);
              setAccountRegistered(true);
              if (onEmailRegistered) onEmailRegistered();
            })
            .finally(() => setCheckingEmailLoading(false));
        } else {
          setName(checkUserRegistered.name);
          setAccountRegistered(true);
          setHasPassword(true);
          setCheckingEmailLoading(false);
          if (onEmailRegistered) onEmailRegistered();
        }

        if (checkUserRegistered && typeof onGetUser === 'function') {
          onGetUser(checkUserRegistered.name);
        }
      })
      .catch((error) => {
        setInputErrors({
          email: isEmailStep() ? getResponseError(error) : null,
          phone: isPhoneStep() ? stripGraphqlPrefix(error.message) : null,
        });
      })
      .finally(() => setCheckingEmailLoading(false));
  };

  const sendResetPass = async (isResend = false): Promise<void> => {
    if (isResetPasswordSending) return;

    setResetPasswordSending(true);

    await sendLoginCode(true);

    analytics.user.serviceRequestPasswordReset();

    setHasPassword(false);

    if (isResend) {
      analytics.trackEvent('code_login_popup_send_again');
      setResetPasswordSent(true);
    }
    setResetPasswordSending(false);
  };

  const sendLoginCode = async (reset?: boolean): Promise<any> => {
    const value = phone ? { phone } : { email };

    const startLoginFlow = async (): Promise<FrictionHeaders | void> => {
      if (!config.OLX_FRICTION_FLOW_ENABLED) return;

      const username = String(phone || email);
      const challengeState = await getFrictionInit('login', username, renderChallengeElement);
      return getFrictionChallengeHeaders(challengeState);
    };

    const data = reset
      ? {
          ...value,
          forgotPassword: true,
        }
      : value;

    const headers = await startLoginFlow();
    return api.sendLoginCode(data, headers);
  };

  const onPasswordLoginFormSubmit = (password: string): void => {
    setPassword(password);
    setCode('');

    loginUser({
      password,
    });
  };

  const onCodeLoginFormSubmit = (code: string): void => {
    analytics.trackEvent('code_login_popup_confirm');
    setCode(code);
    setPassword('');
    loginUser({ code });
  };

  const onPasswordFormChange = (): void => {
    setPasswordError('');
    setCodeError('');
  };

  const { onFormChange } = props;

  const onSigninFormChange = useCallback(
    (values: ComplexLoginFormValues): void => {
      if (onFormChange) {
        onFormChange(values);
      }
      if (values.phone) setPhone(values.phone);
      if (values.email) setEmail(values.email);

      setInputErrors({
        phone: null,
        email: null,
      });
    },
    [onFormChange]
  );

  const onStepChange = (formStep: string): void => setFormStep(formStep);

  const loginUser = (options?: Partial<UserCredentials>): void => {
    const credentials = {
      ...getCredentials(),
      ...options,
    };
    if (isSigningLoading) {
      return;
    }

    setSigningLoading(true);
    analytics.global.loginSubmit();

    const startLoginFlow = async (): Promise<FrictionHeaders | void> => {
      if (!config.OLX_FRICTION_FLOW_ENABLED) return;

      const username = String(credentials.email || credentials.phone);

      const challengeState = await getFrictionInit('login', username, renderChallengeElement);
      return getFrictionChallengeHeaders(challengeState);
    };

    startLoginFlow()
      .then((headers) => api.login(credentials, headers, true))
      .then(({ data }) => {
        const userVerified = data.emailVerified || data.phoneVerified;

        localStorage.setItem(LANDING_APP_BANNER_KEY, 1);
        analytics.global.loginSuccess(data.userId, false, () => props.onSubmit(data.userId, false, userVerified));
      })
      .catch((error) => {
        analytics.global.loginError();
        const errorMessage = getResponseError(error);
        setPasswordError(errorMessage);
        setCodeError(errorMessage);
      })
      .finally(() => setSigningLoading(false));
  };

  const signUpUser = async (userCredentials: UserCredentials): Promise<void> => {
    analytics.user.serviceRequestSelectRegister();
    analytics.user.userRegistrationSubmit();

    const startRegisterFlow = async (): Promise<FrictionHeaders | void> => {
      if (!config.OLX_FRICTION_FLOW_ENABLED) return Promise.resolve();

      const username = String(userCredentials.email || userCredentials.phone);
      const challengeState = await getFrictionInit('register', username, renderChallengeElement);
      return getFrictionChallengeHeaders(challengeState);
    };

    const headers = await startRegisterFlow();

    const res = await registerCustomer({
      variables: {
        input: {
          name,
          ...userCredentials,
          subscribeToMarketing: Boolean(userCredentials.subscribeToMarketing),
        },
      },
      context: {
        headers: headers,
      },
    });

    if (res.data) {
      const { registerClient } = res.data;
      const registerType = userCredentials.phone ? 'sms' : 'email';
      auth.storeCredentials({
        ...registerClient,
      });
      analytics.user.registrationSuccess(() => props.onSubmit(registerClient.userId, true, false, registerType));
    }
  };

  const renderChallengeElement = (element?: HTMLElement) => {
    setChallengeElement(element ? <JSXElementFromHTMLElement htmlElement={element} /> : null);
  };

  const renderSigninForm = () => {
    const { isWizard } = props;

    return (
      <SignInFormStep isWizard={isWizard} showPhoneMessage={isWizard && isPhoneStep()}>
        <ComplexLoginForm
          defaultValues={props.values}
          inputErrors={inputErrors}
          isLoading={isCheckingEmailLoading}
          submitForm={checkAccountUsage}
          onBack={onBack}
          onStepChange={onStepChange}
          onFormChange={onSigninFormChange}
          formStep={formStep}
          isWizard={isWizard}
          challengeElement={challengeElement}
        />
      </SignInFormStep>
    );
  };

  const renderLoginForm = () => {
    const actions = <ComplexLoginButtons isWizard={props.isWizard} onBack={onBack} isLoading={isSigningLoading} />;

    return hasPassword ? (
      <PasswordLoginForm
        isWizard={props.isWizard}
        name={name}
        error={passwordError}
        loading={isSigningLoading}
        onSubmit={onPasswordLoginFormSubmit}
        onChange={onPasswordFormChange}
        isResetPasswordSending={isResetPasswordSending}
        isResetPasswordSent={isResetPasswordSent}
        challengeElement={challengeElement}
        actions={actions}
        onResetClick={() => sendResetPass()}
      />
    ) : (
      <CodeLoginForm
        isWizard={props.isWizard}
        name={name}
        error={codeError}
        loading={isSigningLoading}
        phone={phone}
        email={email}
        onSubmit={onCodeLoginFormSubmit}
        isResetPasswordSending={isResetPasswordSending}
        isResetPasswordSent={isResetPasswordSent}
        challengeElement={challengeElement}
        actions={actions}
        onResetClick={() => sendResetPass(true)}
      />
    );
  };

  return isAccountRegistered ? renderLoginForm() : renderSigninForm();
};

export default ComplexLogin;
