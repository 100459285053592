import config from 'config/config';

import routes from '../routeTranslator';
import { getFingerPrintHash } from './fingerprint';
import { getFetchHeaders } from './headers';
import { PayByCard, PayByLink, PwfPaymentMethod, MultiUseCardToken } from './types';
import { responseToJson } from './utils';

export type PwfOrderSuccessResponse = {
  orderId: number;
  redirectUri: string;
  status: {
    statusCode:
      | 'WARNING_CONTINUE_CVV'
      | 'WARNING_CONTINUE_3DS'
      | 'PENDING'
      | 'COMPLETED'
      | 'SUCCESS'
      | 'CANCELED'
      | 'REJECTED'
      | 'WAITING_FOR_CONFIRMATION'
      | 'REFUNDED';
  };
};

export type PwfOrderStatusResponse = {
  status: {
    statusCode: 'PENDING' | 'COMPLETED' | 'CANCELED' | 'REJECTED' | 'WAITING_FOR_CONFIRMATION' | 'REFUNDED';
  };
};

type PwfOrderDetails = {
  serviceRequestConversationId: string;
  customerEmail: string;
  price: number;
  webViewMode?: boolean;
};

export type PwfCardOrderPayload = PwfOrderDetails & PayByCard;
export type PwfPblOrderPayload = PwfOrderDetails & PayByLink;

export const placeOrder = async (
  payload: PwfCardOrderPayload | PwfPblOrderPayload
): Promise<PwfOrderSuccessResponse> => {
  const url = `${config.API_URL}${routes.get('api::pwf.placeOrder')}`;

  return fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      ...payload,
      deviceFingerprint: await getFingerPrintHash(),
    }),
    credentials: 'include',
    headers: await getFetchHeaders(),
  }).then<PwfOrderSuccessResponse>(responseToJson);
};

export const checkOrderStatus = async (orderId: string) => {
  const url = `${config.API_URL}${routes.get('api::pwf.orderStatus', { id: orderId })}`;
  return fetch(url, {
    credentials: 'include',
    headers: await getFetchHeaders(),
  }).then<PwfOrderStatusResponse>(responseToJson);
};

export const paymentMethods = async () => {
  const url = `${config.API_URL}${routes.get('api::pwf.paymentMethods')}`;
  return fetch(url, {
    credentials: 'include',
    headers: await getFetchHeaders(),
  }).then<PwfPaymentMethod[]>(responseToJson);
};

export const cardTokens = async () => {
  const url = `${config.API_URL}${routes.get('api::pwf.cardTokens')}`;
  return fetch(url, {
    credentials: 'include',
    headers: await getFetchHeaders(),
  }).then<MultiUseCardToken[]>(responseToJson);
};

export const deleteCardToken = async (token: string) => {
  const url = `${config.API_URL}${routes.get('api::pwf.deleteCardToken', { token })}`;
  return fetch(url, {
    method: 'DElETE',
    credentials: 'include',
    headers: await getFetchHeaders(),
  }).then(responseToJson);
};
