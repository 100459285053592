import { t } from '@lingui/macro';

import { TGraphqlResponseError } from 'common/helpers/commonTypes';

import { captureException } from '../errorLogger/sentry';

export const responseToJson = async <T>(response: Response): Promise<T> => {
  const json = response.json();
  if (!response.ok && response.status >= 400) {
    const data = await json;
    let error = new Error(t`Coś poszło nie tak...Spróbuj ponownie`);

    if (data.errors && data.errors[0] && data.errors[0].message) {
      error = new Error(data.errors[0].message);
    }

    captureException(error, data);

    return Promise.reject(error);
  }
  return json as Promise<T>;
};

export const getResponseError = (error: Error | { response?: TGraphqlResponseError }) => {
  console.error(error);

  if (error instanceof Error) {
    return error.message;
  }

  if (
    error.response &&
    error.response.data &&
    Array.isArray(error.response.data.errors) &&
    error.response.data.errors[0] &&
    error.response.data.errors[0].message
  ) {
    return error.response.data.errors[0].message;
  }

  return t`Coś poszło nie tak...Spróbuj ponownie`;
};
