import React from 'react';
import loadable from '@loadable/component';

/* @ts-expect-error type mismatch */
const Placeholder = ({ error }) => {
  if (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return <p>Cannot load external review page...</p>;
  }

  return <div />;
};

export default loadable(() => import('./ExternalReview'), {
  fallback: Placeholder({
    /* @ts-expect-error type mismatch */
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});
