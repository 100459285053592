import React from 'react';
import ReactDOM from 'react-dom';
import { loadableReady } from '@loadable/component';

import App from './App';

// TODO: fix the problem GS-9213
// eslint-disable-next-line ssr-friendly/no-dom-globals-in-module-scope
const rootElement = document.getElementById('root');
if (rootElement?.hasChildNodes()) {
  loadableReady(() => ReactDOM.hydrate(<App />, rootElement));
} else {
  rootElement && ReactDOM.render(<App />, rootElement);
}
