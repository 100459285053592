/**
 * (Browser only) window's hostname
 * @returns string
 */
export const getHostname = () => window.location.hostname;

/**
 * Parses provided URL and returns hostname
 * @param url string or any valid URL like - `location.href`
 * @returns string
 */
export const extractHostnameFromUrl = (url: string) => {
  return new URL(url).hostname;
};
