import { Trans } from '@lingui/macro';
import React, { useState, useEffect, FC } from 'react';

import { isMobileWebView } from 'common/helpers/helpers';
import { Button } from 'uikit/Button';
import { TextP1, TextDisplay } from 'uikit/Text';
import analytics from 'utils/analytics/analytics';
import localStorage from 'utils/localStorage/localStorage';

import './LandingAppBanner.scss';
import chromeIcon from './img/chrome.png';
import safariIcon from './img/safari.png';
import storeIcon from './img/store.png';

type Props = {
  browserDetector: {
    isChrome: boolean;
    isSafari: boolean;
  };
};

export const LANDING_APP_BANNER_KEY = 'showLandingAppBanner';

const LandingAppBanner: FC<Props> = ({ browserDetector }) => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (localStorage.getItem(LANDING_APP_BANNER_KEY)) {
      analytics.trackEvent('download_user_app_popup_show');
      setOpen(true);
    }
  }, []);

  const closeBanner = (touchPointButton: string) => {
    localStorage.removeItem(LANDING_APP_BANNER_KEY);
    analytics.trackEvent('download_user_app_popup_click', { touch_point_button: touchPointButton });
    setOpen(false);
  };

  if (!isOpen || isMobileWebView()) {
    return null;
  }

  return (
    <div className="landingAppBanner">
      <div
        className="landingAppBanner__overlay"
        onClick={() => closeBanner('use_browser_overlay')}
        data-testid="landingAppBannerOverlay"
      />
      <div className="landingAppBanner__content">
        <div className="landingAppBanner__header">
          <TextDisplay bold>
            <Trans>Zobacz Fixly w</Trans>...
          </TextDisplay>
        </div>
        <div className="landingAppBanner__row">
          <div className="landingAppBanner__info">
            <img src={storeIcon} width="40" height="40" className="landingAppBanner__img" alt="" />
            <TextP1 bold>
              {' '}
              <Trans>Aplikacja Fixly</Trans>{' '}
            </TextP1>
          </div>
          <Button
            className="landingAppBanner__button"
            kind="cta"
            as="a"
            target="_blank"
            href="https://app.fixly.pl/after-sms-not"
            onClick={() => closeBanner('download_app')}
          >
            <Trans>Otwórz</Trans>
          </Button>
        </div>
        <div className="landingAppBanner__row">
          {browserDetector.isChrome && (
            <div className="landingAppBanner__info">
              <img src={chromeIcon} width="40" height="40" className="landingAppBanner__img" alt="" />
              <TextP1 bold>Chrome</TextP1>
            </div>
          )}

          {browserDetector.isSafari && (
            <div className="landingAppBanner__info">
              <img src={safariIcon} width="40" height="40" className="landingAppBanner__img" alt="" />
              <TextP1 bold>Safari</TextP1>
            </div>
          )}
          <Button className="landingAppBanner__button" kind="secondary" onClick={() => closeBanner('use_browser')}>
            <Trans>Dalej</Trans>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LandingAppBanner;
