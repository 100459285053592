import routes from 'utils/routeTranslator';
import api from 'utils/api';
import { withNoSSR } from 'Components/NoSSR';
import { useEffect } from 'react';

const LogoutComponent = () => {
  useEffect(() => {
    const asyncEffect = async () => {
      await api.logout();
      window.location.href = routes.get('front::index');
    };
    asyncEffect();
  }, []);

  return null;
};

export default withNoSSR(LogoutComponent);
