/**
 * Retry async operation
 *
 * @param {Function} operation async function which performs the operation
 * @param {Number}   retries how many times operation will be retried
 * @param {Function} receives error caused by original operation and current try number
 *                   returns a promise with boolean value to indicate wether new try is needed
 *                   or to throw error up
 */

export type asyncRetry$Config = {
  times?: number;
  errorHandler?: (e: Error, currentTry: number) => Promise<boolean>;
};

const defaultConfig: asyncRetry$Config = {
  times: 3,
};

async function asyncRetry<T = any>(
  operation: () => Promise<T>,
  config: asyncRetry$Config = asyncRetry.defaultConfig,
  iteration = 1
): Promise<T> {
  const { times = 3, errorHandler } = config;

  // On the last attempt -> run without try/catch
  if (times <= 0) {
    return operation();
  }

  try {
    return await operation();
  } catch (e) {
    const nextConfig = { ...config, times: times - 1 };
    const nextTry = () => asyncRetry(operation, nextConfig, iteration + 1);

    // If there is no error handler -> perform next try
    if (!errorHandler) {
      return nextTry();
    }

    // Await for error handler to do it's job
    /* @ts-expect-error type mismatch */
    const errorHandlerResult = await errorHandler(e, iteration);

    // If error handler returned `false` throw error up;
    if (!errorHandlerResult) {
      throw e;
    }

    // perform next try if error handler decided to
    return nextTry();
  }
}

asyncRetry.defaultConfig = defaultConfig;

export default asyncRetry;
