import { Trans } from '@lingui/macro';
import React from 'react';

import Coupons from 'Components/Coupons/Coupons';
import NoSSR from 'Components/NoSSR';
import PageView from 'common/PageView/PageView';
import { Heading4 } from 'uikit/Heading';
import { TextP3 } from 'uikit/Text';

import './CouponsPage.scss';

const CouponsPage = () => {
  return (
    <div className="CouponsPage">
      <NoSSR>
        <PageView pageLabel="coupons_page_mobile" />
        <Heading4 className="CouponsPage__title">
          <Trans>Skorzystaj ze zniżek i promocji od naszych partnerów</Trans>
        </Heading4>
        <TextP3 className="CouponsPage__subTitle">
          <Trans>Poniżej znajdziesz dodatkowe oferty od naszych partnerów.</Trans>
        </TextP3>
        <Coupons />
      </NoSSR>
    </div>
  );
};

export default CouponsPage;
