import { FrictionTypes } from '@olx-friction/friction-service-sdk-js/lib/types';

import config from 'config/config';
import auth from '../auth/auth';

/**
 * In order to enable requests to "beta" backend, run this in dev console:
 * window.localStorage.setItem("FEATURE_FORCE_DEPLOYMENT", "beta")
 * In order to enable requests to "main" backend, run this in dev console:
 * window.localStorage.setItem("FEATURE_FORCE_DEPLOYMENT", "main")
 */
function getCanaryHeader() {
  if (typeof window !== 'undefined') {
    if (window.localStorage.getItem('FEATURE_FORCE_DEPLOYMENT') === 'beta') {
      return {
        'x-canary-version': 'beta',
      };
    } else if (window.localStorage.getItem('FEATURE_FORCE_DEPLOYMENT') === 'main') {
      return {
        'x-canary-version': 'main',
      };
    }
  }
}

export const apiHeaders = {
  // TODO: fix the problem GS-9213
  // eslint-disable-next-line ssr-friendly/no-dom-globals-in-module-scope
  version: typeof window !== 'undefined' && window.FIX ? window.FIX.release : '',
  ...getCanaryHeader(),
  'api-version': config.API_VERSION,
};

export const getFrictionChallengeHeaders = (state: FrictionTypes.ChallengeReturnValue) => {
  if ('failure' in state) {
    const { failure } = state;
    if (failure.error) {
      throw failure.error;
    } else {
      throw new Error(failure.message || failure.reason);
    }
  }

  return {
    'x-friction-token': state.token,
  };
};

export type FrictionHeaders = ReturnType<typeof getFrictionChallengeHeaders>;

export const getFetchHeaders = async (headers: HeadersInit = {}): Promise<HeadersInit> => {
  const authorization = await auth.getAccessTokenWithRefresh();

  return {
    ...headers,
    ...(authorization ? { authorization } : null),
    ...apiHeaders,
  };
};
