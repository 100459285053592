import classnames from 'classnames';
import React from 'react';

import './TextDisplay.scss';

type TextDisplay$Props<T extends keyof JSX.IntrinsicElements> = React.ComponentProps<T> & {
  children: React.ReactNode;
  className?: string;
  bold?: boolean;
  as?: T;
};

export default function TextDisplay<T extends keyof JSX.IntrinsicElements = 'p'>({
  className,
  children,
  bold,
  as,
  ...rest
}: TextDisplay$Props<T>) {
  const CN = classnames('textDisplay', className, {
    'textDisplay_bold': bold,
  });

  return React.createElement(as || 'p', { className: CN, ...rest }, children);
}
