import { client } from 'utils/apolloConnector/apolloConnector';

import { updateUserSettingsMutation } from '../SettingsLayout/SettingsLayoutHelpers';

export const REQUEST_PASSWORD_HASH = 'hasloggedin';

export enum LoginStepEnum {
  login_step = 'login_step',
  password_step = 'password_step',
  code_step = 'code_step',
  login_link_step = 'login_link_step',
  set_password_step = 'set_password_step',
}

export const updateUserSettings = (newPassword: string) => {
  return client.mutate<{ updateUserSettings: boolean }>({
    mutation: updateUserSettingsMutation,
    variables: {
      security: {
        password: '',
        newPassword: newPassword,
      },
    },
  });
};
