import React, { FC, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { CurrentUserConsumer, TCurrentUserState } from 'Components/CurrentUser/CurrentUser';
import { isRoute } from 'common/helpers/helpers';
import api from 'utils/api';

import './Footer.scss';
import ProviderFooter from './ProviderFooter';
import UserFooter from './UserFooter';

const Footer: FC = () => {
  const { pathname } = useLocation();
  const [isNewTosBadgeVisible, setNewTosBadgeVisible] = useState<boolean>(false);

  useEffect(() => {
    const asyncFetch = async () => {
      const response = await api.getTosBadgeStatus();
      if (response && response.ok) {
        const result = await response.json();
        setNewTosBadgeVisible(result);
      }
    };
    asyncFetch();
  }, []);

  const excludeFooterRoutes: string[] = [
    'provider::onboarding',
    'payments::any',
    'front::user.rate.provider',
    'front::contest',
    'provider::onboarding.pwf',
  ];

  if (excludeFooterRoutes.map((route) => isRoute(pathname, route)).filter(Boolean).length) {
    return null;
  }

  return (
    <CurrentUserConsumer>
      {(context: TCurrentUserState | undefined) => {
        const isProvider = Boolean(context?.currentUser?.isProvider);
        const isProviderLayout =
          isRoute(pathname, 'front::provider.register') ||
          isRoute(pathname, 'front::provider.register.l2') ||
          isRoute(pathname, 'front::provider.register.l3');
        const isReportLink = isRoute(pathname, 'front::index');

        return isProvider || isProviderLayout ? (
          <ProviderFooter isNewTosBadgeVisible={isNewTosBadgeVisible} />
        ) : (
          <UserFooter isNewTosBadgeVisible={isNewTosBadgeVisible} isReportLink={isReportLink} />
        );
      }}
    </CurrentUserConsumer>
  );
};

export default Footer;
