import { Trans } from '@lingui/macro';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { NoSSR } from 'Components/NoSSR';
import Modal from 'common/Modal/Modal';
import parseQueryString from 'common/helpers/parseQueryString';
import { useQueryParams } from 'common/hooks';
import { Button } from 'uikit/Button';
import { TextDisplay, TextP3 } from 'uikit/Text';
import api from 'utils/api';
import { useNotifyAskForReviewMutation } from 'utils/graphql/reviewLink';
import routes from 'utils/routeTranslator';

import './UserReviewLinkHandler.scss';

type TLoginMethod = { channel: 'sms' | 'email'; value: string };

const UserReviewLinkHandler: FC = () => {
  const history = useHistory();
  const [loginMethod, setLoginMethod] = useState<TLoginMethod | null>(null);
  const [isLoginSent, setIsLoginSent] = useState<boolean>(false);
  const [submitLogin] = useNotifyAskForReviewMutation();
  const queryParams = useQueryParams();

  const hash = queryParams.get('hash') ?? undefined;
  const touch_point_button = queryParams.get('touch_point_button');

  useEffect(() => {
    const asyncEffect = async () => {
      const { hash } = parseQueryString(window.location.search);
      if (!hash) {
        history.push(routes.get('front::index'));
      }
      try {
        const { data } = await api.getJobDoneData(hash);

        if ('notification' in data) {
          setLoginMethod(data.notification);
        } else if ('redirectUrl' in data) {
          window.location.href = `${data.redirectUrl}&touch_point_button=${touch_point_button}`;
        }
      } catch (err) {}
    };
    asyncEffect();
  }, [history, touch_point_button]);

  const handleLoginSubmit = async () => {
    await submitLogin({ variables: { hash } });
    setIsLoginSent(true);
  };

  if (!loginMethod) return null;

  return (
    <Modal
      isOpen={true}
      handleClose={() => history.push(routes.get('front::index'))}
      className="userReviewLinkHandler__modal"
    >
      {!isLoginSent ? (
        <>
          <TextDisplay bold className="userReviewLinkHandler__modalHeader">
            <Trans>Zaloguj się</Trans>
          </TextDisplay>
          {loginMethod.channel === 'email' && (
            <TextP3>
              <Trans id="reviewLink.emailHintPrefix">
                Nie jesteś obecnie zalogowany na koncie zarejestrowanym na adres
              </Trans>
            </TextP3>
          )}
          {loginMethod.channel === 'sms' && (
            <TextP3>
              <Trans id="reviewLink.smsHintPrefix">
                Nie jesteś obecnie zalogowany na koncie zarejestrowanym na numer telefonu
              </Trans>
            </TextP3>
          )}
          <TextP3 bold>{loginMethod.value}</TextP3>
          <TextP3>
            <Trans>Aby się zalogować poproś o link do logowania.</Trans>
          </TextP3>
          <Button className="userReviewLinkHandler__modalButton" width="wide" onClick={handleLoginSubmit}>
            <Trans>Wyślij link</Trans>
          </Button>
        </>
      ) : (
        <>
          <TextDisplay bold className="userReviewLinkHandler__modalHeader">
            <Trans>Link wysłany</Trans>
          </TextDisplay>
          {loginMethod.channel === 'email' && (
            <TextP3>
              <Trans>Link do logowania został wysłany na Twój adres</Trans>
            </TextP3>
          )}
          {loginMethod.channel === 'sms' && (
            <TextP3>
              <Trans>Link do logowania został wysłany na Twój numer telefonu</Trans>
            </TextP3>
          )}
          <TextP3 bold>{loginMethod.value}</TextP3>
        </>
      )}
    </Modal>
  );
};

const NoSsrComponent: FC = () => (
  <NoSSR>
    <UserReviewLinkHandler />
  </NoSSR>
);

export default NoSsrComponent;
