/**
 * Receives `?par1=val1&par2=val2`
 * Returns `{ par1: "val1", par2: "val2" }`
 */
// TODO: Use native URL object to parse query strings and add tests to this file
export default function parseQueryString(qs: string): { [key: string]: any } {
  const query = {};
  const pairs = (qs[0] === '?' ? qs.substr(1) : qs).split('&');

  for (let i = 0; i < pairs.length; i += 1) {
    const [key, value] = pairs[i].split('=');
    if (key) {
      /* @ts-expect-error type mismatch */
      query[decodeURIComponent(key)] = decodeURIComponent(value || '');
    }
  }
  return query;
}
