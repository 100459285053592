import { Event, Response, Status, Transport } from '@sentry/types';
import { getGlobalObject, supportsReferrerPolicy, SyncPromise } from '@sentry/utils';

export default class CustomFetchTransport implements Transport {
  public sendEvent(event: Event): PromiseLike<Response> {
    return new SyncPromise<Response>((resolve, reject) => {
      getGlobalObject<Window>()
        .fetch('/sentry', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(event),
          referrerPolicy: (supportsReferrerPolicy() ? 'origin' : '') as ReferrerPolicy,
        })
        .then((response) => {
          const status = Status.fromHttpCode(response.status);
          if (status === Status.Success) {
            resolve({ status });
          } else {
            reject(response);
          }
        })
        .catch(reject);
    });
  }

  public close(timeout?: number): PromiseLike<boolean> {
    return Promise.resolve(true);
  }
}
