import React, { ReactNode, HTMLProps } from 'react';
import classnames from 'classnames';

import './Label.scss';

type Label$Props = HTMLProps<HTMLLabelElement> & {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
};

export default function Label(props: Label$Props) {
  const { children, className, disabled, ...rest } = props;

  const labelCN = classnames(
    {
      'label': true,
      'label_disabled': disabled,
    },
    className
  );

  return (
    <label className={labelCN} {...rest}>
      {children}
    </label>
  );
}
