import React, { useCallback, useContext, useEffect, useState } from 'react';

import localStorage from 'utils/localStorage/localStorage';

const LOCAL_STORAGE_SETTINGS_KEY = 'fixlyLocalApplicationSettings';

export interface TLocalSettings {
  disableSounds?: boolean;
}

interface TContextProps {
  settings: TLocalSettings;
  setSettings: React.Dispatch<TLocalSettings>;
}

const defaultSettings = {
  disableSounds: false,
};

const getSavedSettings = (): TLocalSettings => {
  const localStorageData = localStorage.getItem(LOCAL_STORAGE_SETTINGS_KEY);
  try {
    return localStorageData ? JSON.parse(localStorageData) : defaultSettings;
  } catch (err) {
    console.error("can't get saved settings");
    return defaultSettings;
  }
};

const LocalSettingsContext = React.createContext<TContextProps>({
  settings: {},
  setSettings: () => {
    console.error('LocalSettingsProvider is missing');
  },
});

export const LocalSettingsProvider: React.FC = ({ children }) => {
  const [settings, setSettings] = useState<TLocalSettings>(getSavedSettings);

  const handleNewSettings = useCallback((newSettings: TLocalSettings) => {
    setSettings((prevSettings) => {
      const mergedSettings = { ...prevSettings, ...newSettings };
      localStorage.setItem(LOCAL_STORAGE_SETTINGS_KEY, JSON.stringify(mergedSettings));
      return mergedSettings;
    });
  }, []);

  const listener = useCallback(() => {
    handleNewSettings(getSavedSettings());
  }, [handleNewSettings]);

  useEffect(() => {
    window.addEventListener('storage', listener);
    return () => {
      window.removeEventListener('storage', listener);
    };
  }, [listener]);

  return (
    <LocalSettingsContext.Provider value={{ settings, setSettings: handleNewSettings }}>
      {children}
    </LocalSettingsContext.Provider>
  );
};

export const useLocalSettings = (): [TLocalSettings, React.Dispatch<TLocalSettings>] => {
  const { settings, setSettings } = useContext(LocalSettingsContext);
  return [settings, setSettings];
};

export const withLocalSettings =
  <P extends object>(Component: React.ComponentType<P>) =>
  (props: P) => (
    <LocalSettingsContext.Consumer>{(context) => <Component {...props} {...context} />}</LocalSettingsContext.Consumer>
  );
