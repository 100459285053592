import { t, Trans } from '@lingui/macro';
import { useFormik } from 'formik';
import React, { FC, memo, useEffect } from 'react';
import * as Yup from 'yup';
import config from 'config/config';
import { Button } from 'uikit/Button';
import FormRow from 'uikit/FormRow/FormRow';
import Input from 'uikit/Input/Input';

type LoginStepFormProps = {
  challengeElement: JSX.Element | null;
  mod?: string;
  loading?: boolean;
  error?: string;
  onSubmit: (value: string) => void;
};

const initialValues = {
  login: '',
};

const validationSchema = Yup.object().shape({
  login: Yup.string().required(t`Nieprawidłowy adres e-mail lub numer telefonu`),
});

const LoginStepForm: FC<LoginStepFormProps> = ({ error, onSubmit, mod, challengeElement, loading }) => {
  const { values, touched, errors, handleChange, setFieldError, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      onSubmit(values.login);
    },
  });

  useEffect(() => {
    if (!error) return;
    (async () => {
      await setFieldError('login', error);
    })();
  }, [error]);

  const id = `login__email_${mod || ''}`;

  return (
    <form className="login__form" onSubmit={handleSubmit}>
      <FormRow>
        <Input
          id={id}
          name="login"
          type="text"
          value={values.login}
          error={touched.login && errors.login}
          onChange={handleChange}
          placeholder={t`E-mail lub numer telefonu`}
          autoCorrect="off"
          autoCapitalize="off"
          tabIndex={0}
        />
      </FormRow>

      {challengeElement}

      <Button type="submit" kind="cta" width="full" name="submitLogin" className="login__submit" isLoading={loading}>
        <Trans>Dalej</Trans>
      </Button>

      <p className="login__tos">
        <Trans>
          Zalogowanie oznacza akceptację{' '}
          <a href={config.HELPDESK_TOS_URL} target="_blank" rel="noreferrer">
            Regulaminu
          </a>{' '}
          serwisu Fixly w aktualnym brzmieniu. Jeśli nie akceptujesz warunków zmienionego Regulaminu serwisu Fixly,
          wyślij oświadczenie o rozwiązaniu Umowy w trybie przewidzianym w Regulaminie.
        </Trans>
      </p>
    </form>
  );
};

export default memo(LoginStepForm);
