import { t, Trans } from '@lingui/macro';
import classnames from 'classnames';
import { useFormik } from 'formik';
import React, { FC, useEffect } from 'react';
import * as Yup from 'yup';

import FormRow from 'uikit/FormRow/FormRow';
import Input from 'uikit/Input/Input';
import analytics from 'utils/analytics/analytics';

type SetPasswordStepFormProps = {
  mod?: string;
  loading?: boolean;
  error?: string;
  onSubmit: (value: string) => void;
  onDismiss: () => void;
};

const SetPasswordStepForm: FC<SetPasswordStepFormProps> = (props) => {
  const { values, errors, touched, setFieldError, handleChange, handleSubmit } = useFormik({
    initialValues: {
      new_password: '',
    },
    validationSchema: Yup.object().shape({
      new_password: Yup.string()
        .required(t`Wpisz hasło`)
        .min(6, t`Hasło musi mieć co najmniej 6 znaków - dzięki temu będzie bezpieczniejsze`),
    }),
    onSubmit: () => {
      props.onSubmit(values.new_password);
    },
  });

  useEffect(() => {
    setFieldError('new_password', props.error);
  }, [props.error, setFieldError]);

  const submitButtonCN = classnames('login__submitSetPassword', {
    'login__submitSetPassword_loading': props.loading,
  });

  return (
    <form onSubmit={handleSubmit} className="login__form login__form_setPassword">
      <div className="login__form_setPasswordSubtitle">
        <Trans>Dzięki temu następnym razem szybciej się zalogujesz</Trans>
      </div>
      <div className="login__setPasswordInputWrap">
        <FormRow>
          <Input
            id={`login__password_${props.mod || ''}`}
            name="new_password"
            type="password"
            value={values.new_password}
            error={touched.new_password && errors.new_password}
            onChange={handleChange}
            onFocus={() => analytics.trackEvent('create_password_popup_click')}
            onInput={() => analytics.trackEvent('create_password_popup_type')}
            className="login__setPasswordInput"
            placeholder={t`Hasło`}
          />
        </FormRow>

        <button
          id={`submitSetPassword${props.mod || ''}`}
          type="submit"
          name="submitSetPassword"
          className={submitButtonCN}
        >
          <Trans>Zapisz hasło</Trans>
        </button>
      </div>

      <div className="login__form_setPasswordHint">
        <Trans>Hasło musi mieć co najmniej 6 znaków</Trans>
      </div>

      <button type="button" onClick={props.onDismiss} className="login__cancelSetPassword">
        <Trans>Nie, dziękuję</Trans>
      </button>
    </form>
  );
};

export default SetPasswordStepForm;
