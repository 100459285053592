import { Options } from '@sentry/types/dist/options';
import CustomFetchTransport from './fetch';

type BrowserOptions = {
  blacklistUrls?: RegExp[];
};

interface SentryClient {
  init(options: Options & BrowserOptions): void;
  captureException(err: Error, extra?: any): void;
  addBreadcrumb(data: any): void;
}

declare global {
  interface Window {
    Sentry?: SentryClient;
    SentryInitialized?: boolean;
  }
}

function init(): SentryClient | null {
  // SSR mode
  if (typeof window === 'undefined') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const sentrySsr: { capture(err: Error, extra?: any): void } = require('../../ssr/sentry');

    return {
      init() {
        // void
      },
      captureException(err, data) {
        sentrySsr.capture(err, { data });
      },
      addBreadcrumb() {
        // void
      },
    };
  }

  // Browser mode
  if (!window.Sentry) return null;
  if (!window.FIX) return null;
  if (typeof window.SentryInitialized === 'undefined') window.SentryInitialized = false;
  if (window.SentryInitialized) return window.Sentry;

  if (window.FIX.environment === 'development') return null;

  // noinspection JSUnusedGlobalSymbols
  window.Sentry.init({
    dsn: 'https://6dd5eec8efb04144b9af4526fc212911@sentry.infra.fixly.pl/7',
    release: window.FIX.release,
    environment: window.FIX.environment,
    maxBreadcrumbs: 5, // Reduce error report payload
    // whitelistUrls: [], // e.g.: cdn
    ignoreErrors: [
      // Generic error code from errors outside the security sandbox
      // You can delete this if using raven.js > 1.0, which ignores these automatically.
      'Script error.',

      // Custom
      'Non-Error promise rejection captured with value: undefined',
      'NetworkError when attempting to fetch resource',
      'SecurityError: SecurityError',
      'Network error: Unauthorized',
      'Failed to register a ServiceWorker',
      'client disconnecting',
      "Can't find variable: fbq",
      'Cannot unsubscribe',
    ],
    blacklistUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Browser extensions
      /extensions\//i,
      /^chrome:\/\//i,
    ],
    debug: false,
    sampleRate: 0.05, // Only report 5% of all errors

    beforeSend: (event) => {
      // eslint-disable-next-line no-param-reassign
      if (!event.user) event.user = {};
      // eslint-disable-next-line no-param-reassign
      event.user.id = (window.FIX.current_user || 'guest').toString();

      return event;
    },

    beforeBreadcrumb: (bc, hint) => {
      if (bc.category === 'console') return null;
      if (bc.category === 'sentry') return null;
      if (bc.category === 'exception') return null;

      // eslint-disable-next-line no-param-reassign
      if (bc.type === 'http') bc.type = 'default';

      // @todo Do the same for XHR post calls
      if (bc.category === 'fetch' && bc.data?.method === 'POST') {
        if (hint && hint.input && hint.input[1] && hint.input[1].body && hint.input[1].body.substring) {
          // eslint-disable-next-line no-param-reassign
          bc.data.request_body = hint.input[1].body.substring(0, 256);
        }
      }

      return bc;
    },

    transport: CustomFetchTransport,
  });

  window.SentryInitialized = true;
  console.info('[Sentry] initialized');

  return window.Sentry;
}

export function captureException(error: Error, extra: any = {}): void {
  console.error(error, extra);

  const sentry = init();
  if (sentry) {
    sentry.captureException(error, { extra });
  }
}

export function addBreadcrumb(data: any): void {
  const sentry = init();
  if (sentry) {
    sentry.addBreadcrumb(data);
  }
}

// Initialize sentry
init();
