import classnames from 'classnames';
import React, { ReactNode, HTMLProps } from 'react';

import './TextParagraph.scss';

type TextParagraph$Props = HTMLProps<HTMLElement> & {
  children: ReactNode;
  className?: string;
  bold?: boolean;
  dark?: boolean;
  htmlElement?: string;
};

export function TextP1({ className, bold, as = 'p', ...rest }: TextParagraph$Props) {
  const CN = classnames('textP1', className, {
    'textP1_bold': bold,
  });

  return React.createElement(as, { className: CN, ...rest });
}

export function TextP2({ className, bold, as = 'p', ...rest }: TextParagraph$Props) {
  const CN = classnames('textP2', className, {
    'textP2_bold': bold,
  });

  return React.createElement(as, { className: CN, ...rest });
}

export function TextP3({ className, bold, dark, as = 'p', ...rest }: TextParagraph$Props) {
  const CN = classnames('textP3', className, {
    'textP3_bold': bold,
    'textP3_dark': dark,
  });

  return React.createElement(as, { className: CN, ...rest });
}
