import React, { HTMLProps } from 'react';
import classnames from 'classnames';

import './CloseButton.scss';

type CloseButton$Props = {
  className?: string;
  children?: any;
  type?: 'button' | 'submit' | 'reset';
} & HTMLProps<HTMLButtonElement>;

export default function CloseButton(props: CloseButton$Props) {
  const { className, children, type = 'button', ...rest } = props;

  const buttonCN = classnames('closeButton', className);

  return (
    <button type={type} className={buttonCN} {...rest}>
      {children && children}
    </button>
  );
}
