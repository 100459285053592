import { t, Trans } from '@lingui/macro';
import classnames from 'classnames';
import { useFormik } from 'formik';
import React, { FC, ReactNode, useEffect } from 'react';

import { phoneNumberMask } from 'Components/Form/Form.helpers';
import { AtIcon } from 'common/icons/AtIcon';
import { SmsIcon } from 'common/icons/SmsIcon';
import config from 'config/config';
// eslint-disable-next-line max-len
import { ExternalReviewSignin$FormValues } from 'pages/ExternalReview/ExternalReviewSignin/ExternalReviewSignin.helpers';
import { Button } from 'uikit/Button';
import FormRow from 'uikit/FormRow/FormRow';
import Input from 'uikit/Input/Input';
import analytics from 'utils/analytics/analytics';

import IconSelect from '../Form/IconSelect/IconSelect';
import { ComplexLoginFormValues, getUserInfoValidationSchema, LoginType } from './ComplexLogin.helpers';
import ComplexLoginButtons from './ComplexLoginButtons';
import './ComplexLoginForm.scss';

type Props = {
  isLoading: boolean;
  isWizard?: boolean;
  isExternalReview?: boolean;
  defaultValues?: ComplexLoginFormValues;
  inputErrors: {
    phone: string | null;
    email?: string | null;
  };
  formStep: string | null;
  onStepChange: (value: string) => void;
  onFormChange?: (values: ComplexLoginFormValues) => void;
  submitForm: (values: ExternalReviewSignin$FormValues) => void;
  isExternalFormValid?: () => boolean;
  onInputFocus?: (inputName: string) => void;
  onBack?: () => void;
  challengeElement?: ReactNode;
};

const ComplexLoginForm: FC<Props> = (props) => {
  const getAnalyticsValueFromStepType = (step: string): string => step.replace('type_', '');

  const fireAnalyticsEvent = (type: string, value?: string) => {
    if (!props.isWizard) {
      return;
    }

    const eventValue = value || (props.formStep ? getAnalyticsValueFromStepType(props.formStep) : '');
    analytics.user.wizardSignin(`${eventValue}_${type}`);
  };

  const onFormInputBlur = (inputName: string): void => {
    if (inputName === 'phone' && !errors.phone) {
      analytics.user.serviceRequestPhoneValid();
    }

    if (inputName === 'email' && !errors.email) {
      analytics.user.serviceRequestEmailValid();
    }
  };

  const onInputFocus = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { name } = e.currentTarget;
    const analyticsType = name === 'name' ? 'name_text' : 'addr';
    fireAnalyticsEvent(analyticsType);

    if (props.onInputFocus) {
      props.onInputFocus(name);
    }
  };

  const onTypeChange = (e: React.SyntheticEvent<HTMLInputElement>): void => {
    const { value } = e.currentTarget;
    props.onStepChange(value);
    fireAnalyticsEvent('icon', getAnalyticsValueFromStepType(value));
  };

  const loginType =
    props.formStep === LoginType.Email ? 'email' : props.formStep === LoginType.SMS ? 'phone' : undefined;
  const { formStep, isWizard, isExternalReview, isLoading } = props;
  const isNameRequired = isWizard || isExternalReview;

  const { values, errors, touched, handleSubmit, handleChange, setErrors } = useFormik({
    initialValues: {
      email: '',
      phone: '',
      name: '',
      ...props.defaultValues,
    },
    validationSchema: getUserInfoValidationSchema(loginType, isNameRequired),
    onSubmit: () => {
      if (props.isExternalFormValid && !props.isExternalFormValid()) {
        return;
      }
      const credentials =
        props.formStep === LoginType.Email
          ? {
              email: values.email,
              name: values.name,
            }
          : {
              phone: values.phone,
              name: values.name,
            };
      props.submitForm(credentials);
    },
  });

  const { onFormChange, inputErrors } = props;

  useEffect(() => {
    if (onFormChange) onFormChange(values);
  }, [onFormChange, values]);

  useEffect(() => {
    if (inputErrors) {
      setErrors({
        email: inputErrors.email || undefined,
        phone: inputErrors.phone || undefined,
      });
    }
  }, [inputErrors, setErrors]);

  const isEmailType: boolean = formStep === LoginType.Email;
  const isSmsType: boolean = formStep === LoginType.SMS;

  const submitCN = classnames({
    complexLoginForm__submit: true,
    complexLoginForm__submit_wizard: isWizard,
  });
  const smsLabel = isExternalReview ? t`kod SMS` : t`SMSem`;
  const smsPlaceholder = isExternalReview ? t`Podaj swój numer telefonu` : '+48 XXX XXX XXX';
  const namePlaceholder = t`Jak masz na imię?`;
  const submitLabel = isExternalReview ? t`Wyślij sms z kodem` : t`Wyślij`;

  return (
    <form className="complexLoginForm__form" onSubmit={handleSubmit}>
      <div className="complexLoginForm__formContent">
        <div className="complexLoginForm__selectStep">
          <IconSelect
            isActive={isSmsType}
            value={LoginType.SMS}
            onChange={onTypeChange}
            label={smsLabel}
            subLabel={isWizard ? t`Zalecane` : undefined}
            isBig={isExternalReview}
          >
            <SmsIcon />
          </IconSelect>
          {!isExternalReview && (
            <IconSelect isActive={isEmailType} value={LoginType.Email} onChange={onTypeChange} label={t`E-mailem`}>
              <AtIcon />
            </IconSelect>
          )}
        </div>
        {(isEmailType || isSmsType) && (
          <div className="complexLoginForm__inputs">
            {isEmailType && (
              <FormRow>
                <Input
                  id="email"
                  type="email"
                  name="email"
                  value={values.email}
                  error={touched.email && errors.email}
                  className="complexLoginForm__input complexLoginForm__input_email"
                  wrapperClassName="complexLoginForm__inputWrap complexLoginForm__inputWrap_email"
                  placeholder={t`Adres e-mail`}
                  onChange={handleChange}
                  onFocus={onInputFocus}
                  onBlur={() => onFormInputBlur('email')}
                />
              </FormRow>
            )}

            {isSmsType && (
              <FormRow>
                <Input
                  id="phone"
                  name="phone"
                  type="tel"
                  value={values.phone}
                  error={touched.phone && errors.phone}
                  mask={phoneNumberMask}
                  className="complexLoginForm__input complexLoginForm__input_phone"
                  wrapperClassName="complexLoginForm__inputWrap complexLoginForm__inputWrap_phone"
                  placeholder={smsPlaceholder}
                  onChange={handleChange}
                  onFocus={onInputFocus}
                  onBlur={() => onFormInputBlur('phone')}
                />
              </FormRow>
            )}

            {isNameRequired && (
              <FormRow>
                <Input
                  id="name"
                  name="name"
                  value={values.name}
                  error={touched.name && errors.name}
                  placeholder={namePlaceholder}
                  autoCorrect="off"
                  onChange={handleChange}
                  onFocus={onInputFocus}
                />
              </FormRow>
            )}

            {props.challengeElement}

            <Button className={submitCN} type="submit" width="full" disabled={isLoading} isLoading={isLoading}>
              {submitLabel}
            </Button>

            <p className="complexLoginForm__tos">
              <Trans>
                Klikając przycisk kontynuowania, akceptuję{' '}
                <a href={config.HELPDESK_TOS_URL} target="_blank" rel="noreferrer">
                  Regulamin Fixly
                </a>
                .<br />
                Przyjmuję do wiadomości, że <b>Grupa OLX</b> sp. z o.o. wykorzystuje moje dane osobowe zgodnie z{' '}
                <a href={config.HELPDESK_PRIVACY_URL} target="_blank" rel="noreferrer">
                  Polityką prywatności
                </a>{' '}
                oraz{' '}
                <a href={config.HELPDESK_COOKIE_URL} target="_blank" rel="noreferrer">
                  Polityką dotyczącą plików cookie i podobnych technologii
                </a>
                .
              </Trans>
            </p>
          </div>
        )}
      </div>
      {isWizard && (
        <div className="complexLoginForm__wrap">
          <ComplexLoginButtons
            onBack={props.onBack}
            submitDisabled={formStep === ''}
            isLoading={isLoading}
            isWizard={isWizard}
          />
        </div>
      )}
    </form>
  );
};

export default ComplexLoginForm;
