import asyncRetry from './asyncRetry';

export function apiAvailabilityRetry<T = any>(operation: () => Promise<T>): Promise<T> {
  const delayFactor = 150; // ms

  const statusesToRetry = [502, 503];

  const errorHandler = async (e: Error, iteration: number): Promise<boolean> => {
    // @ts-ignore
    const { networkError } = e;

    if (!networkError || statusesToRetry.indexOf(networkError.statusCode) === -1) {
      // If status is not supported -> return false to stop retrying
      return false;
    }

    // Wait some exponential delay
    await new Promise((res) => setTimeout(res, delayFactor * (iteration ** 2 / 2)));
    // Return true for new try
    return true;
  };

  return asyncRetry(operation, { times: 3, errorHandler });
}
