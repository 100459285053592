import { extractHostnameFromUrl, getHostname } from './hostname';

const extractDomainFromHostname = (hostname: string) => {
  const numberOfDomainComponents = 2;
  return extractDomainFromHostnameByComponentPosition(hostname, numberOfDomainComponents);
};

const extractStagingDomainFromHostname = (hostname: string) => {
  return extractDomainFromHostnameByComponentPosition(hostname, 5);
};

const extractDomainFromHostnameByComponentPosition = (hostname: string, numberOfDomainComponents: number) =>
  hostname.split('.').splice(-numberOfDomainComponents, numberOfDomainComponents).join('.');

/**
 * (Browser only) Returns domain according to the [RFC6265 standard](https://datatracker.ietf.org/doc/html/rfc6265#section-4.1.2.3])
 * - it can be used directly to set scope of the cookie
 * @param isProduction boolean - default true
 * @returns string
 */
export const getDomain = (isProduction = true) => {
  const domain = isProduction
    ? extractDomainFromHostname(getHostname())
    : extractStagingDomainFromHostname(getHostname());
  return domain;
};

/**
 * * (Browser only) Returns domain according to the [RFC6265 standard](https://datatracker.ietf.org/doc/html/rfc6265#section-4.1.2.3])
 * - it can be used directly to set scope of the cookie
 * @param url string or any valid URL like `location.href`
 * @param isProduction boolean - default true
 * @returns string
 */
export const extractDomainFromUrl = (url: string, isProduction = true) => {
  const hostName = extractHostnameFromUrl(url);
  const domain = isProduction ? extractDomainFromHostname(hostName) : extractStagingDomainFromHostname(hostName);
  return domain;
};
