import classnames from 'classnames';
import React, { ReactNode, HTMLProps, forwardRef, CSSProperties } from 'react';
import MaskedInput from 'react-text-mask';

import Error from '../Error/Error';
import Label from '../Label/Label';
import './Input.scss';

interface Props extends Omit<HTMLProps<HTMLInputElement>, 'label'> {
  id?: string;
  label?: ReactNode;
  error?: ReactNode;
  className?: string;
  labelClassName?: string;
  wrapperClassName?: string;
  isBig?: boolean;
  mask?: (RegExp | string)[];
  iconUrl?: string;
}

const Input = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { id, label, error, className, labelClassName, isBig, wrapperClassName, mask, iconUrl, ...rest } = props;

  const inputCs = classnames(
    'input',
    {
      'input_error': error,
      'input_big': isBig,
      'input_iconic': iconUrl,
    },
    className
  );
  const wrapCs = classnames('input__wrap', wrapperClassName);

  let inputStyle: CSSProperties | undefined;
  if (iconUrl) {
    inputStyle = {
      backgroundImage: `url(${iconUrl})`,
    };
  }

  return (
    <div className={wrapCs}>
      {label && (
        <Label htmlFor={id} className={labelClassName}>
          {label}
        </Label>
      )}
      {/*@ts-expect-error type mismatch*/}
      {mask && <MaskedInput className={inputCs} style={inputStyle} id={id} mask={mask} {...rest} ref={ref} />}
      {!mask && <input className={inputCs} style={inputStyle} id={id} {...rest} ref={ref} />}
      {error && typeof error !== 'boolean' && <Error htmlFor={id}>{error}</Error>}
    </div>
  );
});

export default Input;
