import React from 'react';

export const AtIcon = () => (
  <svg width="42" height="42" xmlns="http://www.w3.org/2000/svg">
    <path
      className="svgIcon"
      d="M21 .167C9.5.167.167 9.5.167 21 .167 32.5 9.5 41.833 21 41.833h10.417v-4.166H21c-9.042 0-16.667-7.625-16.667-16.667 0-9.042 7.625-16.667 16.667-16.667 9.042 0 16.667 7.625 16.667 16.667v2.98c0 1.645-1.48 3.27-3.125 3.27-1.646 0-3.125-1.625-3.125-3.27V21c0-5.75-4.667-10.417-10.417-10.417S10.583 15.25 10.583 21 15.25 31.417 21 31.417c2.875 0 5.5-1.167 7.375-3.063 1.354 1.854 3.688 3.063 6.167 3.063 4.104 0 7.291-3.334 7.291-7.438V21C41.833 9.5 32.5.167 21 .167zm0 27.083A6.242 6.242 0 0 1 14.75 21 6.242 6.242 0 0 1 21 14.75 6.242 6.242 0 0 1 27.25 21 6.242 6.242 0 0 1 21 27.25z"
      fill="#0087D0"
      fillRule="nonzero"
    />
  </svg>
);

/* eslint max-len:0 */
