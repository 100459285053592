import { Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FC } from 'react';

import { Button } from 'uikit/Button';

import './ComplexLoginButtons.scss';

type Props = {
  isWizard?: boolean;
  isLoading: boolean;
  submitDisabled?: boolean;
  onBack?: () => void;
};

const ComplexLoginButtons: FC<Props> = (props) => {
  const { isLoading, isWizard, submitDisabled, onBack } = props;
  const CN = classnames('complexLoginButtons', {
    complexLoginButtons_wizard: isWizard,
  });
  return (
    <div className={CN}>
      <Button
        kind="secondary"
        onClick={onBack}
        className="complexLoginButtons__btn complexLoginButtons__btn_back"
        disabled={isLoading}
      >
        <Trans>Powrót</Trans>
      </Button>

      <Button
        type="submit"
        className="complexLoginButtons__btn"
        isLoading={isLoading}
        disabled={submitDisabled || isLoading}
      >
        {isWizard ? <Trans>Wyślij</Trans> : <Trans>Zaloguj się</Trans>}
      </Button>
    </div>
  );
};

export default ComplexLoginButtons;
