import { Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FC, HTMLProps } from 'react';

import './BackButton.scss';

type Props = HTMLProps<HTMLButtonElement> & {
  arrowOnly?: boolean;
};

const BackButton: FC<Props> = ({ arrowOnly, className, ...props }) => {
  const buttonCN = classnames(
    'backButton',
    {
      'backButton_arrowOnly': arrowOnly,
    },
    className
  );

  return (
    <button {...props} className={buttonCN} type="button">
      {!arrowOnly && <Trans>Wróć</Trans>}
    </button>
  );
};

export default BackButton;
