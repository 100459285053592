import { Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FC, useEffect, useRef, useCallback } from 'react';

import defaultIconVariants from '../utils/defaultIconVariants';

import { SnackbarItemProps } from '../Snackbar.helpers';
import './SnackbarItem.scss';

const SnackbarItem: FC<SnackbarItemProps> = (props) => {
  const timerAutoHide = useRef<number | null>(null);
  const { message, content, variant, options, onClose } = props;

  const setAutoHideTimer = useCallback(
    (autoHideDurationParam) => {
      if (!onClose || autoHideDurationParam === undefined) {
        return;
      }
      timerAutoHide.current && window.clearTimeout(timerAutoHide.current);
      timerAutoHide.current = window.setTimeout(() => {
        onClose();
      }, autoHideDurationParam);
    },
    [onClose]
  );

  useEffect(() => {
    setAutoHideTimer(options?.autoHideDuration);

    return () => {
      timerAutoHide.current && window.clearTimeout(timerAutoHide.current);
    };
  }, [options.autoHideDuration, setAutoHideTimer]);

  const handleClose = () => {
    onClose();
  };

  const icon = {
    ...defaultIconVariants,
  }[variant];

  const itemCN = classnames('snackbarItem', {
    [`snackbarItem_${variant}`]: Boolean(variant),
  });

  const showIcon = !options.hideIconVariant && variant !== 'default';

  return (
    <div className={itemCN} role="alert" key="SnackbarItem">
      {showIcon ? <div className="snackbarItem__icon">{icon}</div> : null}
      <div className="snackbarItem__content">{content || <div className="snackbarItem__message">{message}</div>}</div>

      <button onClick={handleClose} className="snackbarItem__closeButton">
        <span className="visually-hidden">
          <Trans>Zamknij</Trans>
        </span>
      </button>
    </div>
  );
};

export default SnackbarItem;
