import { useMutation, useQuery } from '@apollo/client';
import React, { FC } from 'react';

import analytics from 'utils/analytics/analytics';

import { activateCouponMutation, couponsQuery, ProviderCoupon } from './Coupons.helpers';
import './Coupons.scss';
import CouponsItem from './CouponsItem/CouponsItem';

const Coupons: FC = () => {
  const { data } = useQuery<{ providerCoupons: ProviderCoupon[] }>(couponsQuery);
  const [mutateCouponActivation] = useMutation<{ providerCoupons: ProviderCoupon[] }, { couponId: string }>(
    activateCouponMutation
  );
  if (!data) return null;

  const onActivate = async (id: string, link?: string) => {
    analytics.trackEvent(link ? 'sp_coupon_link_open' : 'sp_coupon_code_reveal', { coupon_id: id });
    await mutateCouponActivation({ variables: { couponId: id } });
    if (link) window.open(link, '_blank');
  };

  return (
    <div className="coupons">
      <div className="coupons__list">
        {data.providerCoupons.map((coupon) => (
          <CouponsItem key={coupon.id} couponData={coupon} onActivate={onActivate} />
        ))}
      </div>
    </div>
  );
};

export default Coupons;
