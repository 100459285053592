import { t, Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FC } from 'react';

import TopCategories from 'Components/TopCategories/TopCategories.async';
import DropdownMenu from 'common/DropdownMenu';
import routes from 'utils/routeTranslator';

import './AllServicesNav.scss';

type Props = {
  mode?: 'dark';
  routeL2?: boolean;
};

const AllServicesNav: FC<Props> = (props) => {
  const preload = () => TopCategories.preload();

  const allServicesLinkCN = classnames('allServicesNav__allServicesLink', {
    allServicesNav__allServicesLink_light: props.mode === 'dark',
  });

  return (
    <DropdownMenu
      title={t`Usługi`}
      theme={props.mode}
      ariaLabel={t`Lista wszystkich usług`}
      dataTestId="dropdownMenuServicesButton"
    >
      <TopCategories shouldUseNativeNavigation mode={props.mode} routeL2={props.routeL2} />
      {props.mode !== 'dark' && (
        <a
          href={routes.get('categories_seo', {})}
          className={allServicesLinkCN}
          // tabIndex={isDropdownOpen ? 0 : -1}
          onMouseOver={preload}
          onFocus={preload}
        >
          <Trans>Lista wszystkich usług</Trans>
        </a>
      )}
    </DropdownMenu>
  );
};

export default AllServicesNav;
