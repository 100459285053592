import classnames from 'classnames';
import React, { FC, HTMLProps, ReactNode } from 'react';

import './FabButton.scss';

type FabButtonProps = {
  className?: string;
  kind?: 'cta' | 'primary' | 'secondary' | 'danger';
  icon: ReactNode;
} & HTMLProps<HTMLButtonElement>;

const FabButton: FC<FabButtonProps> = (props) => {
  const { className, kind, type, ...rest } = props;
  const CN = classnames('fabButton', props.className, {
    [`fabButton_${kind}`]: Boolean(kind),
  });

  return (
    <button className={CN} type="button" {...rest}>
      {props.icon}
    </button>
  );
};

export default FabButton;
