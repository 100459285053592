import React, { FC, useState } from 'react';

import Modal from 'common/Modal/Modal';
import analytics from 'utils/analytics/analytics';
import routes from 'utils/routeTranslator';

import { REQUEST_PASSWORD_HASH } from '../Login/Login.helpers';
import ComplexLogin from './ComplexLogin';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const ComplexLoginModal: FC<Props> = (props) => {
  const [isLoginCodeSent, setLoginCodeSent] = useState<boolean>(false);

  const closeComplexLoginModal = (closer?: string) => {
    if (isLoginCodeSent) {
      analytics.trackEvent('code_login_popup_close', {
        touch_point_button: closer,
      });
    }

    props.onClose();
  };

  const onLoginSuccess = () => {
    window.location.href = `${routes.get('front::dashboard')}#${REQUEST_PASSWORD_HASH}`;
  };

  return (
    <Modal
      isOpen={props.isOpen}
      handleClose={closeComplexLoginModal}
      className="complexLoginModal"
      bodyClassName="complexLoginModal__body"
    >
      <ComplexLogin onCodeSent={() => setLoginCodeSent(true)} onSubmit={onLoginSuccess} />
    </Modal>
  );
};

export default ComplexLoginModal;
