import classnames from 'classnames';
import React from 'react';

import { SnackbarContainerProps } from './Snackbar.helpers';
import './Snackbar.scss';

const SnackbarContainer: React.FC<SnackbarContainerProps> = (props) => {
  const { className, children, ...other } = props;
  const containerCN = classnames('snackbarContainer', className);
  return (
    <div className={containerCN} {...other}>
      {children}
    </div>
  );
};

export default React.memo(SnackbarContainer);
