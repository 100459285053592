import { FrictionSdk, FrictionTypes } from '@olx-friction/friction-service-sdk-js/lib/types';
import React from 'react';

const initSdk = async (): Promise<FrictionSdk> => {
  const module = await import('@olx-friction/friction-service-sdk-js');
  return module.initFrictionSdk({
    language: 'pl',
  });
};

export async function getFrictionInit(
  action: 'login' | 'register',
  username: string,
  renderChallenge: (element?: HTMLElement) => void
): Promise<FrictionTypes.ChallengeReturnValue> {
  const sdk = await initSdk();
  return sdk.getToken({
    challengeParams: {
      username,
      action,
      aud: 'fixly',
      origin: window.location.origin,
    },
    onChallenge: ({ element }) => {
      if (element) renderChallenge(element);
    },
    onChallengeEnd: () => {},
  });
}

export const JSXElementFromHTMLElement = ({ htmlElement }: { htmlElement: HTMLElement }) => (
  <div ref={(element) => element?.appendChild(htmlElement)}></div>
);
