import { useQuery } from '@apollo/client';
import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

import NotFound from 'Components/NotFound/NotFound';
import { ProfileRedirectResponse, slugAliasRedirectQuery } from 'utils/graphql/slug';
import RedirectWithStatus from './RedirectWithStatus';

const WildcardRoute: FC = () => {
  const { pathname } = useLocation();

  // checking if it matches any slug alias
  const { data } = useQuery<ProfileRedirectResponse>(slugAliasRedirectQuery, {
    variables: {
      pathname: pathname,
    },
  });

  if (data) {
    const response = data.slugAliasRedirect;

    const { statusCode, pathname } = response;

    switch (statusCode) {
      case 301:
        return <RedirectWithStatus to={pathname} status={statusCode} />;
      case 404:
        return <NotFound />;
      default:
        return <RedirectWithStatus to={pathname} status={statusCode} />;
    }
  }

  return null;
};

export default WildcardRoute;
