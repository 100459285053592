import React, { FC, useContext, useEffect, useState } from 'react';

import { fetchCurrentUser, TCurrentUser } from './CurrentUser.helpers';

export type TCurrentUserState = {
  currentUser: TCurrentUser | undefined;
  loaded: boolean;
  setUser: (user?: TCurrentUser) => void;
};

const defaultState: TCurrentUserState = {
  currentUser: undefined,
  loaded: false,
  setUser: () => {},
};

const CurrentUserContext = React.createContext(defaultState);

export const CurrentUserConsumer = CurrentUserContext.Consumer;

export const useCurrentUser = () => {
  return useContext(CurrentUserContext);
};

/**
 * @todo Logout with clearCredentials.
 * @todo Fetch me with a graphql call.
 * @todo set current user on log out / log in
 */

const CurrentUser: FC = (props) => {
  const [userState, setUserState] = useState<TCurrentUserState>({
    currentUser: undefined,
    loaded: false,
    setUser: (user) => setUserState((state) => ({ ...state, currentUser: user })),
  });

  useEffect(() => {
    if (!userState.loaded) {
      fetchCurrentUser().then((user) => {
        setUserState((state) => ({
          ...state,
          currentUser: user,
          loaded: true,
        }));
      });
    }
  }, [userState.loaded]);

  return <CurrentUserContext.Provider value={userState}>{props.children}</CurrentUserContext.Provider>;
};

export default CurrentUser;
