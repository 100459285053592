import { ApolloLink } from '@apollo/client';

import { LANDING_APP_BANNER_KEY } from 'Components/LandingAppBanner/LandingAppBanner';
import { REQUEST_PASSWORD_HASH } from 'Components/Login/Login.helpers';
import api from 'utils/api';
import localStorage from 'utils/localStorage/localStorage';
import routes from 'utils/routeTranslator';

declare global {
  interface Window {
    isFetchingLoginToken: boolean;
  }
}

export const loginTokenMiddleware = new ApolloLink((operation, forward) => {
  if (typeof window === 'undefined') return forward(operation);

  const { location, history } = window;

  const params = new URLSearchParams(location.search);
  const loginToken = params.get('loginToken') || params.get('login_token');
  const shouldAddPostLoginHash = location.pathname === routes.get('front::dashboard');
  params.delete('loginToken');
  params.delete('login_token');

  if (!loginToken || Array.isArray(loginToken)) return forward(operation);
  const newHash = location.hash || shouldAddPostLoginHash ? `#${REQUEST_PASSWORD_HASH}` : '';
  const newHistoryState = `?${params.toString()}${newHash}`;
  history.replaceState(null, '', newHistoryState);
  window.isFetchingLoginToken = true;
  // TODO: find a better place for this
  localStorage.setItem(LANDING_APP_BANNER_KEY, 1);
  api
    .loginWithLoginToken(loginToken, true)
    .then(() => {
      location.reload();
    })
    .catch(() => {
      const redirectUrl = location.pathname.length > 1 ? `?redirect_url=${location.pathname}` : '';
      location.href = `${routes.get('front::auth.user.login')}${redirectUrl}`;
    });

  return forward(operation);
});
