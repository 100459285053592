// TODO: fix the problem GS-9213
// eslint-disable-next-line ssr-friendly/no-dom-globals-in-module-scope
let userAgent: string = typeof navigator !== 'undefined' ? navigator.userAgent : '';

const androidRegex = /Android/i;
const iosRegex = /iPhone|iPad|iPod/i;

const Android = (): boolean => androidRegex.test(userAgent);

const iOS = (): boolean => iosRegex.test(userAgent);

const isAnyMobile = (): boolean => Android() || iOS();

type SupportedPlatformKey = 'desktop' | 'ios' | 'android';

const getPlatform = (): SupportedPlatformKey => {
  if (!isAnyMobile()) return 'desktop';
  if (iOS()) return 'ios';
  return 'android';
};

const isFirefox = (): boolean => userAgent.toLowerCase().includes('firefox');

export const injectUserAgent = (ua: string): void => {
  userAgent = ua;
};

const platformDetector = {
  Android,
  iOS,
  isAnyMobile,
  getPlatform,
  isFirefox,
};

export default platformDetector;
