import { authorizationMiddleware } from './middleware/authorizationMiddleware';
import { loginTokenMiddleware } from './middleware/loginToken';
import { errorMiddleware } from './middleware/errorMiddleware';

import { isWindow } from 'common/helpers/helpers';
import config from 'config/config';
import auth from 'utils/auth/auth';

import { apiHeaders } from '../api/headers';
import { ApolloClient, HttpLink, InMemoryCache, from } from '@apollo/client';

function getCache() {
  const cacheManager = new InMemoryCache();

  return isWindow ? cacheManager.restore(window.__APOLLO_STATE__) : cacheManager;
}

const retryOnStatus = async (
  failureStatus: number,
  fetchCall: () => Promise<Response>,
  beforeRetryCall: () => Promise<unknown>
): Promise<Response> => {
  const response = await fetchCall();
  if (response.status !== failureStatus) {
    return response;
  }

  await beforeRetryCall();
  return await fetchCall();
};

const customFetch = async (uri: RequestInfo | URL, options?: RequestInit) => {
  const body = options?.body ? JSON.parse(String(options.body)) : undefined;
  const url = typeof uri === 'string' && body.operationName ? `${uri}?name=${body.operationName}` : uri;

  return await retryOnStatus(
    401,
    () => fetch(url, options),
    () => auth.refresh()
  );
};

function getLink() {
  const httpLink = new HttpLink({
    uri: `${config.API_URL}/graphql`,
    fetch: customFetch,
    credentials: 'include',
    headers: apiHeaders,
  });

  return from([loginTokenMiddleware, authorizationMiddleware, errorMiddleware, httpLink]);
}

export const client = new ApolloClient({
  link: getLink(),
  cache: getCache(),
});
