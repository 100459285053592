import c from 'classnames';
import React, { FC } from 'react';

import { ReactComponent as IosIcon } from 'common/icons/ios_icon_grey.svg';

import Button, { Button$Props } from '../Button';
import '../Button.scss';

const IosButton: FC<Button$Props> = ({ children, className, ...rest }) => {
  return (
    <Button icon={<IosIcon aria-hidden />} className={c('button_app', className)} {...rest}>
      {children}
    </Button>
  );
};

export default IosButton;
