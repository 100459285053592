import config from 'config/config';
import { OrderTypes } from 'pages/Payments/utils/placeOrder.helpers';

import routes from '../routeTranslator';
import { getFingerPrintHash } from './fingerprint';
import { getFetchHeaders } from './headers';
import { PayByCard, PayByLink } from './types';
import { responseToJson } from './utils';

type PaymentOrderDetails = {
  slug: string;
  type: 'vas' | 'package' | 'package_subscription' | OrderTypes.packageSubscription;
  quantity: number;
  webViewMode?: boolean;
  promoCodes?: string[];
  expectedPrice?: number;
};

export type PaymentCardOrderPayload = PaymentOrderDetails & PayByCard;
export type PaymentPblOrderPayload = PaymentOrderDetails & PayByLink;

export type OrderSuccessResponse = {
  orderId: string;
  status: {
    statusCode: string;
    statusDesc: string;
  };
};

export type OrderRedirectResponse = {
  orderId: string;
  redirectUri: string;
  status: {
    statusCode: string;
    severity: string;
  };
};

export const providerPlaceOrder = (
  payload: PaymentCardOrderPayload | PaymentPblOrderPayload
): Promise<OrderSuccessResponse | OrderRedirectResponse> => {
  const url = `${config.API_URL}${routes.get('api::payments.placeOrder')}`;

  return Promise.all([getFingerPrintHash(), getFetchHeaders()])
    .then(([deviceFingerprint, headers]) =>
      fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          ...payload,
          deviceFingerprint,
        }),
        credentials: 'include',
        headers,
      })
    )
    .then<OrderSuccessResponse | OrderRedirectResponse>(responseToJson);
};

export const getPBLOptions = async (type = 'vas') => {
  return fetch(`${config.API_URL}${routes.get('api::payments.getPBLOptions', { type })}`, {
    credentials: 'include',
    headers: await getFetchHeaders(),
  });
};

export const checkOrderStatus = async (orderId: string) => {
  return fetch(`${config.API_URL}${routes.get('api::payments.checkOrderStatus', { orderId })}`, {
    credentials: 'include',
    headers: await getFetchHeaders(),
  });
};

export const getCardTokens = async (productType = 'vas') => {
  return fetch(`${config.API_URL}${routes.get('api::payments.getCardTokens', { productType })}`, {
    credentials: 'include',
    headers: await getFetchHeaders(),
  });
};

export const deleteCardToken = async (token: string, productType: string) => {
  const url = `${config.API_URL}${routes.get('api::payments.deleteCardToken', {
    productType,
    token,
  })}`;

  return fetch(url, {
    method: 'DElETE',
    credentials: 'include',
    headers: await getFetchHeaders({ 'Content-Type': 'application/json' }),
  });
};
