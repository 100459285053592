import { apiAvailabilityRetry } from 'common/helpers/apiAvailabilityRetry';
import config from 'config/config';
import { getFetchHeaders } from 'utils/api';
import auth from 'utils/auth/auth';

export type TCurrentUser = {
  accessToken?: string;
  userId?: string | number;
  isProvider?: boolean;
  isImpersonating?: boolean;
  expiresAt?: number;
  refreshToken?: string;
};

type TCurrentUserError = {
  code: number;
  location: string[];
  message: string;
  name: string;
  path: string[];
  stack: string;
};

type TCurrentUserResponse = {
  data?: {
    currentUser?: TCurrentUser;
  };
  errors?: TCurrentUserError[];
};

/** @global */
let currentUserPromise: Promise<TCurrentUser | undefined>;

export const fetchCurrentUser = async (): Promise<TCurrentUser | undefined> => {
  // Fetch current user only once on app load
  if (currentUserPromise) return currentUserPromise;

  const operation = async (): Promise<TCurrentUser | undefined> => {
    const tokensUrl = `${config.API_URL}/tokens?operationName=fetchCurrentUser`;

    let response;

    try {
      response = await fetch(tokensUrl, {
        method: 'GET',
        headers: await getFetchHeaders(),
        credentials: 'include',
      });
    } catch (e) {
      response = {
        ok: false,
        json: () => {},
      };
    }

    if (!response.ok) {
      if (response.status === 403) {
        const res = (await response.json()) as TCurrentUserResponse;
        res.errors?.forEach((error: TCurrentUserError) => {
          if (error.code === 10667) {
            auth.clearCredentials();
            window.location.href = '/';
          }
          console.log(error.message);
        });
      }

      console.log('Failed to fetch current user');
    }

    const currentUser: TCurrentUser = await response.json();

    if (currentUser && currentUser.userId) {
      if (currentUser.refreshToken) {
        auth.storeCredentials(currentUser);
      } else {
        auth.storeCurrentUser(currentUser);
      }

      return currentUser;
    }

    return;
  };

  currentUserPromise = apiAvailabilityRetry(operation);

  return currentUserPromise;
};
