import React, { ReactNode } from 'react';
import classnames from 'classnames';

import './TextMeta.scss';

type TextMeta$Props = {
  children: ReactNode;
  className?: string;
  bold?: boolean;
  big?: boolean;
  light?: boolean;
};

export default function TextMeta({ className, children, bold, big, light }: TextMeta$Props) {
  const CN = classnames('textMeta', className, {
    'textMeta_bold': bold,
    'textMeta_big': big,
    'textMeta_light': light,
  });

  return <p className={CN}>{children}</p>;
}
