import React from 'react';

export const SmsIcon = () => (
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      className="svgIcon"
      d="M36.8 4.4H8C6.02 4.4 4.4 6.02 4.4 8V40.4L11.6 33.2H36.8C38.78 33.2 40.4 31.58 40.4 29.6V8C40.4 6.02 38.78 4.4 36.8 4.4ZM36.8 29.6H10.106L8 31.706V8H36.8V29.6ZM13.4 17H17V20.6H13.4V17ZM27.8 17H31.4V20.6H27.8V17ZM20.6 17H24.2V20.6H20.6V17Z"
      fill="#0087D0"
    />
  </svg>
);

/* eslint max-len:0 */
