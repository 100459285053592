import { gql } from '@apollo/client';

export type ProfileRedirectResponse = {
  slugAliasRedirect:
    | {
        statusCode: 200 | 301;
        pathname: string;
      }
    | {
        statusCode: 404;
        pathname: null;
      };
};

export const slugAliasRedirectQuery = gql`
  query SlugAliasRedirect($pathname: String!) {
    slugAliasRedirect(pathname: $pathname) {
      statusCode
      pathname
    }
  }
`;
