/* eslint-disable max-len */
import { FirebaseNamespace, FirebaseApp } from '@firebase/app-types';
import { FirebaseMessaging, FirebaseMessagingName } from '@firebase/messaging-types';

import { fetchCurrentUser } from 'Components/CurrentUser/CurrentUser.helpers';
import { isWindow } from 'common/helpers/helpers';

import { captureException } from '../errorLogger/sentry';
import localStorage from '../localStorage/localStorage';
import {
  TLocalFCMData,
  getFirebaseConfig,
  getFCMPublicKey,
  parseLocalFCMDataString,
  registerTokenUtil,
  unregisterTokenUtil,
  loadFirebaseSDK,
  registerWorker,
  platformSupportsMessaging,
  log,
} from './FCMManager.helpers';

declare let firebase: FirebaseNamespace & {
  [name in FirebaseMessagingName]: FirebaseMessaging & { isSupported?: () => boolean };
};

enum NotificationPermissions {
  PERMISSION_DEFAULT = 'default',
  PERMISSION_DENIED = 'denied',
  PERMISSION_GRANTED = 'granted',
}

export class FCMManager {
  static TOKEN_PERSISTANCE_KEY = 'fixly_fcm_token';

  initializationPromise?: Promise<void>;
  FCMServiceWorkerRegistration?: ServiceWorkerRegistration;
  FCMSupported = false;
  firebaseApp?: FirebaseApp;
  FCM?: FirebaseMessaging;

  constructor() {
    this.init();
  }

  init = async (): Promise<void> => {
    if (!isWindow) {
      return Promise.resolve();
    }

    if (!this.initializationPromise) {
      this.initializationPromise = (async () => {
        if (!platformSupportsMessaging()) {
          return;
        }

        const loaded = await loadFirebaseSDK();
        if (!loaded) {
          return;
        }

        log(getFirebaseConfig());

        if (typeof firebase === 'undefined') {
          return;
        }

        this.firebaseApp = firebase.initializeApp(getFirebaseConfig());

        this.FCMSupported = (firebase.messaging.isSupported && firebase.messaging.isSupported()) || false;

        if (!this.FCMSupported || (await this.isPermissionDenied())) {
          return;
        }

        // noinspection JSUnresolvedFunction // @fixme either types are fucked up or the package we are using
        // @ts-ignore
        this.FCM = firebase.messaging() as FirebaseMessaging;
        this.FCM.usePublicVapidKey(getFCMPublicKey());
        this.FCM.onTokenRefresh(this.processFCMTokenRetrieving);
      })();
    }

    return this.initializationPromise;
  };

  // @fixme use  generics to specify types
  lazyInit =
    (cb: Function) =>
    async (...args: any): Promise<any> => {
      try {
        await this.init();
      } catch (e) {
        // @ts-expect-error type mismatch
        log('Could not init FCM:', e.message);
      }

      return cb(...args);
    };

  isPushNotificationSupported = this.lazyInit(
    async (): Promise<boolean> => isWindow && 'Notification' in window && this.FCMSupported
  );

  isPermissionSet = (): boolean => Notification.permission !== NotificationPermissions.PERMISSION_DEFAULT;

  /**
   * NOTE: we don't need to use here lazy init, since this method doesn't require any Firebase SDK calls
   */
  isPermissionDenied = async (): Promise<boolean> =>
    Notification.permission === NotificationPermissions.PERMISSION_DENIED;

  /**
   * Asks for notification permission and start processing new FCM token if permission granted
   */
  async requestNotificationPermission(): Promise<NotificationPermission> {
    if (!(await this.isPushNotificationSupported())) {
      return 'default';
    }

    const permission: NotificationPermission = await Notification.requestPermission();

    if (permission === NotificationPermissions.PERMISSION_GRANTED) {
      this.processFCMTokenRetrieving();
    } else {
      log(`Permission is not granted, permision value: ${permission}`);
    }

    return permission;
  }

  /**
   * Retrieves new FCM token and sends it to the server if it's different from the saved one
   */
  async processFCMTokenRetrieving(): Promise<void> {
    log('Process token retrieving');
    const token: string | undefined = await this.getFCMToken();
    if (!token) {
      log('No token received by FCM');
      return;
    }

    const currentUser = await fetchCurrentUser();
    const currentUserId = (currentUser && currentUser.userId && currentUser.userId.toString()) || null;

    if (!currentUserId) {
      log('User signed out, unregistering FCM token');
      this.unregisterToken(token);
      // this.changeLocalFCMData(false, token);
      return;
    }

    this.registerToken(token, currentUserId);
  }

  /**
   * Tries to retrieve token if notification permission granted
   */
  getFCMToken = this.lazyInit(async (): Promise<string | null> => {
    try {
      return (this.FCM && (await this.FCM.getToken())) || null;
    } catch (e) {
      // NOTE: Notifications are not granted
      return null;
    }
  });

  /**
   * Calls the mutation to save new FCM token and saves it in localStorage with userId
   */
  async registerToken(token: string, uid: string): Promise<void> {
    const tokenRegistered: boolean = await registerTokenUtil(token);

    if (tokenRegistered) {
      log('New token registered', {
        token,
        uid,
      });
    } else {
      log('Current token is still up to date');
    }

    // Currently we won't rely on local data, while there are some issues in server implementation
    // which causes token to disappear
    // this.changeLocalFCMData(true, token, uid);
  }

  /**
   * Check localStorage for existing local FCM data,
   * compares the localy saved token with current FCM token
   * if tokens are the same -> calls the mutation to remove it for this user and clear local FCM data
   */
  async unregisterToken(token: string): Promise<void> {
    // NOTE: Now we unregister token straightaway
    await unregisterTokenUtil(token);
    log('Token unregistered', token);

    // const localFCMData = this.getLocalFCMData();
    // if (localFCMData && localFCMData.token === token) { // If localStorage has some existing FCM local data -> unregister token
    // NOTE: Local data is not used so far
    // this.changeLocalFCMData(false);
    // await unregisterTokenUtil(token);
    // log('Token unregistered', token);
    // }
  }

  /**
   * [WARNING] this method is not used now, due to server side issues with sending web pushes
   * Saves or removes TLocalFCMData object as a string in localStorage
   */
  changeLocalFCMData(isRegistered: boolean, token?: string, uid?: string): void {
    if (isRegistered && uid && token) {
      const localFCMData: TLocalFCMData = {
        token,
        uid,
      };
      localStorage.setItem(FCMManager.TOKEN_PERSISTANCE_KEY, JSON.stringify(localFCMData));
    } else {
      localStorage.removeItem(FCMManager.TOKEN_PERSISTANCE_KEY);
    }
  }

  getLocalFCMData(): TLocalFCMData | null {
    const localFCMDataString = localStorage.getItem(FCMManager.TOKEN_PERSISTANCE_KEY);
    return parseLocalFCMDataString(localFCMDataString);
  }

  /**
   * Tries to register service worker and passes it to FCM
   */
  registerCustomFCMServiceWorker = this.lazyInit(async (): Promise<void> => {
    if (!isWindow) {
      return;
    }

    if (!window.navigator.serviceWorker) {
      log('Service worker is not available on this page.');
      return;
    }

    log('Try to register FCMServiceWorker');

    try {
      this.FCMServiceWorkerRegistration = await registerWorker();

      if (!this.FCM) {
        log('FCM is not initialized');
        return;
      }

      this.FCM.useServiceWorker(this.FCMServiceWorkerRegistration);
      log('Custom service worker registered and passed to FCM');

      this.processFCMTokenRetrieving();
    } catch (e) {
      log('Error occurred while registering service worker', e);
      // @ts-expect-error type mismatch
      captureException(e);
    }
  });
}

let fcmInstance: FCMManager | null = null;
export default (() => {
  if (!fcmInstance) {
    fcmInstance = new FCMManager();
  }

  if (process.env.NODE_ENV !== 'production' && typeof window !== 'undefined') {
    // @ts-ignore
    window.FCMManager = fcmInstance;
  }

  fcmInstance.registerCustomFCMServiceWorker();

  return fcmInstance;
})();
