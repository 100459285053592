import classnames from 'classnames';
import React from 'react';

import './LinkButton.scss';

type LinkButton$Props<T extends keyof JSX.IntrinsicElements> = React.ComponentProps<T> & {
  className?: string;
  loading?: boolean;
  as?: T;
};

export default function LinkButton<T extends keyof JSX.IntrinsicElements = 'button'>(props: LinkButton$Props<T>) {
  const { className, loading, children, as, ...rest } = props;

  const buttonCN = classnames(
    {
      'linkButton': true,
      'linkButton_loading': loading,
    },
    className
  );

  return React.createElement(as || 'button', { className: buttonCN, ...rest }, children);
}
