import { Trans } from '@lingui/macro';
import React, { FC } from 'react';

import config from 'config/config';
import routes from 'utils/routeTranslator';

import FooterSocials from './FooterSocials';

type Props = {
  isNewTosBadgeVisible: boolean;
  isReportLink: boolean;
};

const contactEmail = 'kontakt@fixly.pl';

const UserFooter: FC<Props> = ({ isNewTosBadgeVisible, isReportLink }) => (
  <>
    <div className="footer">
      <div className="container">
        <div className="footer__row footer__row_userLinks">
          <div className="footer__aboutUs">
            <h3 className="footer__title">
              <Trans>Przydatne linki</Trans>
            </h3>
            <div className="footer__links">
              <a
                className="footer__item footer__item_link"
                href={routes.get('front::aboutus')}
                rel="noopener noreferrer nofollow"
              >
                <Trans>O nas</Trans>
              </a>
              <a className="footer__item footer__item_link" href={routes.get('categories_seo', {})}>
                <Trans>Lista usług</Trans>
              </a>
              {isNewTosBadgeVisible ? (
                <a
                  className="footer__item footer__item_link footer__new-tos"
                  href={config.FIX_HELPDESK_TOS_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Trans>Regulamin</Trans>
                  <sup className="footer__new-tos-badge">
                    <Trans>zmiany</Trans>{' '}
                  </sup>
                </a>
              ) : (
                <a
                  className="footer__item footer__item_link"
                  href={config.FIX_HELPDESK_TOS_URL}
                  rel="noopener noreferrer nofollow"
                >
                  <Trans>Regulamin</Trans>
                </a>
              )}
              <a
                className="footer__item footer__item_link"
                href={config.FIX_HELPDESK_PRIVACY_URL}
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                <Trans>Polityka prywatności</Trans>
              </a>
              <a
                className="footer__item footer__item_link"
                href={routes.get('front::referral')}
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                <Trans>Współpraca</Trans>
              </a>
              <a
                className="footer__item footer__item_link"
                href={routes.get('front::reviews')}
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                <Trans>Opinie</Trans>
              </a>
              {isReportLink && (
                <a
                  className="footer__item footer__item_link"
                  href="/jak-remontuja-polacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Trans>Jak remontujemy [Raport]</Trans>
                </a>
              )}
            </div>
          </div>

          <div className="footer__help">
            <h3 className="footer__title">
              <Trans>Pomoc</Trans>
            </h3>
            <div className="footer__links footer__links_help">
              <a
                className="footer__item footer__item_link"
                href={config.FIX_HELPDESK_URL}
                rel="noopener noreferrer nofollow"
              >
                <Trans>Centrum pomocy</Trans>
              </a>
              <a
                className="footer__item footer__item_link"
                href={config.FIX_HELPDESK_CONTACT_URL}
                rel="noopener noreferrer nofollow"
              >
                <Trans>Kontakt</Trans>
              </a>
            </div>
          </div>

          <div className="footer__contacts">
            <h3 className="footer__title">
              <Trans>Dane firmy</Trans>
            </h3>
            <div className="footer__links">
              <p className="footer__item footer__item">
                <Trans>Fixly, Grupa OLX</Trans>
              </p>
              <p className="footer__item footer__item">
                <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
              </p>
              <p className="footer__item footer__item">
                <Trans>ul. Królowej Jadwigi 43</Trans>
              </p>
              <p className="footer__item footer__item">
                <Trans>61-872 Poznań</Trans>
              </p>
            </div>
          </div>
        </div>
        <FooterSocials />
      </div>
    </div>
  </>
);
export default UserFooter;
