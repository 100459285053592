import { gql } from '@apollo/client';
import { ReactNode } from 'react';
import { RouteChildrenProps } from 'react-router-dom';

// TYPES
export type TProviderSettingsGalleryImageInput = {
  title: string;
  order: number;
  name: string;
};
export type TProviderSettings$GalleryUpdateInput = {
  images: TProviderSettingsGalleryImageInput[];
};
export type TProviderSettingsNotificationsInput = {
  push: boolean;
  email: boolean;
};
export type TProviderSettings$ContactUpdateInput = {
  email?: string;
  phone?: string;
  marketing?: boolean;
  notifications?: TProviderSettingsNotificationsInput;
};
export type TProviderSettings$SecurityUpdateInput = {
  password: string;
  newPassword: string;
};
export type TProviderSettings$Specialization = {
  ids: string[];
};
export type TProviderSettings$PublicProfile = {
  firstName: string;
  lastName: string;
  shortDescription: string;
  longDescription: string;
  avatar?: string;
  companyName?: string;
  yearEstablished: number | null | undefined;
  isEmailPublic?: boolean;
};
export type TProviderSettingsServiceAreaInput = {
  latitude: number;
  longitude: number;
  radius: number;
};
export type TProviderSettings$Services = {
  ids: string[];
};
export type TProviderSettings$Location = {
  serviceArea: TProviderSettingsServiceAreaInput;
  cityId: string;
};
export type TUserSettings$PublicProfile = {
  firstName?: string;
  lastName?: string;
  avatarUrl?: string;
  phone: string;
  email: string;
  marketingSubscription: boolean;
  notificationSetting: {
    email: boolean;
    sms: boolean;
  };
};
export type TProviderSettingsUpdateVariables$Input = {
  publicProfile?: TProviderSettings$PublicProfile;
  services?: TProviderSettings$Services;
  location?: TProviderSettings$Location;
  gallery?: TProviderSettings$GalleryUpdateInput;
  contact?: TProviderSettings$ContactUpdateInput;
  security?: TProviderSettings$SecurityUpdateInput;
  specialization?: TProviderSettings$Specialization;
};
export type TUserSettingsUpdateVariables = {
  publicProfile?: TUserSettings$PublicProfile;
  security?: TProviderSettings$SecurityUpdateInput;
};

export type TUpdateSettingsMutationResult = {
  data: {
    updateProviderSettings: boolean;
  };
};
export type TUpdateUserSettingsMutationResult = {
  data: {
    updateUserSettings: boolean;
  };
};
export type TcurrentUser = {
  me: {
    slug: string;
    provider: {
      id: string;
      slug: string;
    };
  };
};

export type IUpdateSettings = (
  options: TProviderSettingsUpdateVariables$Input
) => Promise<TUpdateSettingsMutationResult>;
export type IUpdateUserSettings = (options: TUserSettingsUpdateVariables) => Promise<TUpdateUserSettingsMutationResult>;
export type CommonSectionProps = {
  renderSectionHeader: (sectionTitle: string) => ReactNode;
  updateProviderSettings: IUpdateSettings;
  updateUserSettings: IUpdateUserSettings;
};
export type SettingsLayoutProps = RouteChildrenProps & {
  currentUser: TcurrentUser | undefined;
  isProvider?: boolean;
};

// HELPERS
export const updateProviderSettingsMutation = gql`
  mutation UpdateProviderSettingsMutation($input: ProviderSettingsUpdateRequest!) {
    updateProviderSettings(input: $input)
  }
`;
export const updateUserSettingsMutation = gql`
  mutation UpdateUserSettingsMutation(
    $publicProfile: UserSettingsPublicProfileUpdateInput
    $security: UserSettingsSecurityUpdateInput
  ) {
    updateUserSettings(publicProfile: $publicProfile, security: $security)
  }
`;
