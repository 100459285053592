import { t, Trans } from '@lingui/macro';
import classnames from 'classnames';
import { useFormik } from 'formik';
import React, { FC, useEffect } from 'react';
import * as Yup from 'yup';

import FormRow from 'uikit/FormRow/FormRow';
import Input from 'uikit/Input/Input';

type PasswordStepFormProps = {
  loading?: boolean;
  error?: string;
  mod?: string;
  login: string;
  challengeElement: JSX.Element | null;
  onSubmit: (value: string) => void;
  onForgotPassword: () => void;
};

const PasswordStepForm: FC<PasswordStepFormProps> = ({
  loading,
  error,
  mod,
  login,
  challengeElement,
  onSubmit,
  onForgotPassword,
}) => {
  const { values, errors, touched, setFieldError, handleChange, handleSubmit } = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required(t`Uzupełnij pole "hasło"`),
    }),
    onSubmit: () => {
      onSubmit(values.password);
    },
  });

  useEffect(() => {
    if (!error) return;
    (async () => {
      await setFieldError('password', error);
    })();
  }, [error]);

  const submitButtonCN = classnames('login__submitPassword', {
    'login__submitPassword_loading': loading,
  });

  const id = `login__password_${mod || ''}`;

  return (
    <form onSubmit={handleSubmit} className="login__form login__form_password">
      {/* Trick to save username/password in browser */}
      <input type="text" name="username" defaultValue={login} className="login__usernameHidden" />

      <FormRow>
        <Input
          id={id}
          name="password"
          type="password"
          value={values.password}
          error={touched.password && errors.password}
          onChange={handleChange}
          placeholder={t`Hasło`}
          autoFocus
        />
      </FormRow>

      {challengeElement}

      <button id={`submitLogin${mod || ''}`} type="submit" name="submitLogin" className={submitButtonCN}>
        <Trans>Zaloguj się</Trans>
      </button>

      <button type="button" onClick={onForgotPassword} className="login__forgotPassword">
        <Trans>Nie pamiętasz hasła?</Trans>
      </button>
    </form>
  );
};

export default PasswordStepForm;
