type LocalStorageResponse = string | null | undefined;

class AbstractLocalStorage {
  getItem(key: string): LocalStorageResponse {
    throw new Error('AbstractLocalStorage');
  }

  setItem(key: string, value: any): void {
    throw new Error('AbstractLocalStorage');
  }

  removeItem(key: string): void {
    throw new Error('AbstractLocalStorage');
  }
}

class LocalStorage extends AbstractLocalStorage {
  localStorageEnabled: boolean;
  localStorage?: Storage;

  constructor() {
    super();

    this.localStorageEnabled =
      // TODO: fix the problem GS-9213
      // eslint-disable-next-line ssr-friendly/no-dom-globals-in-constructor
      typeof window !== 'undefined' && window.localStorage && typeof window.localStorage.getItem === 'function';
    if (this.localStorageEnabled) {
      this.localStorage = window.localStorage;
    }
  }

  getItem(key: string): LocalStorageResponse {
    if (this.localStorageEnabled && this.localStorage) {
      return this.localStorage.getItem(key);
    }

    return null;
  }

  setItem(key: string, value: any): void {
    if (this.localStorageEnabled && this.localStorage) {
      this.localStorage.setItem(key, value);
    }
  }

  removeItem(key: string): void {
    if (this.localStorageEnabled && this.localStorage) {
      this.localStorage.removeItem(key);
    }
  }
}

class MockLocalStorage extends AbstractLocalStorage {
  data: Record<string, any>;

  constructor(data: Object) {
    super();

    this.data = data;
  }

  getItem(key: string): LocalStorageResponse {
    return this.data[key] || null;
  }

  setItem(key: string, value: any): void {
    this.data[key] = value;
  }

  removeItem(key: string): void {
    delete this.data[key];
  }
}

let instance: AbstractLocalStorage | null = null;

const getInstance = (mockData: Record<string, any> | null = null) => {
  if (mockData) {
    instance = new MockLocalStorage(mockData);
  } else if (!instance) {
    instance = new LocalStorage();
  }

  return instance;
};

export default getInstance();
