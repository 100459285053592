const styleMap: Map<string, string> = new Map();

function generateColor(logSource: string): string {
  let hash = 0;
  for (let i = 0; i < logSource.length; i += 1) {
    hash = logSource.charCodeAt(i) + ((hash << 5) - hash);
  }

  const c = (hash & 0x00ffffff).toString(16).toUpperCase();

  return '00000'.substring(0, 6 - c.length) + c;
}

function getLogSourceColor(logSource: string): string {
  if (!styleMap.has(logSource)) {
    styleMap.set(logSource, generateColor(logSource));
  }

  return styleMap.get(logSource) || generateColor(logSource);
}

export default function logger(logSource: string, message?: string, ...args: any[]): void {
  const logSourceColor = getLogSourceColor(logSource);
  console.log(
    `%c[${logSource}]%c`,
    `color: #${logSourceColor}; font-weight: 700;`,
    'color: initial; font-weight: initial;',
    message,
    ...args
  );
}

export const logDevOnly = (logSource: string, ...args: any[]): void => {
  if (process.env.NODE_ENV !== 'production') {
    logger(logSource, ...args);
  }
};

export const logAll = (logSource: string, ...args: any[]): void => {
  logger(logSource, ...args);
};

export const createLogger =
  (logFunc: Function, logSource: string) =>
  (...args: any[]) =>
    logFunc(logSource, ...args);
