import { Trans } from '@lingui/macro';
import classnames from 'classnames';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { isRoute } from 'common/helpers/helpers';
import { OlxIcon } from 'common/icons/OlxIcon';

import './Header.scss';

const OlxBanner: React.FC = () => {
  const { pathname: currentUrl } = useLocation();

  const olxCN = classnames('header__olx', {
    'header__olx_onCategory':
      isRoute(currentUrl, 'front::category') ||
      isRoute(currentUrl, 'front::provider.register') ||
      isRoute(currentUrl, 'front::provider.register.l2') ||
      isRoute(currentUrl, 'front::provider.register.l3') ||
      isRoute(currentUrl, 'front::provider.register.l2.requests'),
  });

  return (
    <a className={olxCN} href="https://www.olx.pl" target="_blank" rel="noopener noreferrer">
      <Trans>
        od <OlxIcon className="header__olxLogo" />
      </Trans>
    </a>
  );
};

export default OlxBanner;
